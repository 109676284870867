<template>
  <div class="main vasion-calc-height vld-parent vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-show="showEmailTemplate">
      <EmailTemplate ref="emailTemplateComponent" :emitClose="true" @editingComplete="emailTemplateComplete" />
    </div>
    <div v-if="showStepDetails">
      <WorkflowStepDetails
        ref="stepDetailsComponent"
        :stepData="editStepData"
        :workflowID="workflowId"
        @cancelClick="hideEditStepData"
        @doneClick="updateWorkflowStep"
        @markAsDirty="markAsDirtyStep"
        @markAsClean="markAsCleanStep"
        @markAsDirtyDone="markAsDirty"
      />
    </div>
    <div v-show="showWorkflow">
      <!-- TOOLBAR -->
      <div id="toolbar" class="vap-page-header">
        <h1 id="title" :class="{ 'new-workflow-name' : workflowId === 0 }">
          {{ workflow.name }}
        </h1>
        <VasionButton
          id="preview"
          :classProp="'primary'"
          :isDisabled="!isDirty && !isDirtyStep"
          @vasionButtonClicked="save()"
        >
          SAVE
        </VasionButton>
      </div>

      <!-- TABS -->
      <div class="md-layout">
        <VasionButton
          id="tab-settings"
          :isInErrorState="settingsErrorState"
          :classProp="calculatedClass('settings')"
          @vasionButtonClicked="updateSelectedTab('settings')"
        >
          SETTINGS
        </VasionButton>
        <VasionButton
          id="tab-status"
          :isInErrorState="statusNotificationsErrorState"
          :classProp="calculatedClass('status')"
          @vasionButtonClicked="updateSelectedTab('status')"
        >
          STATUS NOTIFICATIONS
        </VasionButton>
        <VasionButton
          id="tab-permissions"
          :isInErrorState="permissionsErrorState"
          :classProp="calculatedClass('permissions')"
          @vasionButtonClicked="updateSelectedTab('permissions')"
        >
          PERMISSIONS
        </VasionButton>
      </div>

      <!-- SETTINGS -->
      <div v-show="selectedTabName === 'settings'" id="settings-tab" class="workflow-tab">
        <WorkflowSettings
          ref="settings"
          @markAsDirty="markAsDirty"
          @addEmailTemplateClick="addEmailTemplateClick"
          @configureStep="startConfigureStep"
          @isLoading="setIsLoading"
          @errorInTab="setTabErrorState('settings')"
        />
      </div>

      <!-- STATUS -->
      <div v-show="selectedTabName === 'status'" id="status-tab" class="workflow-tab">
        <WorkflowStatus
          ref="status"
          :workflowId="workflowId"
          @markAsDirty="markAsDirty"
        />
      </div>

      <!-- PERMISSIONS -->
      <div v-show="selectedTabName === 'permissions'" id="permissions-tab" class="workflow-tab">
        <div id="all-users" class="collapsible-section">
          <h2 class="subheader">
            All Users
          </h2>

          <VasionButton
            id="all-users-collapse-section"
            name="all-users-collapse-section"
            class="collapse-section"
            :icon="showAllUsers ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Section"
            @vasionButtonClicked="toggleAllUsersSection()"
          />
          <div class="comment">
            If no initiators are configured, all users will be able to initiate this workflow
          </div>
          <VasionInput
            v-if="showAllUsers"
            id="user-search-bar"
            key="users"
            v-model="userInput"
            v-debounce:300ms="listUsers"
            name="user-search-bar"
            class="user-search-field"
            placeholder="Search Users..."
            :width="'312'"
            inputType="search-white"
          />
        </div>
        <div class="setup-row">
          <div v-show="showAllUsers" class="wf-grid-div vasion-html-table-default tables">
            <VasionTable
              :headerColumns="tableUserData.Columns.Values"
              :tableRows="tableUserData.Rows.Values"
              :supportSorting="true"
              :disableShiftSelect="true"
              @vasion-column-sort="sortUserColumns"
              @vasion-row-checked="handleUserChecklistChange"
            />
          </div>
        </div>
        <div class="separator-div" />
        <div>
          <div id="all-groups" class="collapsible-section">
            <h2 class="subheader">
              All Groups
            </h2>

            <VasionButton
              id="all-groups-collapse-section"
              name="all-groups-collapse-section"
              class="collapse-section"
              :icon="showAllGroups ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
              title="Collapse Section"
              @vasionButtonClicked="toggleAllGroupsSection()"
            />

            <VasionInput
              v-if="showAllGroups"
              id="group-search-bar"
              key="groups"
              v-model="groupInput"
              v-debounce:300ms="listGroups"
              name="group-search-bar"
              class="user-search-field"
              placeholder="Search Groups..."
              :width="'312'"
              inputType="search-white"
            />

            <div class="setup-row">
              <div v-show="showAllGroups" class="wf-grid-div vasion-html-table-default tables">
                <VasionTable
                  :headerColumns="tableGroupData.Columns.Values"
                  :tableRows="tableGroupData.Rows.Values"
                  :supportSorting="true"
                  :disableShiftSelect="true"
                  @vasion-column-sort="sortGroupColumns"
                  @vasion-row-checked="handleGroupChecklistChange"
                />
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog :message="leaveMessage" @noButtonClick="cancelLeave()" @yesButtonClick="doLeave()" />
    </md-dialog>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

import EmailTemplate from '@/views/admin/EmailTemplate.vue';
import WorkflowSettings from '@/components/workflow/WorkflowSettings.vue';
import WorkflowStatus from '@/components/workflow/WorkflowStatus.vue';
import WorkflowStepDetails from '@/components/workflow/WorkflowStepDetails.vue'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { tableSorter } from '@/store/helperModules/common.module'

export default {
  name: 'TheWorkflowAdmin',
  components: {
    EmailTemplate,
    Loading,
    WorkflowSettings,
    WorkflowStatus,
    WorkflowStepDetails,
  },
  beforeRouteLeave(to, from, next) {
    if ((this.isDirty || this.isDirtyStep) && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      allowLeave: false,
      checkboxes: {
        users: [],
        groups: [],
      },
      columns: {
        Values: [
          'Name',
          '_VasionCheckBox_{{ Initiator }}',
          '_VasionCheckBox_{{ Supervisor }}',
          '_VasionCheckBox_{{ Editor }}',
        ],
        ValueType: 'String',
      },
      editStepData: {},
      fullPage: true,
      groupInput: '',
      groupList: {},
      isDirty: false,
      isDirtyStep: false,
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      localGrouplist: {},
      localUserList: {},
      prevActive: '',
      selectedTabName: 'settings',
      showAllGroups: false,
      showAllUsers: false,
      showEmailTemplate: false,
      showLeaveDialog: false,
      showSnackbar: false,
      showStepDetails: false,
      showWorkflow: true,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableGroupData: {Columns: [], Rows: []},
      tableUserData: {Columns: [], Rows: []},
      userInput: '',
      userList: {},
    }
  },
  computed: {
    active() { return this.$store.state.mainViews.mainNav },
    leaveMessage() { return `Are you sure you want to leave? Changes to "${this.workflow.name}" have not been saved.` },
    newWorkflowClicked() { return this.$store.state.workflow.newWorkflowClicked },
    permissionsErrorState() { return this.$store.state.workflow.designerTabErrorConfig.permissions },
    saveWorkflowID() { return this.$store.state.admin.saveWorkflowID },
    settingsErrorState() { return this.$store.state.workflow.designerTabErrorConfig.settings },
    statusNotificationsErrorState() { return this.$store.state.workflow.designerTabErrorConfig.statusNotification },
    workflow() { return this.$store.state.workflow.selectedWorkflow },
    workflowId() { return this.workflow && this.workflow.id ? this.workflow.id : 0 },
  },
  watch: {
    active: function () {
      if ((this.isDirty || this.isDirtyStep) && this.prevActive !== this.active && this.active !== 'workflow') {
        this.toggleLeaveDialog()
      }
    },
    newWorkflowClicked: function () {
      if ((this.isDirty || this.isDirtyStep) && this.newWorkflowClicked) {
        this.toggleLeaveDialog()
      }
    },
    workflowId: async function () { await this.reloadWorkflowData() },
  },
  created: async function () {
    this.$material.locale.dateFormat = this.$store.state.systemSettings.systemDateFormat
    this.$store.dispatch('vault/getVaultList')
    this.$store.dispatch('admin/setSaveWorkflowID', 0)
    this.prevActive = this.active === 'workflow-designer' ? `configure-workflow-${this.workflow.id}` : this.active
    await this.reloadWorkflowData()
  },
  methods: {
    async addEmailTemplateClick() {
      await this.$refs.emailTemplateComponent.clearFields()

      this.showWorkflow = false
      this.showEmailTemplate = true
    },
    calculatedClass(selectedTab) { return this.selectedTabName === selectedTab ? 'text-selected' : 'text' },
    cancelLeave() {
      this.routeTo = null
      this.$store.dispatch('mainViews/changeMainNav', this.prevActive)
      this.$store.dispatch('workflow/setNewWorkflowClicked', false)
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.$refs.settings.clearFieldErrors()
      this.$refs.status.clearSectionErrors()
      this.$store.dispatch('workflow/clearTabErrors')
      this.markAsClean()
      this.toggleLeaveDialog()
      if (this.routeTo && this.routeTo.name !== '') {
        this.$router.push({ name: this.routeTo.name });
        this.routeTo = null
      } else if (this.newWorkflowClicked) {
        await this.$store.dispatch('workflow/selectWorkflow', 0)
        this.$router.push({ name: 'WorkflowAdmin' })
        this.routeTo = null
        this.$store.dispatch('workflow/setNewWorkflowClicked', false)
      } else {
        this.prevActive = this.active
        await this.$store.dispatch('workflow/selectWorkflow', Number(this.active.substring(19)))
      }
    },
    emailTemplateComplete() {
      this.$store.dispatch('common/getEmailTemplates')
      this.showEmailTemplate = false
      this.showWorkflow = true
    },
    async filteredValues(usersOrGroups, text, userGroup) {
      const input = text.toLowerCase()
      if (!input) { return usersOrGroups }

      const returnList = usersOrGroups.filter(userOrGroup => {
        const name = userGroup === 'users' ? userOrGroup.UserName.toLowerCase() : userOrGroup.GroupName.toLowerCase()
        const foundName = name.indexOf(input) > -1

        return foundName
      })

      return returnList
    },
    async handleGroupChecklistChange(options, row, column, value) {
      this.markAsDirty()
      this.localGroupList[row].checkboxes[column].value = value
    },
    async handleUserChecklistChange(options, row, column, value) {
      this.markAsDirty()
      this.localUserList[row].checkboxes[column].value = value
    },
    hideEditStepData() {
      this.showStepDetails = false
      this.showWorkflow = true
      this.editStepData = {}
    },
    async listGroups(input) {
      if (input) {
        this.groupInput = input
        this.localGroupList = await this.filteredValues(this.groupList, input, 'groups')
      } else {
        this.localGroupList = this.groupList
      }
      this.refreshGroupTable()
    },
    async listUsers(input) {
      if (input) {
        this.userInput = input
        this.localUserList = await this.filteredValues(this.userList, input, 'users')
      } else {
        this.localUserList = this.userList
      }
      this.refreshUserTable()
    },
    markAsClean() {
      this.isDirty = false
      this.$store.dispatch('workflow/setIsWorkflowDirty', false)
    },
    markAsDirty() {
      this.isDirty = true
      this.$store.dispatch('workflow/setIsWorkflowDirty', true)
      this.$store.dispatch('workflow/clearTabError', 'permissions')
    },
    markAsDirtyStep() {
      this.isDirtyStep = true
      this.$store.dispatch('workflow/setIsWorkflowDirty', true)
      this.$store.dispatch('workflow/clearTabError', 'permissions')
    },
    markAsCleanStep() {
      this.isDirtyStep = false
      if (!this.isDirty) {
        this.$store.dispatch('workflow/setIsWorkflowDirty', false)
      }
    },
    async refreshGroupTable() {
      const data = {
        dataList: this.localGroupList,
        columns: this.columns,
      }

      this.tableGroupData = await this.$store.dispatch('security/setWorkflowGroupTableData', data)
    },
    async refreshUserTable() {
      const data = {
        dataList: this.localUserList,
        columns: this.columns,
      }
      this.tableUserData = await this.$store.dispatch('security/setWorkflowUserTableData', data)
    },
    async reloadWorkflowData() {
      let securityData = await this.$store.dispatch('workflow/getWorkflowSecurity', this.workflowId)
      if (securityData) {
        this.userList = securityData.userDetails;
        this.userList.map(user => {
          user.checkboxes = [
                  user.UserName,
                  { value: user.IsInitiator },
                  { value: user.IsSupervisor },
                  { value: user.IsEditor },
                ]
        })
        this.groupList = securityData.groupDetails
        this.groupList.map(group => {
          group.checkboxes = [
                  group.GroupName,
                  { value: group.IsInitiator },
                  { value: group.IsSupervisor },
                  { value: group.IsEditor },
                ]
        })
      }
      this.listUsers()
      this.listGroups()
    },
    async save() {

      let test = this.tableUserData
      this.showSnackbar = test
      this.showSnackbar = false
      this.isLoading = true

      let settingsSaveResults = null
      try {
        settingsSaveResults = await this.$refs.settings.save()
      } catch {
        settingsSaveResults = null
      }

      if (!settingsSaveResults || this.saveWorkflowID <= 0) {
        this.snackbarTitle = 'Unable to save Workflow'
        this.snackbarSubTitle = !settingsSaveResults || settingsSaveResults.message === '' ? 'Error saving Workflow Settings\n' : settingsSaveResults.message
        this.snackbarImage = false
        this.showSnackbar = true;
        this.isLoading = false
        return false
      }

      let displayMessage = settingsSaveResults.message
      let saveSuccess = settingsSaveResults.success      

      //we have to save security first, before attempting to change other data
      // saving security simultaneously with other settings, can result in failures
      const securityResults = await this.saveSecurity()
      saveSuccess = saveSuccess && securityResults.success
      displayMessage += securityResults.message

      const statusResults = await this.$refs.status.save()
      saveSuccess = saveSuccess && statusResults.success
      displayMessage += statusResults.message

      if (saveSuccess === true) {
        this.snackbarTitle = 'Workflow Saved!'
        this.snackbarSubTitle = 'All settings saved successfully'
        this.snackbarImage = true
        await this.$amplitude.trackEvent('Workflow Saved', {})
        this.markAsClean()
        this.markAsCleanStep()
      } else {
        this.snackbarTitle = 'Unable to save Workflow'
        this.snackbarSubTitle = displayMessage
        this.snackbarImage = false
      }
      this.showSnackbar = true;
      this.isLoading = false
      return saveSuccess
    },
    async saveSecurity() {
      if (this.saveWorkflowID <= 0) {
        return false
      }

      const users = this.userList.filter(user => user.checkboxes[1].value || user.checkboxes[2].value || user.checkboxes[3].value)
      const group = this.groupList.filter(group => group.checkboxes[1].value || group.checkboxes[2].value || group.checkboxes[3].value)
      const payload = {
        workflowID: this.saveWorkflowID,
        assignedUsers: users.map(user => {
          return {
            UserID: user.UserID,
            IsInitiator: user.checkboxes[1].value,
            IsSupervisor: user.checkboxes[2].value,
            IsEditor: user.checkboxes[3].value,
          }
        }),
        assignedGroups: group.map(group => {
          return {
            GroupID: group.GroupID,
            IsInitiator: group.checkboxes[1].value,
            IsSupervisor: group.checkboxes[2].value,
            IsEditor: group.checkboxes[3].value,
          }
        }),
      }

      const saveResult = await this.$store.dispatch('workflow/saveWorkflowSecurity', payload)
      if (!saveResult) {
        this.$store.dispatch('workflow/setWorkflowTabError', 'permissions')
      }
      const returnData = {
        success: saveResult,
        message: saveResult ? '' : 'Error saving Security. ',
      }

      return returnData
    },
    setIsLoading(payload) {
      this.isLoading = payload.isLoading
    },
    showPreview() {
      // TODO: This is really just a placeholder for now
    },
    async sortGroupColumns(options) {
      switch (options.columnName) {
        case 'Name':
          this.sortGroupTableBy = 'GroupName'
          break
        case '_VasionCheckBox_{{ Initiator }}':
          this.sortGroupTableBy = 'IsInitiator'
          break
        case '_VasionCheckBox_{{ Supervisor }}':
          this.sortGroupTableBy = 'IsSupervisor'
          break
        case '_VasionCheckBox_{{ Editor }}':
          this.sortGroupTableBy = 'IsEditor'
          break
      default:
          break
      }
      this.sortTableGroupAscending = !options.ascending
      this.localGroupList.sort(tableSorter(this.sortGroupTableBy, this.sortTableGroupAscending))
      this.refreshGroupTable()
    },
    async sortUserColumns(options) {
      switch (options.columnName) {
        case 'Name':
          this.sortUserTableBy = 'UserName'
          break
        case '_VasionCheckBox_{{ Initiator }}':
          this.sortUserTableBy = 'IsInitiator'
          break
        case '_VasionCheckBox_{{ Supervisor }}':
          this.sortUserTableBy = 'IsSupervisor'
          break
        case '_VasionCheckBox_{{ Editor }}':
          this.sortUserTableBy = 'IsEditor'
          break
        default:
          break
      }
      this.sortTableUserAscending = !options.ascending
      this.localUserList.sort(tableSorter(this.sortUserTableBy, this.sortTableUserAscending))

      this.refreshUserTable()
    },
    startConfigureStep(stepData) {
      this.editStepData = JSON.parse(JSON.stringify(stepData))
      this.showWorkflow = false
      this.showStepDetails = true
    },
    toggleAllGroupsSection() { this.showAllGroups = !this.showAllGroups },
    toggleAllUsersSection() { this.showAllUsers = !this.showAllUsers },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    updateSelectedTab(newSelectedTab) { this.selectedTabName = newSelectedTab },
    updateWorkflowStep(stepData) {
      this.$refs.settings.updateLocalStep(stepData)
      this.markAsDirty()
      this.hideEditStepData()
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';

  .main {
    background-color: white;
    padding: 20px 0 0 15px;
    width: 100%;
    z-index: 1;
    overflow: auto;

    #toolbar {
      height: 40px;
      margin: 0;
      width: 100%;
      text-align: left;
      line-height: 40px;
      vertical-align: middle;
      min-width: 500px;

      #title {
        @include Headline;
        float: left;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        margin: 0;

        &.new-workflow-name {
          color: $grey-400;
        }
      }

      #button-favorite {
        margin: 0 25px 0 25px;
        float: left;
      }

      #preview {
        float: right;
        margin-right: 10px;

        &.vasion-button {
          button {
            margin-top: 0;
          }
        }
      }
    }

    #tab-settings {
      &.vasion-button {
        &:first-child {
          button {
            margin-left: 0;
          }
        }
      }
    }
  }

  .subheader {
      @include SubHeadline;
      width: 200px;
      display: block;
      float: left;
      margin-top: 5px;
    }

  .workflow-tab {
    width: calc(100vw - 378px);
    height: calc(100vh - 240px);
    overflow-y: auto;
    overflow-x: none;
  }
  .tab-error-state {
    border: 1px solid $error-red;
  }

  .separator-div {
    height: 40px;
  }

  .user-search-field {
    padding-left: 5px;
    float: right;
    color: $grey-400;
    fill: $grey-400;
    margin-bottom: 10px;
  }

  .comment {
    display:inline-block;
    padding-left: 100px;
    vertical-align: sub;
  }

  .collapsible-section {
    float: left;
    margin-top: 20px;
    width: 100%;

    .subheader {
      @include SubHeadline;
      width: 200px;
      display: block;
      float: left;
      margin-top: 5px;
    }
  }

  .wf-grid-div {
    height: 400px;
    overflow: auto;
    box-sizing: content-box;
    width: 100%;
  }

  .setup-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 6px 0;

    ::v-deep .vasion-input {
      width: 310px;
      margin-right: 16px;
    }
  }

  @media (max-width: $breakpoint-lg) {
    .workflow-tab {
      width: calc(100vw - 118px);
    }
  }
</style>
<style lang="scss" scoped>
    ::v-deep   .setColumnWidth {
    max-width: 90px;
    width: 90px;
  }

</style>
