<template>
  <div id="workflow-status">
    <div class="vasion-flex-row check-container">
      <VasionCheckbox
        id="status-notification"
        class="notification-checkbox"
        :checked="inactivitySettings.inactivityEnabled"
        @change="updateInactivitySetting(!inactivitySettings.inactivityEnabled); emitWorkflowIsDirty();"
      />
      <label for="notifications-for">Enable Status Notifications</label>
    </div>
    <transition name="fade">
      <div v-if="inactivitySettings && inactivitySettings.inactivityEnabled" class="status-notification-section">
        <label for="notifications-for" class="input-lable">Run Status Notifications Every:</label>
        <div id="notifications-for" class="drop-down-group vasion-flex-row">
          <VasionInput
            id="daysNotifications"
            v-model="inactivitySettings.sendEmailFrequencyDays"
            class="vasion-mr-15"
            inputType="top-white"
            title="DAYS"
            width="96"
            type="number"
            min="0"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            @input="emitWorkflowIsDirty('inactivitySettings')"
          />
          <VasionInput
            id="hoursNotifications"
            v-model="inactivitySettings.sendEmailFrequencyHours"
            inputType="top-white"
            title="HOURS"
            width="96"
            type="number"
            min="0"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            @input="emitWorkflowIsDirty('inactivitySettings')"
          />
        </div>
        <label for="notifications-for" class="input-lable">For Documents Inactive For:</label>
        <div id="notifications-for" class="drop-down-group vasion-flex-row">
          <VasionInput
            id="daysInactive"
            v-model="inactivitySettings.documentInactiveDays"
            class="vasion-mr-15"
            inputType="top-white"
            title="DAYS"
            width="96"
            type="number"
            min="0"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            @input="emitWorkflowIsDirty('inactivitySettings')"
          />
          <VasionInput
            id="hoursInactive"
            v-model="inactivitySettings.documentInactiveHours"
            inputType="top-white"
            title="HOURS"
            width="96"
            type="number"
            min="0"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            @input="emitWorkflowIsDirty('inactivitySettings')"
          />
        </div>
        <div v-for="(status, index) in inactivitySettings.statusInactiveList" :key="index">
          <label for="notifications-for" class="input-lable">{{ status.statusValue }}</label>
          <div id="notifications-for" class="drop-down-group vasion-flex-row">
            <VasionInput
              id="daysInactiveList"
              v-model="status.documentInactiveDays"
              class="vasion-mr-15"
              inputType="top-white"
              title="DAYS"
              width="96"
              type="number"
              min="0"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              @input="emitWorkflowIsDirty('status')"
            />
            <VasionInput
              id="hoursInactiveList"
              v-model="status.documentInactiveHours"
              inputType="top-white"
              title="HOURS"
              width="96"
              type="number"
              min="0"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              @input="emitWorkflowIsDirty('status')"
            />
            <VasionDeleteIcon class="delete-icon status-delete-icon" @click="deleteListItem(inactivitySettings.statusInactiveList, index)" />
          </div>
        </div>
        <label for="notifications-for" class="input-lable">Include Documents With The Specified Workflow Status and Inactive Time:</label>
        <div id="notifications-for" class="drop-down-group vasion-flex-row">
          <div class="input-group">
            <VasionInput
              id="wfStatusNew"
              v-model="newInactivitySetting.statusValue"
              class="vasion-mr-15"
              inputType="top-white"
              title="WORKFLOW STATUS"
              width="258"
              :isInErrorState="statusValueMissing"
              @focus="clearErrorMessages()"
              @input="emitWorkflowIsDirty('inactivitySetting')"
            />
          </div>
          <div class="input-group">
            <VasionInput
              id="daysInactiveNew"
              v-model="newInactivitySetting.documentInactiveDays"
              class="vasion-mr-15"
              inputType="top-white"
              title="DAYS INACTIVE"
              type="number"
              min="0"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              width="258"
              :isInErrorState="documentInactiveDaysMissing"
              @focus="clearErrorMessages()"
              @input="emitWorkflowIsDirty('inactivitySetting')"
            />
          </div>
          <div class="input-group">
            <VasionInput
              id="hoursInactiveNew"
              v-model="newInactivitySetting.documentInactiveHours"
              inputType="top-white"
              title="HOURS INACTIVE"
              type="number"
              min="0"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              width="258"
              :isInErrorState="documentInactiveHoursMissing"
              @focus="clearErrorMessages()"
              @input="emitWorkflowIsDirty('inactivitySetting')"
            />
          </div>
        </div>
        <VasionButton
          id="preview"
          class="add-status"
          :classProp="'secondary'"
          @vasionButtonClicked="addStatus(); emitWorkflowIsDirty('inactivitySetting')"
        >
          ADD STATUS
        </VasionButton>
      </div>
    </transition>
    <div v-if="inactivitySettings && inactivitySettings.inactivityEnabled && inactivitySettings.wfInactiveID" class="vasion-flex-row">
      <div class="radio-container">
        <md-radio
          id="terminate-radio"
          v-model="flowBoolean"
          value="terminate"
          @change="emitWorkflowIsDirty('inactivitySetting')"
        >
          Terminate Workflow
        </md-radio>
      </div>
      <div class="radio-container">
        <md-radio
          id="email-radio"
          v-model="flowBoolean"
          value="email"
          @change="emitWorkflowIsDirty('inactivitySetting')"
        >
          Send Email Reminder
        </md-radio>
      </div>
      <div v-if="flowBoolean === 'email'" class="vasion-mr-15">
        <VasionDropList
          v-slot="slotItem"
          v-model="inactivitySettings.emailTemplateID"
          title="EMAIL TEMPLATE"
          type="plain-list"
          valueName="iID"
          displayName="sName"
          :dataArray="templateList ? templateList : []"
          width="396"
          @input="emitWorkflowIsDirty('inactivitySetting')"
        >
          {{ slotItem.item.sName }}
        </VasionDropList>
      </div>
    </div>
    <div id="workflow-hours" class="vasion-collapsable-section-header" @click="toggleSections('workingHours')">
      <span :class="{'section-error': sectionErrorValues.businessHours}" class="subheader">Working Hours</span><VasionArrowDropDownIcon v-if="workingHours" :class="{'section-error': sectionErrorValues.businessHours}" /><VasionArrowDropDownRight16Icon v-else :class="{'section-error': sectionErrorValues.businessHours}" />
    </div>
    <transition name="fade">
      <div v-if="workingHours">
        <div v-if="workingHours && localBusinessHours" class="collaps-section">
          <div v-for="(status, index) in localBusinessHours" :key="index" class="day-group vasion-flex-row">
            <div class="vasion-flex-row day-check-group mr-25">
              <VasionCheckbox
                :id="`day-${index}`"
                class="day-checkbox"
                :checked="status.checked"
                @change="setStatusChecked(status); emitWorkflowIsDirty('businessHours');"
              />
              <label class="day-label" for="day">{{ getDayOfTheWeek(status.dayOfWeek) }}</label>
            </div>
            <VasionDateTimeField
              v-model="status.startHour"
              label="START HOUR"
              type="time"
              class="row-field"
              customClass="vasion-mr-15"
              width="258px"
              @input="emitWorkflowIsDirty('businessHours')"
            />
            <VasionDateTimeField
              v-model="status.endHour"
              label="END HOUR"
              type="time"
              class="row-field"
              customClass="vasion-mr-15"
              width="258px"
              @input="emitWorkflowIsDirty('businessHours')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div id="workflow-holidays" class="vasion-collapsable-section-header" @click="toggleSections('holidayTab')">
      <span :class="{'section-error': sectionErrorValues.inactivitySettings}" class="subheader">Holidays</span><VasionArrowDropDownIcon v-if="holidayTab" :class="{'section-error': sectionErrorValues.userAssignedSummary}" /><VasionArrowDropDownRight16Icon v-else :class="{'section-error': sectionErrorValues.userAssignedSummary}" />
    </div>
    <transition name="fade">
      <div>
        <div v-if="holidayTab" class="collaps-section">
          <div class="vasion-flex-row vasion-flex-vertical-center">
            <div class="datepicker">
              <VasionDateTimeField
                v-model="newHolidayDateSelected"
                label=""
                type="date"
                @input="emitWorkflowIsDirty('inactivitySetting')"
              />
            </div>
            <VasionButton
              id="add-date"
              :classProp="'secondary'"
              @focus="clearErrorMessages()"
              @vasionButtonClicked="addHolidayDate(); emitWorkflowIsDirty('inactivitySetting');"
            >
              ADD DATE
            </VasionButton>
            <label class="error-notification">{{ newDateMissing }}</label>
          </div>
          <table class="table-width">
            <thead>
              <tr class="vasion-table-header-row table-row">
                <th>
                  <label class="vasion-html-table-header">Holiday</label>
                </th>
                <th class="delete-col" />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(date, index) in holidays"
                :key="index"
                :class="{'vasion-greyBackground': index % 2 === 0}"
                class="table-row"
              >
                <td class="table-field">
                  <label class="vasion-html-table-field">{{ date }}</label>
                </td>
                <td class="delete-col">
                  <VasionDeleteIcon class="delete-icon" @click="deleteListItem(holidays, index)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="holidayTab && (!holidays || holidays.length == 0)">
          No holidays to display
        </div>
      </div>
    </transition>
    <div id="workflow-summary" class="vasion-collapsable-section-header mb-20" @click="toggleSections('summary')">
      <span :class="{'section-error': sectionErrorValues.userAssignedSummary}" class="subheader">Summary</span><VasionArrowDropDownIcon v-if="summary" :class="{'section-error': sectionErrorValues.userAssignedSummary}" /><VasionArrowDropDownRight16Icon v-else :class="{'section-error': sectionErrorValues.userAssignedSummary}" />
    </div>
    <transition name="fade">
      <div>
        <div v-if="summary" class="collaps-section">
          <div class="vasion-flex-row mb-20">
            <div class="input-group">
              <VasionDropList
                v-slot="slotItem"
                v-model="newDateSummaryDate"
                title="WEEKDAY"
                type="plain-list"
                class="vasion-mr-15"
                valueName="dayId"
                displayName="dayDisplay"
                placeholder="Select Weekday..."
                :dataArray="dateOptions"
                required
                :isInErrorState="newDateSummaryDateMissing"
                @focus="clearErrorMessages()"
                @input="emitWorkflowIsDirty"
              >
                {{ slotItem.item.dayDisplay }}
              </VasionDropList>
            </div>
            <div class="input-group">
              <VasionInput
                id="time"
                v-model="newDateSummaryTime"
                title="TIME"
                name="time"
                placeholder="12:00:00 AM"
                inputType="top-white"
                width="320"
                type="time"
                step="60"
                required
                :isInErrorState="newDateSummaryTimeMissing"
                @focus="clearErrorMessages()"
                @input="emitWorkflowIsDirty('userAssignedSummary')"
              />
            </div>
            <VasionButton
              id="add-time"
              class="flex-end"
              :classProp="'secondary'"
              @vasionButtonClicked="addTime(); emitWorkflowIsDirty('userAssignedSummary');"
            >
              ADD TIME
            </VasionButton>
          </div>
          <table class="table-width">
            <thead>
              <tr class="vasion-table-header-row table-row">
                <th>
                  <label class="vasion-html-table-header weekday">Weekday</label>
                </th>
                <th>
                  <label class="vasion-html-table-header">Time</label>
                </th>
                <th class="delete-col" />
              </tr>
            </thead>
            <tbody v-if="userAssignedSummarySendTimes">
              <tr
                v-for="(date, index) in userAssignedSummarySendTimes"
                :key="index"
                :class="{'vasion-greyBackground': index % 2 === 0}"
                class="table-row"
              >
                <td class="table-field">
                  <label class="vasion-html-table-field weekday-item">{{ getDayOfTheWeek(date.dayOfTheWeek) }}</label>
                </td>
                <td class="table-field">
                  <label class="vasion-html-table-field">{{ date.timeOfDay | moment("h:mm a") }}</label>
                </td>
                <td>
                  <VasionDeleteIcon class="delete-icon" @click="deleteListItem(userAssignedSummarySendTimes, index)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="summary && (!userAssignedSummarySendTimes || userAssignedSummarySendTimes.length == 0)">
          No summary to display
        </div>
      </div>
    </transition>

    <VasionSnackbar
      :showSnackbarBool.sync="snackbar.show"
      :snackbarImage="snackbar.image"
      :snackbarSubTitle="snackbar.subtitle"
      :snackbarTitle="snackbar.title"
    />
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import { formatDateTime } from '@/store/helperModules/common.module'

export default {
  name: 'WorkflowStatus',
  props: {
    workflowId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      businessHoursTab: true,
      dateOptions: [{ dayId: 0, dayDisplay: 'Monday' }, { dayId: 1, dayDisplay: 'Tuesday' }, { dayId: 2, dayDisplay: 'Wednesday' }, { dayId: 3, dayDisplay: 'Thursday' }, { dayId: 4, dayDisplay: 'Friday' }, { dayId: 5, dayDisplay: 'Saturday' }, { dayId: 6, dayDisplay: 'Sunday' }],
      documentInactiveDaysMissing: false,
      documentInactiveHoursMissing: false,
      emailTemplates: [{ value: 'thing', name: '23' }],
      flowBoolean: 'terminate',
      holidayTab: true,
      defaultBusinessHours: [
        {
            checked: false,
            dayOfWeek: 1,
            startHour: '12:00',
            endHour: '12:00',
        },
        {
            checked: false,
            dayOfWeek: 2,
            startHour: '12:00',
            endHour: '12:00',
        },
        {
            checked: false,
            dayOfWeek: 3,
            startHour: '12:00',
            endHour: '12:00',
        },
        {
            checked: false,
            dayOfWeek: 4,
            startHour: '12:00',
            endHour: '12:00',
        },
        {
            checked: false,
            dayOfWeek: 5,
            startHour: '12:00',
            endHour: '12:00',
        },
        {
            checked: false,
            dayOfWeek: 6,
            startHour: '12:00',
            endHour: '12:00',
        },
        {
            checked: false,
            dayOfWeek: 7,
            startHour: '12:00',
            endHour: '12:00',
        },
      ],
      localBusinessHours: [],
      newDateMissing: '',
      newDateSummaryDate: '',
      newDateSummaryDateMissing: false,
      newDateSummaryTime: '',
      newDateSummaryTimeMissing: false,
      newHolidayDateSelected: '',
      newInactivitySetting: { documentInactiveHours: '', documentInactiveDays: '', statusValue: '' },
      newSummaryDateSelected: '',
      sectionErrorValues: {
        businessHours: false,
        inactivitySettings: false,
        userAssignedSummary: false,
      },
      snackbar: {
        show: false,
        image: false,
        subtitle: '',
        title: '',
      },
      statusValueMissing: false,
      summary: true,
      timeOptions: [{ dayId: 0, dayDisplay: 'Monday' }, { dayId: 1, dayDisplay: 'Tuesday' }, { dayId: 2, dayDisplay: 'Wednesday' }, { dayId: 3, dayDisplay: 'Thursday' }, { dayId: 4, dayDisplay: 'Friday' }, { dayId: 5, dayDisplay: 'Saturday' }, { dayId: 6, dayDisplay: 'Sunday' }],
      workingHours: true,
    }
  },
  computed: {
    businessHours() {return this.$store.state.workflow.businessHours},
    holidays() { return this.$store.state.workflow.holidays },
    inactivitySettings() { return this.$store.state.workflow.inactivitySettings },
    saveWorkflowID() { return this.workflowId <= 0 ? this.$store.state.admin.saveWorkflowID : this.workflowId },
    templateList() { return this.$store.state.common.emailTemplates },
    userAssignedSummary() { return this.$store.state.workflow.userAssignedSummary },
    userAssignedSummarySendTimes() { return this.$store.state.workflow.userAssignedSummarySendTimes ? this.$store.state.workflow.userAssignedSummarySendTimes : [] },
  },
  watch: {
    businessHours() {
      this.fillNonUsedWorkdays(this.businessHours)
    },
    newHolidayDateSelected() {
      if (this.newHolidayDateSelected) {
        this.newDateMissing = ''
      }
    },
  },
  async created() {
    await this.$store.dispatch('common/getEmailTemplates')
    this.flowBoolean = this.inactivitySettings.emailTemplateID ? "email" : "terminate"
    this.fillNonUsedWorkdays(this.businessHours)
  },
  methods: {
    addHolidayDate() {
      let missingDate = false;
      if (!this.newHolidayDateSelected) { this.newDateMissing = 'Please select a date'; missingDate = true }
      if (!missingDate) {
        this.holidays.push(formatDateTime(this.newHolidayDateSelected, 'date'))
      }
    },
    async addStatus() {
      let errors = []
      if (this.newInactivitySetting.statusValue === '') {
        this.statusValueMissing = true
        errors.push('- Status field is required')
      }
      if (this.newInactivitySetting.documentInactiveDays === '') {
        this.documentInactiveDaysMissing = true
        errors.push('- Document Days Inactive field is required')
      }
      if (this.newInactivitySetting.documentInactiveHours === '') {
        this.documentInactiveHoursMissing = true
        errors.push('- Document Hours Inactive field is required')
      }

      if (errors.length === 0) {
        this.inactivitySettings.statusInactiveList.push({
          statusValue: this.newInactivitySetting.statusValue,
          documentInactiveHours: this.newInactivitySetting.documentInactiveHours,
          documentInactiveDays: this.newInactivitySetting.documentInactiveDays,
        })
        this.clearErrorMessages()
        this.clearNewInactivitySettingInputs()
      } else {
        this.snackbar.title = 'ERROR: Missing fields'
        this.snackbar.subtitle = errors.join('\n')
        this.snackbar.image = false
        this.snackbar.show = true
      }
    },
    addSummaryTime() {
      this.userAssignedSummarySendTimes.push(this.newSummaryDateSelected)
    },
    addTime() {
      let errors = []
      if (this.newDateSummaryDate === '') {
        this.newDateSummaryDateMissing = true
        errors.push('- Weekday field is required.')
      }
      if (this.newDateSummaryTime === '') {
        this.newDateSummaryTimeMissing = true
        errors.push('- Time field is required.')
      }

      if (errors.length === 0) {
        this.userAssignedSummarySendTimes.push({
            dayOfTheWeek: this.newDateSummaryDate.dayId,
            timeOfDay: `2019-09-06T${this.newDateSummaryTime}:00`,
        })
        this.clearErrorMessages()
      } else {
        this.snackbar.title = 'ERROR: Missing fields'
        this.snackbar.subtitle = errors.join('\n')
        this.snackbar.image = false
        this.snackbar.show = true
      }
    },
    clearErrorMessages() {
      this.statusValueMissing = false
      this.documentInactiveDaysMissing = false
      this.documentInactiveHoursMissing = false
      this.newDateSummaryDateMissing = false
      this.newDateSummaryTimeMissing = false
    },
    clearSectionErrors() {
      // eslint-disable-next-line
      Object.keys(this.sectionErrorValues).map(key => this.sectionErrorValues[key] = false)
    },
    clearNewInactivitySettingInputs() {
      this.newInactivitySetting.statusValue = ''
      this.newInactivitySetting.documentInactiveDays = ''
      this.newInactivitySetting.documentInactiveHours = ''
    },
    deleteListItem(list, index) {
      this.$emit('markAsDirty', true)
      list.splice(index, 1)
    },
    emitWorkflowIsDirty(type) {
      this.$emit('markAsDirty', true)
      this.$store.dispatch('workflow/clearTabError', 'initialLogic')
      switch (type) {
        case 'inactivitySetting':
          this.sectionErrorValues.inactivitySettings = false
          break;
        case 'businessHours':
          this.sectionErrorValues.businessHours = false
          break;
        case 'userAssignedSummary':
          this.sectionErrorValues.userAssignedSummary = false
          break;
          default:
      }
    },
    fillNonUsedWorkdays(workdays) {
      this.localBusinessHours = cloneDeep(this.defaultBusinessHours)
      workdays.forEach((d) => {
        let dayConfig = this.localBusinessHours[parseInt(d.dayOfWeek, 10) - 1]
        dayConfig.checked = true
        if (typeof d.startHour === 'number') {
          dayConfig.startHour = d.startHour > 9 ? `${d.startHour}:00` : `0${d.startHour}:00`
        }
        if (typeof d.endHour === 'number') {
          dayConfig.endHour = d.endHour > 9 ? `${d.endHour}:00` : `0${d.endHour}:00`
        }
      })
    },
    getDayOfTheWeek(dayNumber) {
      switch (parseInt(dayNumber, 10)) {
        case 1: return 'Monday'
        case 2: return 'Tuesday'
        case 3: return 'Wednesday'
        case 4: return 'Thursday'
        case 5: return 'Friday'
        case 6: return 'Saturday'
        case 7: return 'Sunday'
        default: return 'Monday'
      }
    },
    prepareHoursForSave(hours) {
      const filtered = hours.filter((h) => h.checked)
      const returnValue = filtered.map((h) => {
        const temp = { ...h };
        temp.startHour = parseInt(h.startHour?.slice(0, -3), 10)
        temp.endHour = parseInt(h.endHour?.slice(0, -3), 10)
        return temp
      })

      return returnValue
    },
    async save() {
      if (this.validate() === false) {
        return false
      }

      if (this.flowBoolean === 'email' && this.inactivitySettings.emailTemplateID == null)
      {
        return {
          success: false,
          message: "If Send Email Reminder is selected, you must select an email template."
        }
      }

      const promise1 = this.saveInactivitySettings()
      const promise2 = this.saveUserAssignedSummary()
      const promise3 = this.saveUserBusinessHours()

      // Promise.all runs the items async, and will evaluate once they all come back
      // resultsArray contains booleans for each promise on if the save was successful or not
      // if at least one save failed, we're returning false for the entire save function
      const resultsArray = await Promise.all([promise1, promise2, promise3])

      let resultMessage = ''
      resultsArray.map((saveResult, index) => {
        if (saveResult === false) {
          this.$store.dispatch('workflow/setWorkflowTabError', 'statusNotification')
          switch (index) {
            case 0:
              resultMessage += 'Error saving Inactivity Settings. '
              this.sectionErrorValues.inactivitySettings = true
              break
            case 1:
              resultMessage += 'Error saving User Assigned Summary. '
              this.sectionErrorValues.userAssignedSummary = true
              break
            case 2:
              resultMessage += 'Error saving Business Hours. '
              this.sectionErrorValues.businessHours = true
              break;
            default:
              break
          }
        }

        return true;
      })

      const result = {
          message: resultMessage,
          success: resultMessage === '',
        }

      return result
    },
    async saveInactivitySettings() {
      if (!this.inactivitySettings) {
        return true
      }

      const payload = this.inactivitySettings
      if (this.flowBoolean == 'terminate') {
        payload.emailTemplateID = null
      }
      else if (typeof payload.emailTemplateID === 'object' && payload.emailTemplateID !== null) {
        payload.emailTemplateID = payload.emailTemplateID.iID
      }
       // eslint-disable-next-line
      payload['workflowId'] = this.saveWorkflowID
      return this.$store.dispatch('workflow/updateInactivitySettings', payload)
    },
    async saveUserAssignedSummary() {
      const payload = {
        sendTimes: this.userAssignedSummarySendTimes,
        workflowId: this.saveWorkflowID,
      }
      return this.$store.dispatch('workflow/updateUserAssignedSummary', payload)
    },
    async saveUserBusinessHours() {
      const payload = { businessHours: this.prepareHoursForSave(this.localBusinessHours), holidays: this.holidays }
      // eslint-disable-next-line
      payload['workflowId'] = this.saveWorkflowID
      return this.$store.dispatch('workflow/updateWorkflowBusinessHours', payload)
    },
    setStatusChecked(status) {
      status.checked = !status.checked
    },
    toggleSections(section) {
      this[section] = !this[section]
    },
    async updateInactivitySetting(fieldValue) {
      let payload = {name: 'inactivityEnabled', value: fieldValue}
      await this.$store.dispatch('workflow/updateSingleInactivitySetting', payload)
      if (!fieldValue) {
        payload = {name: 'documentInactiveHours', value: 0}
        await this.$store.dispatch('workflow/updateSingleInactivitySetting', payload)
        payload = {name: 'documentInactiveDays', value: 0}
        await this.$store.dispatch('workflow/updateSingleInactivitySetting', payload)
      }
    },
    validate() {
      if (this.saveWorkflowID > 0
          && !this.statusValueMissing
          && !this.documentInactiveDaysMissing
          && !this.documentInactiveHoursMissing
          && !this.newDateSummaryDateMissing
          && !this.newDateSummaryTimeMissing) {
        return true
      }

      return false
    },
  },
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';
  #add-time {
    position: relative;
    top: 4px;
  }
  .status-delete-icon {
    align-self: center;
    margin-top: 2.3rem;
  }
  .add-status {
    margin-bottom: 20px;
  }
  .datepicker {
    width: 320px;
    margin-right: 15px;
  }
  .day-check-group {
    align-items: center;
  }
  .day-checkbox {
    margin-right: 10px;
    fill: $orange-400;
  }
  .day-group {
    margin-top: 20px;
  }
  .check-container {
    align-items: center;
    margin: 35px 0;
  }
  .collaps-header {
    display: flex;
  }
  .collapsable-control-section {
    display: flex;
  }
  .day-label {
    width: 200px;
  }
  .delete-icon:hover {
    cursor: pointer;
  }
  .drop-down-group {
    margin-bottom: 20px;
  }
  .error-notification {
    color: $error;
    height: 27px;
  }
  .flex-end {
    align-self: flex-end;
  }
  .input-group {
    display: flex;
    flex-direction: column;
  }
  .input-lable {
    margin-bottom: 15px;
    display: block;
  }
  .main-div {
    margin-bottom: 20px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mh-200 {
    min-height: 200px;
  }
  .mr-25 {
    margin-right: 25px;
  }
  .section-error {
    color: $error-red;
    fill: $error-red !important;
  }
  .radio-container {
    width: 274px;
  }
  .notification-checkbox {
    margin-right: 30px;
    fill: $orange-400;
  }
  .status-notification-section {
    margin-bottom: 20px;
  }
  .table-field {
    height: 40px;
  }
  .table-row {
    height: 40px;
  }
  .table-width {
    width: 99%;
  }
  .delete-col {
    width: 50px;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }
</style>
