import { render, staticRenderFns } from "./ButtonInfo.vue?vue&type=template&id=053f2fc7&scoped=true&"
import script from "./ButtonInfo.vue?vue&type=script&lang=js&"
export * from "./ButtonInfo.vue?vue&type=script&lang=js&"
import style0 from "./ButtonInfo.vue?vue&type=style&index=0&id=053f2fc7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "053f2fc7",
  null
  
)

export default component.exports