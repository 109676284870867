<template>
  <div class="action-details-div">
    <Loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-show="!isUploadingFile && !isViewingDocument">
      <div v-show="showUserGroup" class="body-div">
        <VasionUserGroupSelect
          modalTitle="Select a User or Group"
          :userOnly="false"
          @noButtonClick="showUserGroup = false"
          @yesButtonClick="handleUserGroupSelection"
        />
      </div>
      <div v-show="!showUserGroup && !showGroupsOnly" class="body-div">
        <table class="action-details-table">
          <tr>
            <td class="vasion-page-title">
              Custom Button Settings
            </td>
            <td />
          </tr>
          <tr>
            <td>
              <VasionInput
                id="action-name-text"
                v-model="actionName"
                title="Action Name"
                name="action-name-text"
                placeholder="Enter Name..."
                inputType="top-white"
                :maxLengthProp="26"
                @input="emitWorkflowIsDirty"
              />
            </td>
            <td>
              <VasionInput
                id="status-message-text"
                v-model="workflowStatus"
                title="Status Message"
                name="status-message-text"
                placeholder="Enter Status..."
                inputType="top-white"
                @input="emitWorkflowIsDirty"
              />
            </td>
          </tr>
          <tr>
            <td>
              <VasionDropList
                id="button-action-droplist"
                v-slot="slotItem"
                v-model="selectedButtonAction"
                title="BUTTON ACTION"
                name="button-action-droplist"
                type="plain-list"
                :dataArray="buttonActionsArray"
                width="100%"
                valueName="value"
                displayName="name"
                placeholder=""
                @input="handleButtonActionChange(); SetUseOriginalDocument(); emitWorkflowIsDirty()"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
            <td>
              <VasionCheckbox
                v-if="!isAutoStepType"
                id="run-other-action-check"
                name="run-other-action-check"
                :checked="commentRequired"
                color="#95989a"
                class="control-centered"
                @change="toggleCheckbox('commentRequired'); emitWorkflowIsDirty();"
              >
                Comment Required
              </VasionCheckbox>
            </td>
          </tr>
        </table>
        <table v-if="selectedButtonAction && selectedButtonAction.value === 2" class="action-details-table indented">
          <tr v-for="(sig, index) in signatureForms" :key="index">
            <td>
              <VasionDropList
                v-slot="slotItem"
                v-model="sig.attributeForm"
                :title="formsLabel"
                name="form-droplist"
                type="plain-list"
                :dataArray="attributeFormList"
                width="385"
                valueName="value"
                displayName="name"
                placeholder=""
                @input="emitWorkflowIsDirty"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
            <td class="w30">
              <VasionButton
                name="set-signature-location-button"
                :classProp="'secondary'"
                :isDisabled="!(sig.attributeForm && sig.attributeForm.value)"
                class="control-centered"
                @vasionButtonClicked="setSignatureLocation(sig.tempId); emitWorkflowIsDirty();"
              >
                Set Signature Location
              </VasionButton>
            </td>
            <td>
              <VasionButton
                name="set-signature-location-button"
                :classProp="'secondary'"
                class="control-centered"
                @vasionButtonClicked="removeSignatureForm(index); emitWorkflowIsDirty();"
              >
                Remove
              </VasionButton>
            </td>
          </tr>
        </table>
        <table v-show="selectedButtonAction && selectedButtonAction.value === 2" class="action-details-table indented">
          <tr>
            <td>
              <VasionButton
                name="add-new--button"
                :classProp="'secondary'"
                @vasionButtonClicked="addNewSignatureForm(); emitWorkflowIsDirty();"
              >
                Add New
              </VasionButton>
            </td>
          </tr>
        </table>
        <table v-show="selectedButtonAction && selectedButtonAction.value === 2" class="action-details-table">
          <tr>
            <td>
              <VasionCheckbox
                id="burn-signature-check"
                name="burn-signature-check"
                :checked="burnSignature"
                color="#95989a"
                @change="burnSignature = !burnSignature; emitWorkflowIsDirty();"
              >
                Burn Signature
              </VasionCheckbox>
            </td>
            <td>
              <VasionCheckbox
                id="apply-date-signature-check"
                name="apply-date-signature-check"
                :checked="applyDateToSignature"
                color="#95989a"
                @change="applyDateToSignature = !applyDateToSignature; emitWorkflowIsDirty();"
              >
                Apply Date to Signature
              </VasionCheckbox>
            </td>
          </tr>
        </table>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 3" class="set-fields">
          <div id="field-list" class="vasion-html-table-default">
            <table>
              <thead>
                <tr class="white-background">
                  <th class="wide">
                    <label class="vasion-html-table-header">Field</label>
                  </th>
                  <th>
                    <label class="vasion-html-table-header">Prompt</label>
                  </th>
                  <th class="field-type-column">
                    <label class="vasion-html-table-header">Required</label>
                  </th>
                  <th class="field-type-column">
                    <label class="vasion-html-table-hselectedFormFieldseader">Auto Value</label>
                  </th>
                </tr>
              </thead>
              <tbody class="scrollTBody">
                <tr v-for="(field, index) in setFields" :key="index" :class="{'lightestGreyBackground': index % 2 === 0 }">
                  <td class="pointer">
                    <label class="vasion-html-table-field pointer">{{ field.title }}</label>
                  </td>
                  <td class="pointer">
                    <VasionCheckbox
                      id="'prompt'+index"
                      color="#95989a"
                      :checked="field.prompt"
                      class="prompt-checkbox"
                      @change="setPrompt(index); emitWorkflowIsDirty();"
                    />
                  </td>
                  <td class="pointer">
                    <VasionCheckbox
                      id="'required'+index"
                      color="#95989a"
                      :checked="field.required"
                      class="required-checkbox"
                      @change="setRequired(index); emitWorkflowIsDirty();"
                    />
                  </td>
                  <td class="pointer auto-value-input">
                    <VasionInput 
                      v-model="field.default"
                      inputType="top-white"
                      @input="emitWorkflowIsDirty"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 4" id="lookup-section">
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedLookup"
            title="LOOKUP"
            name="lookup-droplist"
            type="plain-list"
            :dataArray="lookupList"
            width="385"
            valueName="value"
            displayName="name"
            placeholder=""
            @input="emitWorkflowIsDirty"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 5" class="">
          <table class="action-details-table indented">
            <tr>
              <td>
                <md-radio id="email-radio" v-model="radio" value="email" @input="emitWorkflowIsDirty">
                  Send to Email
                </md-radio>
                <md-radio id="field-radio" v-model="radio" value="field" @input="emitWorkflowIsDirty">
                  Send to Field
                </md-radio>
              </td>
            </tr>
            <tr>
              <td v-if="radio === 'email'">
                <VasionInput
                  id="send-to-email-text"
                  v-model="sendToEmail"
                  title="Send to Email"
                  name="send-to-email-text"
                  placeholder="Enter Email..."
                  inputType="top-white"
                  specialType="email"
                  @input="emitWorkflowIsDirty"
                  @notValid="isEmailValid = false"
                  @isValid="isEmailValid = true"
                />
              </td>
              <td v-if="radio === 'field'">
                <VasionDropList
                  id="send-to-field"
                  v-slot="slotItem"
                  v-model="sendToField"
                  title="Send to Field"
                  name="send-to-field"
                  type="plain-list"
                  :dataArray="selectedObjectId === 0 ? allIndexFields : indexFields"
                  width="100%"
                  valueName="value"
                  displayName="name"
                  placeholder=""
                  @input="emitWorkflowIsDirty"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </td>
              <td>
                <VasionDropList
                  id="email-templates"
                  v-slot="slotItem"
                  v-model="emailTemplateIDForSendEmail"
                  title="Email Templates"
                  name="email-templates"
                  type="plain-list"
                  :dataArray="emailTemplatesForSendEmail"
                  width="100%"
                  valueName="value"
                  displayName="name"
                  placeholder=""
                  @input="emitWorkflowIsDirty"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </td>
            </tr>
            <tr>
              <td>
                <VasionCheckbox
                  id="attach-document"
                  name="attach-document"
                  class="control-centered"
                  color="#95989a"
                  :checked="attachDocument"
                  @change="toggleCheckbox('attachDocument'); emitWorkflowIsDirty();"
                >
                  Attach document
                </VasionCheckbox>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 6">
          <table class="action-details-table indented">
            <tr>
              <td>
                <VasionDropList
                  id="workflow-field"
                  v-slot="slotItem"
                  v-model="workflowFieldObj"
                  title="Workflow"
                  name="workflow-field"
                  type="plain-list"
                  :dataArray="workflowListValues"
                  width="100%"
                  valueName="value"
                  displayName="name"
                  placeholder=""
                  @input="emitWorkflowIsDirty"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </td>
              <td />
            </tr>
          </table>
        </div>
        <div v-else-if="selectedButtonAction && selectedButtonAction.value === 7">
          <table class="action-details-table indented">
            <tr>
              <td>
                <VasionDropList
                  id="document-template"
                  v-slot="slotItem"
                  v-model="selectedTemplate"
                  title="Document Template"
                  name="document-template"
                  type="plain-list"
                  :dataArray="templateList"
                  width="100%"
                  valueName="documentTemplateID"
                  displayName="name"
                  placeholder=""
                  @input="emitWorkflowIsDirty"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </td>
              <td>
                <VasionCheckbox
                  id="use-original-document"
                  name="use-original-document"
                  :checked="useOriginalDocument"
                  color="#95989a"
                  class="control-centered"
                  @change="toggleCheckbox('useOriginalDocument'); emitWorkflowIsDirty();"
                >
                  Use Original Document
                </VasionCheckbox>
              </td>
            </tr>
          </table>
        </div>
        <div v-else-if="selectedButtonAction && selectedButtonAction.value === 8">
          <table class="action-details-table indented">
            <tr>
              <td>
                <VasionDropList
                  id="signature-template"
                  v-slot="slotItem"
                  v-model="selectedSignatureTemplate"
                  title="Signature Template"
                  name="signature-template"
                  type="plain-list"
                  :dataArray="signatureTemplateList"
                  width="100%"
                  valueName="value"
                  displayName="name"
                  placeholder=""
                  @input="emitWorkflowIsDirty"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </td>
              <td>
                <VasionCheckbox
                  id="use-original-document"
                  name="use-original-document"
                  :checked="useOriginalDocument"
                  color="#95989a"
                  class="control-centered"
                  @change="toggleCheckbox('useOriginalDocument'); emitWorkflowIsDirty(); handleButtonActionChange();"
                >
                  Use Original Document
                </VasionCheckbox>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 'Create User Folder'" class="fields-container">
          <div class="form-row">
            <VasionDropList
              id="folder-email"
              name="folder-email"
              v-slot="slotItem"
              v-model="customFields.createFolder.email"
              title="Email (Username)"
              type="plain-list"
              :dataArray="indexFields"
              width="100%"
              displayName="name"
              valueName="value"
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div class="form-row">
            <VasionDropList
              id="drive-location"
              name="drive-location"
              v-slot="slotItem"
              v-model="customFields.createFolder.driveStorageConfig"
              title="DRIVE LOCATION"
              type="plain-list"
              :dataArray="drives"
              width="100%"
              displayName="name"
              valueName="value"
              :showIfEmpty="true"
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div class="form-row">
            <VasionDropList
              id="custom-name-fields"
              v-slot="slotItem"
              v-model="customFields.createFolder.indexFieldsSelection"
              title="Fields for Folder Name"
              name="custom-name-fields"
              type="plain-list"
              :dataArray="indexFields"
              width="100%"
              valueName="value"
              displayName="name"
              placeholder=""
              @input="checkIfFieldIsAvailable"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionButton
              id="add-custom-name-field"
              class="btn-margin-fix"
              :title="customFields.createFolder.indexFieldsSelection ? '' : 'Select a Fields for Folder Name before attempting to add it.'"
              :isDisabled="(customFields.createFolder.indexFieldsSelection ? false : true) || isCurrentFieldValid"
              :classProp="'primary'"
              @vasionButtonClicked="addCustomNameField"
            >
              Add to Format
            </VasionButton>
          </div>
          <VasionInput
            v-model="customFields.createFolder.folderName"
            title="Folder Name Format"
            inputType="top-white"
            name="folder-name"
            placeholder="Folder Name Format..."
            :width="'100%'"
            :isDisabled="true"
          />
          <VasionButton
            id="add-custom-name-field"
            class="btn-margin-fix"
            :title="customFields.createFolder.folderName ? '' : 'Before clearing, ensure that the Folder Name Format field contains a value.'"
            :isDisabled="customFields.createFolder.folderName ? false : true"
            :classProp="'secondary'"
            @vasionButtonClicked="customFields.createFolder.folderName = ''"
          >
            Clear Folder Name Format
          </VasionButton>
        </div>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 'Create Portal User'" class="fields-container">
          <div class="fields-grid">
            <VasionDropList
              id="first-name"
              v-slot="slotItem"
              v-model="customFields.createUser.firstName"
              title="FIRST NAME"
              name="first-name"
              type="plain-list"
              :dataArray="indexFields"
              width="100%"
              valueName="value"
              displayName="name"
              placeholder=""
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              id="last-name"
              v-slot="slotItem"
              v-model="customFields.createUser.lastName"
              title="LAST NAME"
              name="last-name"
              type="plain-list"
              :dataArray="indexFields"
              width="100%"
              valueName="value"
              displayName="name"
              placeholder=""
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              id="email"
              v-slot="slotItem"
              v-model="customFields.createUser.email"
              title="EMAIL (Username)"
              name="email"
              type="plain-list"
              :dataArray="indexFields"
              width="100%"
              displayName="name"
              valueName="value"
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              id="groups"
              v-slot="slotItem"
              v-model="customFields.createUser.groups"
              title="GROUPS"
              name="groups"
              type="check-list"
              width="100%"
              displayName="name"
              valueName="value"
              :dataArray="groupsArray"
              :pillOptions="true"
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
        </div>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 'Open URL'" class="open-url-fields-container">
          <div class="form-row">
            <VasionDropList
              id="open-url-field"
              v-slot="slotItem"
              v-model="openURL.field"
              title="Field"
              name="open-url-field"
              type="plain-list"
              :dataArray="indexFields"
              width="100%"
              valueName="value"
              displayName="name"
              placeholder=""
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionButton
              id="add-custom-name-field"
              class="btn-margin-fix"
              :title="openURL.field ? '' : 'Select a field to be added to the URL.'"
              :isDisabled="openURL.field ? false : true"
              :classProp="'primary'"
              @vasionButtonClicked="addFieldToURL"
            >
              Add to URL
            </VasionButton>
          </div>
          <div class="">
            <VasionInput
              id="open-url-text"
              v-model="openURL.url"
              title="Open URL"
              inputType="top-white"
              name="open-url-text"
              placeholder="Open URL..."
              :width="'100%'"
              
              @input="emitWorkflowIsDirty"
            />
          </div>
          <div class="fields-grid">
            <md-radio
              v-model="openURL.openInNewWindow"
              @change="emitWorkflowIsDirty"
              value="newTab"
            >
              Open in New Tab
            </md-radio>
            <md-radio
            v-model="openURL.openInNewWindow"
              @change="emitWorkflowIsDirty"
              value="newWindow"
            >
              Open in New Window
            </md-radio>
          </div>
        </div>
        <table v-if="!isAutoStepType" class="action-details-table">
          <tr>
            <td>
              <VasionCheckbox
                v-if="linkedActionsArray.length > 0"
                id="run-other-action-check"
                name="run-other-action-check"
                :checked="runOtherAction"
                color="#95989a"
                :isDisabled="isBottomSectionDisabled"
                @change="toggleCheckbox('runOtherAction'); emitWorkflowIsDirty();"
              >
                Run Other Action When Complete
              </VasionCheckbox>
            </td>
          </tr>
        </table>
        <table v-if="!isAutoStepType" v-show="runOtherAction" class="action-details-table">
          <tr v-show="runOtherAction">
            <td>
              <VasionDropList
                id="run-other-action-droplist"
                v-slot="slotItem"
                v-model="selectedLinkedAction"
                title="Action"
                name="run-other-action-droplist"
                type="plain-list"
                :dataArray="linkedActionsArray"
                width="100%"
                valueName="value"
                displayName="name"
                placeholder=""
                @input="emitWorkflowIsDirty"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
            <td />
          </tr>
        </table>
        <hr v-if="!isAutoStepType" v-show="!runOtherAction">
        <table v-if="!isAutoStepType" v-show="!runOtherAction" class="action-details-table">
          <tr>
            <td>
              <div class="md-layout group-check-box">
                <VasionCheckbox v-if="selectedButtonAction.value !== 3"
                  id="reassign-document-check"
                  name="reassign-document-check"
                  class="reassign-document-check"
                  color="#95989a"
                  :checked="reassign"
                  :isDisabled="isBottomSectionDisabled"
                  @change="toggleCheckbox('reassign'); emitWorkflowIsDirty();"
                >
                  Reassign Document
                </VasionCheckbox>
                <VasionCheckbox
                  id="complete-step-check"
                  name="complete-step-check"
                  :checked="completeStep"
                  color="#95989a"
                  :isDisabled="isBottomSectionDisabled"
                  @change="toggleCheckbox('completeStep'); emitWorkflowIsDirty();"
                >
                  Complete the Step
                </VasionCheckbox>
              </div>
            </td>
            <td>
              <VasionDropList
                id="complete-step-type-droplist"
                v-slot="slotItem"
                v-model="selectedCompleteStepType"
                title="Complete Step Type"
                name="complete-step-type-droplist"
                type="plain-list"
                :dataArray="completeStepTypeArray"
                :openAbove="true"
                :isDisabled="!completeStep || useOriginalDocument"
                valueName="value"
                displayName="name"
                width="100%"
                placeholder=""
                @input="emitWorkflowIsDirty"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
          </tr>
          <tr>
            <td>
              <div class="md-layout">
                <VasionCheckbox
                  v-show="assignDocumentVisible"
                  id="assign-document-check"
                  name="assign-document-check"
                  class="assign-document-check"
                  :checked="assignDocument"
                  @change="toggleCheckbox('assignDocument'); emitWorkflowIsDirty();"
                >
                  Assign Document
                </VasionCheckbox>
                <VasionCheckbox
                  v-show="assignDocumentVisible && assignDocument && groupID > 0"
                  id="user-from-group-check"
                  name="user-from-group-check"
                  :checked="chooseUserFromGroup"
                  @change="toggleCheckbox('chooseUserFromGroup'); emitWorkflowIsDirty()"
                >
                  User from Group
                </VasionCheckbox>
              </div>
            </td>
            <td v-show="assignDocumentVisible && assignDocument">
              <VasionButton id="button-user-group" :classProp="'primary'" @vasionButtonClicked="showUserGroup = true; emitWorkflowIsDirty();">
                {{ assignmentButtonText }}
              </VasionButton>
            </td>
          </tr>
          <tr>
            <td>
              <VasionDropList
                v-show="assignDocumentVisible"
                id="email-template-droplist"
                v-slot="slotItem"
                v-model="emailTemplateObject"
                title="Email Template"
                name="email-template-droplist"
                type="plain-list"
                :dataArray="emailTemplates"
                valueName="value"
                displayName="name"
                width="385"
                placeholder="Select Email Template..."
                :openAbove="true"
                @input="emitWorkflowIsDirty"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
            <td>
              <VasionInput
                v-show="showSendEmailTo"
                id="send-to-email-text-reassign"
                v-model="emailAddress"
                title="Send to Email"
                name="send-to-email-text-reassign"
                placeholder="Enter Email..."
                inputType="top-white"
                @input="emitWorkflowIsDirty"
              />
            </td>
          </tr>
        </table>
      </div>
      <div v-show="!showUserGroup && !showGroupsOnly" class="bottom-div">
        <span class="error-text">{{ errorText }}</span>
        <VasionButton id="button-cancel" :classProp="'secondary'" @vasionButtonClicked="cancelClick()">
          Cancel
        </VasionButton>
        <VasionButton id="button-save" :classProp="'primary'" @vasionButtonClicked="saveClick()">
          Save
        </VasionButton>
      </div>
    </div>

    <!-- FILE UPLOAD  ------------------------------------------------>
    <div v-if="isUploadingFile && !isViewingDocument" class="document-upload">
      <span class="headline">Upload a Document</span>
      <span class="error-text">{{ errorText }}</span>
      <div class="upload-container">
        <div class="file-pond-block-wrapper">
          <file-pond ref="pond" class="new-document" :server="serverOptions" />
        </div>
      </div>
      <div class="auto-left-margin">
        <VasionButton
          id="close-file-upload"
          name="close-file-upload"
          :classProp="'secondary'"
          @vasionButtonClicked="isUploadingFile = false"
        >
          CANCEL
        </VasionButton>
        <VasionButton
          id="close-file-upload"
          name="close-file-upload"
          :classProp="'primary'"
          :isDisabled="!fileUploaded"
          @vasionButtonClicked="isUploadingFile = false; isViewingDocument = true; getImageProperties();"
        >
          CONTINUE
        </VasionButton>
      </div>
    </div>

    <!-- FILE VIEWER  ------------------------------------------------>
    <div v-if="!isUploadingFile && isViewingDocument && imagePropertiesLoaded" class="document-viewer">
      <div>
        <div class="auto-sign-header">
          <VasionButton
            id="close-viewer"
            name="close-viewer"
            title="Close Viewer"
            :icon="'VasionCancelIcon'"
            @vasionButtonClicked="isViewingDocument = false;"
          />
          <span class="headline">Auto Sign Configuration</span>
        </div>
        <div class="md-layout toolbar-div">
          <div class="toolbar-height">
            <VasionButton
              id="btnFirstPage"
              name="btnFirstPage"
              title="First Page"
              :icon="'VasionFirstPageIcon'"
              :isDisabled="isPreviousButtonsDisabled"
              @vasionButtonClicked="goToFirstPage()"
            />
            <VasionButton
              id="btnPreviousPage"
              name="btnPreviousPage"
              title="Previous Page"
              :icon="'VasionPreviousPageIcon'"
              :isDisabled="isPreviousButtonsDisabled"
              @vasionButtonClicked="goToPreviousPage()"
            />
          </div>
          <div class="w60 moveUp">
            <md-field>
              <md-input v-model="pageDisplay" class="w60 centerText" />
            </md-field>
          </div>
          <div class="toolbar-height">
            <VasionButton
              id="btnNextPage"
              name="btnNextPage"
              title="Next Page"
              :icon="'VasionNextPageIcon'"
              :isDisabled="isNextButtonsDisabled"
              @vasionButtonClicked="goToNextPage()"
            />
            <VasionButton
              id="btnLastPage"
              name="btnLastPage"
              title="Last Page"
              :icon="'VasionLastPageIcon'"
              :isDisabled="isNextButtonsDisabled"
              @vasionButtonClicked="goToLastPage()"
            />
          </div>
          <div class="divider" />
          <VasionButton
            id="add-signature"
            name="add-signature"
            title="Signature"
            :icon="'VasionGestureIcon'"
            @vasionButtonClicked="addNewAnnotation(annotationConfig.signatureZone); emitWorkflowIsDirty();"
          />
          <VasionButton
            id="save-signature"
            name="save-signature"
            title="Save"
            :icon="'VasionSaveIcon'"
            @vasionButtonClicked="saveSignatureZones(); emitWorkflowIsDirty();"
          />
        </div>
      </div>
      <div class="autoSign">
        <div class="lightestGreyBackground mainImageDiv">
          <DocumentImage
            v-if="uploadedfilePath && uploadedfilePath !== ''"
            ref="mainImage"
            :key="currentPageNumber"
            :documentID="0"
            :pageNumber="Number(currentPageNumber)"
            :width="viewerWidth"
            :height="viewerHeight"
            :zoomScale="1"
            :largeDefaultLoadSize="false"
            :context="'AutoSignConfig'"
            :imageEndpoint="'document/getFileImageURL'"
            :filePath="uploadedfilePath"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentImage from '@/components/document/DocumentImage.vue';
import { toBase64 } from '@/store/helperModules/storage.module';
import Loading from 'vue-loading-overlay';
import { uuid } from 'vue-uuid';

import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig';
import 'vue-loading-overlay/dist/vue-loading.css';
import { isValidURL } from '@/store/helperModules/common.module'

export default {
  name: 'CustomButtonActionDetails',
  components: {
    DocumentImage,
    Loading,
  },
  props: {
    actionDetails: {
      type: Object,
      required: false,
      default: () => {},
    },
    availableLinkedActions: {
      type: Array,
      required: false,
      default: () => [],
    },
    isAutoStepType: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: function () {
    return {
      actionName: '',
      annotationConfig: {
        signatureZone: { type: 'signatureZone', color: '', subType: '' },
      },
      applyDateToSignature: false,
      assignDocument: false,
      assignToDisplayName: '',
      attachDocument: false,
      base64PdfDataPrefix: 'data:application/pdf;base64,',
      base64TifDataPrefix: 'data:image/tiff;base64,',
      burnSignature: false,
      chooseUserFromGroup: false,
      commentRequired: false,
      completeStep: false,
      completeStepTypeArray: [
        {
          name: 'Approve',
          value: 1,
        },
        {
          name: 'Reject',
          value: 2,
        },
        {
          name: 'Forward',
          value: 3,
        },
        {
          name: 'Complete',
          value: 4,
        },
        {
          name: 'AutoStep',
          value: 5,
        },
      ],
      currentPageNumber: 1,
      currentSignature: {},
      customFields: {
        createUser: {
          firstName: '',
          lastName: '',
          email: '',
          groups: '',
        },
        createFolder: {
          driveStorageConfig: '',
          email: '',
          folderName: '',
          indexFieldsSelection: '',
        },
      },
      drives: [],
      emailAddress: '',
      emailTemplateIDForSendEmail: '',
      emailTemplateObject: '',
      errorText: '',
      fileFullPage: '',
      fileUploaded: false,
      fullPage: true,
      groupID: 0,
      id: 0,
      imageDetails: {},
      imagePropertiesLoaded: false,
      isCurrentFieldValid: false,
      isEmailValid: false,
      isLoading: false,
      isUploadingFile: false,
      isViewingDocument: false,
      linkedActionsArray: [],
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      openURL: {
        field: '',
        url: '',
        openInNewWindow: 'newTab',
      },
      radio: 'email',
      readyToLoad: false,
      reassign: false,
      runOtherAction: false,
      selectedButtonAction: { name: '', value: '' },
      selectedCompleteStepType: { name: 'Approve', value: 1 },
      selectedForm: { name: '', value: 0 },
      selectedLinkedAction: { name: '', value: '' },
      selectedLookup: { name: '', value: 0 },
      selectedSignatureTemplate: { name: '', value: '' },
      selectedTemplate: {},
      sendToEmail: '',
      sendToField: { name: '', value: '' },
      serverOptions: { process: this.processHandler },
      setFields: [],
      showGroupsOnly: false,
      showUserGroup: false,
      signatureForms: [{
        attributeForm: { name: '', value: '' },
        filePath: '',
        tempId: uuid.v1().toString(), // This is being set to handle objects that have not been saved yet, since we could have multiple of them
        signatureId: 0,
        height: '',
        left: '',
        top: '',
        width: '',
        pageNumber: '',
      }],
      totalPages: 1,
      uploadedFileName: '',
      uploadedfilePath: '',
      uploadedFileString: '',
      useOriginalDocument: false,
      userID: 0,
      viewerHeight: 1200,
      viewerWidth: 1000,
      workflowFieldObj: {},
      workflowList: [],
      workflowStatus: '',
    }
  },
  computed: {
    activeForm() { return this.$store.state.attributeForm.activeIndexForm },
    allIndexFields() { return this.$store.state.storage.uniqueFieldNames },
    assignDocumentVisible() { return (this.completeStep && !this.useOriginalDocument) || this.reassign },
    assignmentButtonText() {
      if (!this.assignToDisplayName) {
        return 'Select a User or Group'
      }

      return `Assign To: ${this.assignToDisplayName}`
    },
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    buttonActionsArray() {
      const buttons = [
        {
          name: 'None',
          value: 1,
        },
        {
          name: 'Auto Signature',
          value: 2,
        },
        {
          name: 'Create Portal User',
          value: 'Create Portal User',
        },
        {
          name: 'Create User Folder',
          value: 'Create User Folder',
        },
        {
          name: 'Lookup',
          value: 4,
        },
        {
          name: 'Open URL',
          value: 'Open URL',
        },
        {
          name: 'Send Email',
          value: 5,
        },
        {
          name: 'Set Fields',
          value: 3,
        },
        {
          name: 'Start Workflow',
          value: 6,
        },
        {
          name: 'Terminate',
          value: 9,
        },
        {
          name: 'Generate PDF',
          value: 'Generate PDF',
        },
      ]
      if (this.templateList?.length > 0) {
        buttons.push({ name: 'Document Template', value: 7 })
      }
      if (this.signatureTemplateList?.length > 0) {
        buttons.push({ name: 'Signature Template', value: 8 })
      }
      return buttons
    },
    emailTemplates() {
      const templateArray = this.$store.state.common.emailTemplates.map((t) => {
        return {
          name: t.sName,
          value: t.iID,
        }
      })

      templateArray.unshift({
        name: '[None]',
        value: 0,
      })

      return templateArray
    },
    emailTemplatesForSendEmail() {
      return this.emailTemplates.filter(t => {
        return t.name !== '[None]'
      })
    },
    formsLabel() { return $formsLabel.toUpperCase() },
    groups() { return this.$store.state.common.groups },
    groupsArray() { return this.groups.map(group => { return { name: group.name, value: group.value } }) },
    indexFields() {
      if (this.isAutoStepType) { 
        const fieldNamesToIgnore = ['Workflow_Status', 'Workflow_Approver', 'Workflow_Due_Date', 'Workflow_Initiator', 'CreatedBy']
        return this.$store.state.common.indexFields.filter( field => {
          return !fieldNamesToIgnore.includes(field.value)
        })
      }

      const filtered = this.$store.state.common.indexFields.filter((item) =>
        item.type !== 3 && item.type !==4 && item.type !== 6 && item.type !== 7 && item.type !== 11 && item.type !== 14
      )
      return filtered.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },

    isBottomSectionDisabled() { return this.selectedButtonAction && ((this.selectedButtonAction.value === 6) ||  (this.selectedButtonAction.value === 8 && this.useOriginalDocument) ||(this.selectedButtonAction.value === 9)) },
    isDuplicateActionName() { return this.linkedActionsArray.some(action => action.name === this.actionName) },
    isNextButtonsDisabled() { return this.currentPageNumber >= this.totalPages },
    isPreviousButtonsDisabled() { return this.currentPageNumber <= 1 },
    lookupList() {
      return this.$store.state.fieldMapping.lookupList.map(item => {
        return {
          name: item.name,
          value: item.id,
        }
      })
    },
    pageDisplay: {
      get: function () {
          return `${this.currentPageNumber}/${this.totalPages}`
      },
      set: function () {
      },
    },
    selectedEmailTemplateID() { return this.showSendEmailTo ? this.emailTemplateObject.value : null },
    selectedObject() { return this.attributeFormList.find(form => { return form.value === this.$store.state.workflow.selectedFormId }) },
    selectedObjectId() { return this.$store.state.workflow.selectedFormId },
    showSendEmailTo() { return this.assignDocumentVisible && this.emailTemplateObject && this.emailTemplateObject.value !== undefined && this.emailTemplateObject.value > 0 },
    signatureTemplateList() {
      const { Values } = this.$store.state.digitalSignature.templateData
      return Values?.map(template => {
        return { name: template.sName, value: template.iID }
      })
    },
    signatureZone() { return this.$store.state.workflow.signatureZoneSaveObject },
    templateList() {
      if (this.selectedObjectId === 0) {
        return this.$store.state.common.documentTemplates
      }
      return this.$store.state.common.documentTemplates.filter(template => { return template.objectName === this.selectedObject.name })
    },
    workflowListValues() {
      return this.workflowList.map(a => {
        return {
          name: a.sName,
          value: a.iID,
        }
      })
    },
  },
  watch: {
    completeStep: function () {
      if (this.completeStep) {
        this.reassign = false
      }
    },
    reassign: function () {
      if (this.reassign) {
        this.completeStep = false
      }
    },
    signatureZone: function () {
      // This code detects when a signature zone was saved in the document viewer, and then saves the details locally for later saving to the database
      if (this.signatureZone && this.signatureZone.Annotations && this.signatureZone.Annotations.Values && this.signatureZone.Annotations.Values.length > 0) {
        const annotation = this.signatureZone.Annotations.Values[0] // There should only be one
        const pageDpi = this.imageDetails.OriginalDPI
        const scaleX = this.imageDetails.OriginalWidth / this.imageDetails.Width / pageDpi
        const scaleY = this.imageDetails.OriginalHeight / this.imageDetails.Height / pageDpi
        const sigToUpdate = this.signatureForms.find(f => f.tempId === this.currentSignature.tempId)
        const sigToUpdateIndex = this.signatureForms.indexOf(sigToUpdate)
        if (sigToUpdate) {
          sigToUpdate.filePath = this.uploadedfilePath
          sigToUpdate.height = annotation.Height * scaleY
          sigToUpdate.left = annotation.PosX * scaleX
          sigToUpdate.top = annotation.PosY * scaleY
          sigToUpdate.width = annotation.Width * scaleX
          sigToUpdate.pageNumber = this.signatureZone.PageNumber
        }
        if (sigToUpdateIndex !== -1) {
          this.signatureForms[sigToUpdateIndex] = sigToUpdate
        }
      }
    },
  },
  async created() {
    this.isLoading = true
    this.updateSelectedForm()
    const promises = await Promise.all([
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.dispatch('fieldMapping/getDatabaseLookups', true),
      this.$store.dispatch('workflow/getWorkflowMenuItems'),
      this.$store.dispatch('common/getIndexFieldsForForm', this.selectedObjectId),
      this.$store.dispatch('common/getDocumentTemplates'),
      this.$store.dispatch('digitalSignature/getSignatureDocumentTemplates'),
    ])
    // eslint-disable-next-line
    this.workflowList = promises[2]

    const driveList = await this.$store.dispatch('storage/getConfiguredDriveTypes', { showOnlyDataAutomation: false })
    this.drives = driveList.map((element) => {
      return {
        name: element.storageDisplayName,
        value: element.storageConfigId,
      }
    })

    if (this.availableLinkedActions) {
      this.availableLinkedActions.map((element) => {
        this.linkedActionsArray.push(element)
        return true
      })
    }

    if (!this.actionDetails || !this.actionDetails.actionName) {
      this.isLoading = false
      return
    }
    this.actionName = this.actionDetails.actionName
    this.commentRequired = this.actionDetails.commentRequired
    this.id = this.actionDetails.ID
    this.workflowStatus = this.actionDetails.workflowStatus
    this.reassign = this.actionDetails.reassign
    this.completeStep = this.actionDetails.completeStep
    this.assignDocument = this.actionDetails.assignDocument
    this.chooseUserFromGroup = this.actionDetails.chooseUserFromGroup
    this.emailAddress = this.actionDetails.emailAddress
    this.isEmailValid = this.emailAddress === '' ? true : /\S+@\S+\.\S+/.test(this.emailAddress)
    this.userID = this.actionDetails.userID
    this.groupID = this.actionDetails.groupID
    this.runOtherAction = false
    this.selectedTemplate = this.templateList.find(template => { return template.documentTemplateID === Number(this.actionDetails.customHeaderProperties?.iCreateDocumentTemplateID) })

    if (this.userID > 0) {
      const foundUser = this.$store.state.common.users.find((u) => u.value === this.userID)
      if (foundUser) {
        this.assignToDisplayName = foundUser.name
      }
    } else if (this.groupID > 0) {
      const foundGroup = this.$store.state.common.groups.find((g) => g.value === this.groupID)
      if (foundGroup) {
        this.assignToDisplayName = foundGroup.name
      }
    }

    if (this.actionDetails.completeStepType !== undefined) {
      const foundType = this.completeStepTypeArray.find(element => element.value === this.actionDetails.completeStepType)
      if (foundType) {
        this.selectedCompleteStepType = foundType
      }
    }

    if (this.actionDetails.emailTemplateID !== null && this.actionDetails.emailTemplateID > 0) {
      this.emailTemplateObject = this.emailTemplates.find((f) => f.value === this.actionDetails.emailTemplateID) || ''
    }

    if (this.actionDetails.linkedActionName) {
      this.selectedLinkedAction = this.linkedActionsArray.find((f) => f.value === this.actionDetails.linkedActionName)
      this.runOtherAction = this.selectedLinkedAction !== null
    }

    
    await this.setButtonActionSigFormsAndSetFields()
    this.isLoading = false
  },
  methods: {
    addCustomNameField() {
      if (!this.customFields.createFolder.indexFieldsSelection?.value) return

      this.customFields.createFolder.folderName += `<%${this.customFields.createFolder.indexFieldsSelection.name}%>`
      this.customFields.createFolder.indexFieldsSelection = ''
    },
    addFieldToURL() {
      if (!this.openURL.field?.value) return

      this.openURL.url += `{${this.openURL.field.value}}`
      this.openURL.field = ''
    },
    addNewAnnotation(config) {
      this.$store.dispatch('document/setAnnotationConfig', config)
    },
    addNewSignatureForm() {
      this.signatureForms.push({
        attributeForm: {
          name: '',
          value: '',
        },
        filePath: '',
        tempId: uuid.v1().toString(), // This is being set to handle objects that have not been saved yet, since we could have multiple of them
        signatureId: 0,
        height: '',
        left: '',
        top: '',
        width: '',
        pageNumber: '',
      })
    },
    cancelClick() {
      this.$emit('close')
    },
    checkIfFieldIsAvailable(field) {
      const currentFolderName = this.customFields.createFolder.folderName
      this.isCurrentFieldValid = currentFolderName.includes(`<%${field.name}%>`)
    },
    emitWorkflowIsDirty() {
      this.$emit('markAsDirty', true)
    },
    async getImageProperties() {
      //reset if uploading a new image
      this.viewerHeight = 1200
      this.viewerWidth = 1000
      const payload = {
        FilePath: this.uploadedfilePath,
        ImageWidth: this.viewerWidth,
        ImageHeight: this.viewerHeight,
        IncludeAnno: false,
        PageNum: 1,
        RenderMode: 0,
        ThumbWidth: 100,
        ThumbHeight: 150,
        ZoomMode: 1,
        RotateDegrees: -2,
      }

      let imageData = await this.$store.dispatch('document/getFileImageURL', payload)
      if ((this.viewerHeight > this.viewerWidth && imageData.height < imageData.width)
        || (this.viewerHeight < this.viewerWidth && imageData.height > imageData.width)) {
        // need to flip the bounds
        const temp = this.viewerHeight
        this.viewerHeight = this.viewerWidth
        this.viewerWidth = temp
      }

      if (this.viewerHeight > imageData.height || this.viewerWidth > imageData.width) {
        this.viewerHeight = imageData.height
        this.viewerWidth = imageData.width
      }

      this.imagePropertiesLoaded = true

      this.imageDetails = {
        ImageSource: imageData.url,
        Width: imageData.width,
        Height: imageData.height,
        Annotations: { Values: imageData.annotations },
        PageDPI: imageData.PageDPI,
        OriginalDPI: imageData.OriginalDPI,
        OriginalHeight: imageData.OriginalHeight,
        OriginalWidth: imageData.OriginalWidth,
        FilePath: this.filePath,
      }
    },
    goToFirstPage() {
      this.goToPage(1)
    },
    goToLastPage() {
      this.goToPage(this.totalPages)
    },
    goToNextPage() {
      if ((this.currentPageNumber + 1) <= this.totalPages) {
        this.goToPage(this.currentPageNumber + 1)
      }
    },
    async goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPageNumber = pageNumber
      }
    },
    goToPreviousPage() {
      if (this.currentPageNumber > 1) {
        this.goToPage(this.currentPageNumber - 1)
      }
    },
    handleUserGroupSelection(selectedEntity) {
      if (!selectedEntity || !selectedEntity.type) {
        this.groupID = 0
        this.userID = 0
        this.assignToDisplayName = ''
      } else if (selectedEntity.type === 'groups') {
        this.userID = 0
        this.groupID = selectedEntity.value
        this.assignToDisplayName = selectedEntity.name
      } else if (selectedEntity.type === 'users') {
        this.groupID = 0
        this.userID = selectedEntity.value
        this.assignToDisplayName = selectedEntity.name
      }

      this.showUserGroup = false
    },
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      this.errorText = ''
      let rawData = ''

      const pdfIndex = file.name.indexOf('.pdf')
      const tiffIndex = file.name.indexOf('.tiff')
      const tifIndex = file.name.indexOf('.tif')
      if (pdfIndex < 0 && tiffIndex < 0 && tifIndex < 0) {
        const errorText = 'Only PDF, TIF, and TIFF file types are supported. Please try uploading one of those file types.'
        console.warn(errorText)
        this.errorText = errorText
      }
      this.uploadedFileString = await toBase64(file)
      this.uploadedFileName = file.name
      if (pdfIndex >= 0) {
        rawData = this.uploadedFileString.substring(this.base64PdfDataPrefix.length)
      } else if (tiffIndex >= 0) {
        rawData = this.uploadedFileString.substring(this.base64TifDataPrefix.length)
      } else if (tifIndex >= 0) {
        rawData = this.uploadedFileString.substring(this.base64TifDataPrefix.length)
      }

      progress(true, 0, 1024);
      load(file.name)

      this.uploadedfilePath = await this.$store.dispatch('workflow/uploadWorkflowAutoSignatureFile', { name: this.uploadedFileName, fileData: rawData })
      this.totalPages = await this.$store.dispatch('document/getFilePageCount', { Value: this.uploadedfilePath })
      this.fileUploaded = true

      return {
        abort: () => {
          if (abort !== undefined) {
            abort();
          }
        },
      };
    },
    removeSignatureForm(index) {
      this.signatureForms.splice(index, 1)
    },
    saveClick() {
      this.errorText = ''
      if (!this.actionName) {
        this.errorText = 'Please enter an Action Name.'
        return
      }
      if (this.actionName.trim() === '') {
        this.errorText = 'Action Name cannot only include spaces.'
        return
      }

      if (this.isDuplicateActionName) {
        this.errorText = 'Please enter a unique Action Name.'
        return
      }

      if (this.runOtherAction && (!this.selectedLinkedAction || this.selectedLinkedAction.value === '')) {
        this.errorText = 'Please select a Linked Action.'
        return
      }

      if (this.assignDocumentVisible && this.assignDocument && this.userID <= 0 && this.groupID <= 0) {
        this.errorText = 'Please select a User or Group.'
        return
      }

      if (this.selectedButtonAction.value === 1) { // Button Action = Nothing
        this.signatureForms = []
      }

      let customHeaderProperties = null
      let customListProperties = null
      if (this.selectedButtonAction.value === 2) { // Button Action = Auto Signature
        customHeaderProperties = {
          bBurnSignature: this.burnSignature ? 'True' : 'False',
          bApplyDate: this.applyDateToSignature ? 'True' : 'False',
        }

        const settingList = this.signatureForms.map((s) => {
          // TODO: setting the iAutoSigID to 0 when creating a new one is fine.
          // However, when loading existing objects from the server, we'll need to pass down the ID from there so the Server knows to do an update
          return {
              iAutoSigID: s.signatureId !== '' && s.signatureId !== '0' ? s.signatureId : '0',
              dLeft: s.left,
              dTop: s.top,
              dWidth: s.width,
              dHeight: s.height,
              iPageNumber: s.pageNumber,
              sSampleFile: s.filePath.replace('C:\\MV_Files\\_AutoSignFiles\\SampleFile.pdf', ''),
              iIndexFormID: s.attributeForm.value.toString(),
            }
        })

        customListProperties = {
          oSettingList: settingList,
        }
      }
      if (this.selectedButtonAction.value === 3) { // Button Actions = Set Fields
        if (this.selectedForm.value === undefined) {
          this.errorText = `Please select ${$formsLabelWithPrefix.toLowerCase()}`
          return
        }
        customHeaderProperties = {
          iIndexFormID: this.activeForm.ID
        }

        const filteredFields = this.setFields.filter(field => field.prompt || field.default !== '')
        if (filteredFields.length === 0) {
          this.errorText = 'Please select at least one field'
          return
        }
        const fields = filteredFields.map((s) => {
          return {
            iID: 0,
            iSetIndexFieldID: 0,
            iIndexFieldID: s.indexFieldID,
            sFieldTitle: s.title,
            sFieldName: s.name,
            iControlType: s.controlType,
            sDropDownString: '',
            bPrompt: s.prompt,
            bRequired: s.required,
            sDefault: s.default,
          }
        })

        customListProperties = {
          oFields: fields,
        }
      }
      if (this.selectedButtonAction.value === 4) { // Button Actions = Lookup
        if (this.selectedLookup.value <= 0) {
          this.errorText = 'Please select a Lookup'
          return
        }
        customHeaderProperties = {
          iLookupID: this.selectedLookup.value,
        }
      }

      if (this.selectedButtonAction.value === 5) {
        if (this.emailTemplateIDForSendEmail.value === undefined) {
          this.errorText = 'Please select an e-mail template'
          return
        }
        if (this.selectedObjectId === 0) {
          this.sendToField.value = this.sendToField.name
        }
        if (this.radio === 'field' && !this.sendToField.value) {
          this.errorText = 'Please select a Send To Field'
          return
        }
        if (this.radio === 'email') {
          if (this.sendToEmail.includes(',')) {
            const emails = this.sendToEmail.split(',')
            for (var i=0; i<emails.length; i++) {
              const isValid = /\S+@\S+\.\S+/.test(emails[i])
              if (!isValid) {
                this.errorText = emails[i] + ' is not a valid email address'
                return
              }
            }
          }
          else if (!this.isEmailValid) {
            this.errorText = 'Please enter a valid email address as the Send to Email'
            return
          }
        }
        customHeaderProperties = {
          iEmailTemplateID: this.emailTemplateIDForSendEmail.value.toString(),
          sSendToFieldName: this.radio === 'field' ? this.sendToField.value : null,
          sSendToAddress: this.radio === 'email' ? this.sendToEmail : null,
          bAttachDocument: this.attachDocument.toString(),
        }
      }

      if (this.selectedButtonAction.value === 6) {
        if (!this.workflowFieldObj?.value) {
          this.errorText = 'Please select a workflow field'
          return
        }
        customHeaderProperties = {
          iWFID: this.workflowFieldObj.value.toString(),
        }
        customListProperties = {}
      }

      if (this.selectedButtonAction.value === 7) {
        if (!this.selectedTemplate.documentTemplateID) {
          this.errorText = 'Please select a Document Template'
          return
        }
        customHeaderProperties = {
          iCreateDocumentTemplateID: this.selectedTemplate.documentTemplateID.toString(),
          bSendCurrentDocument: this.useOriginalDocument.toString(),
        }
        customListProperties = {}
      }
      if (this.selectedButtonAction.value === 8) {
        if (!this.selectedSignatureTemplate?.value) {
          this.errorText = 'Please select a Signature Template'
          return
        }
        customHeaderProperties = {
          iSigDocTemplateID: this.selectedSignatureTemplate.value.toString(),
          bSendCurrentDocument: this.useOriginalDocument.toString(),
        }
        customListProperties = {}
      }

      if (this.selectedButtonAction.value === 'Create User Folder') {
        if (!this.customFields.createFolder.email?.name) {
          this.errorText = 'Please choose a value for the Email(Username) field'
          return
        }
        if (this.customFields.createFolder.driveStorageConfig === undefined || this.customFields.createFolder.driveStorageConfig === "") {
          this.errorText = 'Please choose a value for the DRIVE LOCATION'
          return
        }
        
        customHeaderProperties = {
          EmailAddress: this.customFields.createFolder.email.name,
          FolderName: this.customFields.createFolder.folderName,
          DriveStorageConfig: this.customFields.createFolder.driveStorageConfig.value,
        }
      }

      if (this.selectedButtonAction.value === 'Create Portal User') {
        if (!this.customFields.createUser.firstName?.value) {
          this.errorText = 'Please choose a value for the First Name field'
          return
        }
        if (!this.customFields.createUser.lastName?.value) {
          this.errorText = 'Please choose a value for the Last Name field'
          return
        }
        if (!this.customFields.createUser.email?.value) {
          this.errorText = 'Please choose a value for the Email(Username) field'
          return
        }
        if (!this.customFields.createUser.groups.length) {
          this.errorText = 'Please choose a value for the Groups field'
          return
        }
        customHeaderProperties = {
          FirstName: this.customFields.createUser.firstName.value,
          LastName: this.customFields.createUser.lastName.value,
          EmailAddress: this.customFields.createUser.email.value,
          Groups: this.customFields.createUser.groups.map(group => { return group.value }).join(','),
        }
      }

      if (this.selectedButtonAction.value === 'Open URL') {
        if (!this.openURL.url) {
          this.errorText = 'Please enter a URL'
          return
        }
        if (!isValidURL(this.openURL.url)) {
          this.errorText = 'Please enter a valid URL'
          return
        }
        if (!this.openURL.openInNewWindow) {
          this.errorText = 'Please select an option for Open the URL'
          return
        }

        customHeaderProperties = {
          sOpenURL: this.openURL.url,
          OpenInNewWindow: this.openURL.openInNewWindow === 'newTab' ? false : true,
        }
      }

      const buttonDetails = {
        ID: this.id,
        actionName: this.actionName,
        actionType: this.selectedButtonAction ? this.selectedButtonAction.name : '',
        workflowStatus: this.workflowStatus,
        completeStep: this.completeStep,
        completeStepType:  (this.selectedButtonAction.name === 'Signature Template' && this.useOriginalDocument) || (this.completeStep && this.selectedCompleteStepType && this.selectedCompleteStepType.value !== undefined) ? this.selectedCompleteStepType.value : 1,
        assignDocument: this.assignDocumentVisible ? this.assignDocument : false,
        chooseUserFromGroup: this.assignDocumentVisible && this.assignDocument && this.groupID > 0 ? this.chooseUserFromGroup : false,
        userID: this.assignDocumentVisible && this.assignDocument ? this.userID : 0,
        groupID: this.assignDocumentVisible && this.assignDocument ? this.groupID : 0,
        reassign: this.reassign,
        commentRequired: this.commentRequired,
        emailTemplateID: this.selectedEmailTemplateID,
        emailAddress: this.selectedEmailTemplateID !== null ? this.emailAddress : '',
        linkedActionName: this.runOtherAction && this.selectedLinkedAction ? this.selectedLinkedAction.value : '',
        runOtherAction: this.runOtherAction,
        customHeaderProperties: customHeaderProperties,
        customListProperties: customListProperties,
      }
      this.$emit('button-updated', buttonDetails)
    },
    saveSignatureZones() {
      this.isViewingDocument = false;
    },
    async setButtonActionSigFormsAndSetFields() {
      if (this.actionDetails.actionType === 'Auto Signature') {
        this.selectedButtonAction = {
          name: 'Auto Signature',
          value: 2,
        }

        const self = this
        this.burnSignature = this.actionDetails.customHeaderProperties.bBurnSignature.toLowerCase() === "true"
        this.applyDateToSignature = this.actionDetails.customHeaderProperties.bApplyDate.toLowerCase() === "true"
        if (this.actionDetails.customListProperties && this.actionDetails.customListProperties.oSettingList) {
          // eslint-disable-next-line prefer-arrow-callback
          this.signatureForms = this.actionDetails.customListProperties.oSettingList.map(function (s) {
            const form = self.attributeFormList.find(f => f.value === Number(s.iIndexFormID))
            return {
              attributeForm: {
                name: form.name,
                value: form.value,
              },
              filePath: s.sSampleFile.replace('C:\\MV_Files\\_AutoSignFiles\\SampleFile.pdf', ''),
              tempId: uuid.v1().toString(),
              signatureId: s.iAutoSigID,
              height: s.dHeight,
              left: s.dLeft,
              top: s.dTop,
              width: s.dWidth,
              pageNumber: s.iPageNumber,
            }
          })
        }
      } else if (this.actionDetails.actionType === 'Set Fields') {
        this.selectedButtonAction = {
          name: 'Set Fields',
          value: 3,
        }
        this.selectedForm.value = Number(this.actionDetails.customHeaderProperties.iIndexFormID)
        await this.updateSelectedForm()
        const [...oFields] = this.actionDetails.customListProperties.oFields
        if (this.actionDetails.customListProperties && this.actionDetails.customListProperties.oFields) {
          for (let i = 0; i < oFields.length; i += 1) {
            for (let j = 0; j < this.setFields.length; j += 1) {
              if (this.setFields[j].indexFieldID.toString() === oFields[i].iIndexFieldID.toString()) {
                const prompt = oFields[i].bPrompt.toString()
                const required = oFields[i].bRequired.toString()
                this.setFields[j].prompt = (prompt.toLowerCase() === 'true')
                this.setFields[j].required = (required.toLowerCase() === 'true')
                this.setFields[j].default = oFields[i].sDefault
              }
            }
          }
        }
      } else if (this.actionDetails.actionType === 'Send Email') {
        this.selectedButtonAction = {
          name: 'Send Email',
          value: 5,
        }
        this.emailTemplateIDForSendEmail = this.emailTemplates.find(a => a.value === Number(this.actionDetails.customHeaderProperties.iEmailTemplateID))
        if (this.selectedObjectId !== 0) {
          this.sendToField = this.actionDetails.customHeaderProperties.sSendToFieldName ? this.indexFields.find(a => a.value === this.actionDetails.customHeaderProperties.sSendToFieldName) : ''
        } else {
          this.sendToField = this.actionDetails.customHeaderProperties.sSendToFieldName ? this.allIndexFields.find(a => a.value === this.actionDetails.customHeaderProperties.sSendToFieldName) : ''
        }
        if (!this.sendToField) {
          this.sendToField = {}
        }
        this.sendToEmail = this.actionDetails.customHeaderProperties.sSendToAddress
        this.attachDocument = this.actionDetails.customHeaderProperties.bAttachDocument.toLowerCase() === 'true'
        if (this.sendToField === '' || Object.keys(this.sendToField).length === 0) {
          this.radio = 'email'
        } else {
          this.radio = 'field'
        }
      } else if (this.actionDetails.actionType === 'Lookup') {
        this.selectedButtonAction = {
          name: 'Lookup',
          value: 4,
        }
        this.selectedLookup.value = Number(this.actionDetails.customHeaderProperties.iLookupID)
      } else if (this.actionDetails.actionType === 'Start Workflow') {
        this.selectedButtonAction = {
          name: 'Start Workflow',
          value: 6,
        }
        this.workflowFieldObj = this.workflowListValues.find(a => a.value === Number(this.actionDetails.customHeaderProperties.iWFID))
      } else if (this.actionDetails.actionType === 'Document Template') {
        this.selectedButtonAction = {
          name: 'Document Template',
          value: 7,
        }
        this.useOriginalDocument = this.actionDetails.customHeaderProperties?.bSendCurrentDocument.toLowerCase() === "true"
      } else if (this.actionDetails.actionType === 'Signature Template') {
        this.selectedButtonAction = {
          name: 'Signature Template',
          value: 8,
        }
      this.selectedSignatureTemplate = this.signatureTemplateList.find(t => t.value === Number(this.actionDetails.customHeaderProperties?.iSigDocTemplateID))
      this.useOriginalDocument = this.actionDetails.customHeaderProperties?.bSendCurrentDocument.toLowerCase() === "true"
      } else if (this.actionDetails.actionType === 'Terminate') {
        this.selectedButtonAction = {
          name: 'Terminate',
          value: 9,
        }
      } else if (this.actionDetails.actionType === 'Create User Folder') {
        this.selectedButtonAction = {
          name: 'Create User Folder',
          value: 'Create User Folder',
        }

        const email = this.actionDetails?.customHeaderProperties?.EmailAddress
        this.customFields.createFolder.email = this.indexFields.find(field => field.name === email)
        
        const driveStorage = this.actionDetails?.customHeaderProperties?.StorageConfigID
        this.customFields.createFolder.driveStorageConfig = this.drives.find(drive => drive.value == driveStorage)

        this.customFields.createFolder.folderName = this.actionDetails?.customHeaderProperties?.FolderName
      } else if (this.actionDetails.actionType === 'Create Portal User') {
        this.selectedButtonAction = {
          name: 'Create Portal User',
          value: 'Create Portal User',
        }

        const firstName = this.actionDetails?.customHeaderProperties?.FirstName
        this.customFields.createUser.firstName = this.indexFields.find(field => field.value === firstName)

        const lastName = this.actionDetails?.customHeaderProperties?.LastName
        this.customFields.createUser.lastName = this.indexFields.find(field => field.value === lastName)

        const email = this.actionDetails?.customHeaderProperties?.EmailAddress
        this.customFields.createUser.email = this.indexFields.find(field => field.value === email)

        const groupIDs = this.actionDetails?.customHeaderProperties?.Groups.split(',')
        this.customFields.createUser.groups = await this.groupsArray.filter( group => { return groupIDs.includes(String(group.value)) })
      } else if (this.actionDetails.actionType === 'Generate PDF') {
        this.selectedButtonAction = {
          name: 'Generate PDF',
          value: 'Generate PDF',
        }
      } else if (this.actionDetails.actionType === 'Open URL') {
        this.selectedButtonAction = {
          name: 'Open URL',
          value: 'Open URL',
        }

        let openInNewWindow = this.actionDetails?.customHeaderProperties?.OpenInNewWindow
        openInNewWindow = openInNewWindow ? openInNewWindow.toLowerCase() === "true" : false
        
        this.openURL.url = this.actionDetails?.customHeaderProperties?.sOpenURL
        this.openURL.openInNewWindow = openInNewWindow ? 'newWindow' : 'newTab'
      } else {
        this.selectedButtonAction = {
          name: 'None',
          value: 1,
        }
      }
    },
    setPrompt(index) {
      this.setFields[index].prompt = !this.setFields[index].prompt
    },
    setRequired(index) {
      this.setFields[index].required = !this.setFields[index].required
      if (this.setFields[index].required) {
        this.setFields[index].prompt = true
      }
    },
    async setSignatureLocation(tempId) {
      await this.$store.dispatch('workflow/storeSignatureZoneAnnotations', {})
      this.currentPageNumber = 1
      this.currentSignature = this.signatureForms.find(f => f.tempId === tempId)
      if (!this.currentSignature || typeof this.currentSignature.filePath === 'undefined') {
        console.warn('Could not find current signature')
        return
      }

      if (this.currentSignature.filePath === '') {
        this.fileUploaded = false
        this.isUploadingFile = true
        this.isViewingDocument = false
      } else {
        this.uploadedfilePath = this.currentSignature.filePath
        this.totalPages = await this.$store.dispatch('document/getFilePageCount', { Value: this.uploadedfilePath })
        this.fileUploaded = true
        this.isUploadingFile = false
        this.isViewingDocument = true
        await this.getImageProperties()
      }
    },
    handleButtonActionChange(){
      if(this.isBottomSectionDisabled){
        if(this.reassign){
          this.toggleCheckbox('reassign')
        }
        if(this.completeStep){
          this.toggleCheckbox('completeStep')
        }       
      }
      if(this.selectedButtonAction.name === 'Signature Template'){
          this.selectedCompleteStepType = this.completeStepTypeArray[3]
          if (this.useOriginalDocument) {
            this.completeStep = true
          }
      } else {
          this.selectedCompleteStepType = this.completeStepTypeArray[0]
      }
      if(this.selectedButtonAction.name === 'Set Fields') {
        this.reassign = false
      }
    },
    SetUseOriginalDocument(){
      this.useOriginalDocument = false
    },
    toggleCheckbox(id) {
      this[id] = !this[id]
    },
    async updateSelectedForm() {
      await this.$store.dispatch('attributeForm/getFormDetails', this.selectedObjectId)
      const fieldNamesToIgnore = ['Workflow_Status', 'Workflow_Approver', 'Workflow_Due_Date', 'Workflow_Initiator', 'CreatedBy']
      this.setFields = this.activeForm?.fields.filter( field => !(fieldNamesToIgnore.includes(field.name))).map((s) => {
        return {
          id: 0,
          setIndexFieldID: 0,
          indexFieldID: s.fieldID,
          title: s.title,
          name: s.title,
          controlType: s.type,
          dropDownString: '',
          prompt: false,
          required: false,
          default: '',
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .overflow-auto-div {
    overflow: auto;
  }

  .assign-document-check {
    margin-right: 40px;
  }

  .reassign-document-check {
    margin-right: 25px;
  }

  .action-details-table {
    width: 100%;

    td {
      width: 50%;
      padding: 5px;
    }
  }

  .column-align-right {
    text-align: right;
  }

  .action-details-div {
    overflow-y: auto;
    width: 800px;
    padding: 5px;
  }

  .body-div {
    min-height: 420px;
    width: 100%;
    padding: 5px;
  }

  .bottom-div {
    width: 100%;
    vertical-align: middle;
    line-height: 50px;
    text-align: right;
  }

  hr {
    border-width: 1px;
    border-color: $grey-100;
  }

  .group-check-box {
    width: 100%;
    margin-top: 20px;
  }

  .w30 {
    width: 30% !important;
  }

  .control-centered {
    margin-top: 20px;
  }

  .document-upload {
    border-radius: 8px;
    box-shadow: 0 8px 20px 0 rgba(22, 23, 24, 0.08);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;

    .auto-left-margin {
      margin-left: auto;
    }
    
    .upload-container {
      width: 100%;
    }
    
    .file-pond-block-wrapper {
      width: 100%;
      padding: 0;
    }
  }

  .document-viewer {
    height: 100%;
    overflow: hidden;
  }

  .divider {
    width: 1px;
    background-color: $divider-light-grey;
  }

  .headline {
    @include Headline;
    width: 100%;
  }

  .error-text {
    @include Text;
    width: 100%;
    color: red;
    margin: 20px;
  }

  .w60 {
    width: 86px;
    height: 30px;
  }

  .centerText {
    text-align: center;
  }

  .moveUp {
    position: relative;
    top: -20px;
  }

  .toolbar-div {
    border-bottom: solid 1px $divider-light-grey;
  }

  .toolbar-height {
    height: 25px;
  }

  .auto-sign-header {
    display: flex;
    padding: 15px;
  }
  
  .autoSign {
    height:  calc(100% - 103px);
  }

  .mainImageDiv {
    height: 100%;
    padding: 16px 16px 16px 16px;
    overflow: auto;
    text-align: center;
  }

  .lightestGreyBackground {
    background-color: $grey-75;
  }

  .set-fields{
    margin: 5px;
  }

  .prompt-checkbox{
    margin-left: 30px;
  }

  .required-checkbox{
    margin-left: 35px;
  }

  .white-background{
    background-color: white;
  }

  .wide{
    width: 45%;
  }

  #field-list{
    margin-top: 16px;
  }

  #lookup-section {
    padding: 5px;
  }

  .auto-value-input{
    padding: 3px;
  }

  div.form-row {
    padding: 6px 1px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  ::v-deep .btn-margin-fix button {
    margin-left: 0px;
  }
  @media (min-width: $breakpoint-xl) {
    .btn-margin-fix {
      position: relative;
      top: 17px;
    }
  }
  .fields-container {
    min-height: 344px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .fields-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    grid-gap: 16px;
  }
  .open-url-fields-container {
    margin-left: 4px;
    margin-right: 4px;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }
</style>
