<template>
  <div>
    <div class="setup-row">
      <VasionInput
        id="workflow-name"
        v-model="workflowName"
        title="WORKFLOW NAME"
        name="workflow-name"
        placeholder="Workflow Name"
        inputType="top-white"
        required
        :autoFocus="true"
        :isInErrorState="fieldErrorValues.workflowName"
        @input="emitWorkflowIsDirty(); clearFieldError('workflowName');"
      />

      <VasionInput
        id="initial-status"
        v-model="initialStatus"
        title="INITIAL STATUS"
        name="initial-status"
        placeholder="Initial Status"
        inputType="top-white"
        required
        :isInErrorState="fieldErrorValues.initialStatus"
        @input="emitWorkflowIsDirty(); clearFieldError('initialStatus')"
      />

      <VasionDropList
        v-slot="slotItem"
        v-model="initialReject"
        :dataArray="initialRejectFields"
        :width="'310'"
        title="INITIAL REJECT STEP"
        type="plain-list"
        displayName="name"
        required
        valueName="value"
        :showSearchField="false"
        :isInErrorState="fieldErrorValues.initialReject"
        @input="emitWorkflowIsDirty(); clearFieldError('initialReject')"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>
    <div class="setup-row">
      <VasionInput
        id="approved-destination"
        v-model="approvedDestinationFolder"
        class="row-field browse-input"
        title="COMPLETED DESTINATION"
        inputType="top-white"
        name="approved-destination"
        placeholder="Select Folder..."
        required
        :isDisabled="true"
        :width="'306'"
        :isInErrorState="fieldErrorValues.approvedDestinationFolder"
        @focus="clearFieldError('approvedDestinationFolder')"
      />
      <VasionButton
        id="approved-destination-button"
        class="browse-button"
        :classProp="'primary'"
        @vasionButtonClicked="toggleBrowseFolderDialog('approved-destination')"
      >
        Browse
      </VasionButton>

      <VasionInput
        id="rejected-destination"
        v-model="rejectedDestinationFolder"
        class="row-field browse-input"
        title="REJECTED DESTINATION"
        inputType="top-white"
        name="rejected-destination"
        placeholder="Select Folder..."
        :isDisabled="true"
        :width="'306'"
      />
      <VasionButton
        id="rejected-destination-button"
        class="browse-button"
        :classProp="'primary'"
        @vasionButtonClicked="toggleBrowseFolderDialog('rejected-destination')"
      >
        Browse
      </VasionButton>

      <VasionInput
        id="terminated-destination"
        v-model="terminatedDestinationFolder"
        class="row-field browse-input"
        title="TERMINATED DESTINATION"
        inputType="top-white"
        name="terminated-destination"
        placeholder="Select Folder..."
        :width="'306'"
        :isDisabled="true"
      />
      <VasionButton
        id="terminated-destination-button"
        class="browse-button"
        :classProp="'primary'"
        @vasionButtonClicked="toggleBrowseFolderDialog('terminated-destination')"
      >
        Browse
      </VasionButton>
    </div>
    <div class="setup-row">
      <VasionDropList
        v-slot="slotItem"
        v-model="priority"
        :dataArray="priorities"
        :width="'310'"
        title="PRIORITY"
        type="plain-list"
        displayName="name"
        valueName="value"
        @input="emitWorkflowIsDirty"
      >
        {{ slotItem.item.name }}
      </VasionDropList>

      <VasionDropList
        v-slot="slotItem"
        v-model="primaryIdentifier"
        :dataArray="indexFieldsForForm"
        :width="'310'"
        title="PRIMARY IDENTIFIER"
        type="plain-list"
        displayName="name"
        valueName="value"
        :closeOnOutsideClick="true"
        @input="emitWorkflowIsDirty"
      >
        {{ slotItem.item.name }}
      </VasionDropList>

      <VasionCheckbox
        id="use-duedate"
        name="use-duedate"
        class="extra-top-margin medium-checkbox"
        :checked="enableDueDate"
        @change="toggleCheckbox('enableDueDate'); emitWorkflowIsDirty();"
      >
        Enable Due Date
      </VasionCheckbox>

      <VasionInput
        id="due-date-days"
        v-model="dueDateDays"
        class="due-date"
        inputType="top-white"
        type="number"
        min="0"
        oninput="this.value = Math.abs(this.value)"
        width="100%"
        title="Due Date Days"
        name="due-date-days"
        :isDisabled="!enableDueDate"
        @input="emitWorkflowIsDirty();"
      />

      <VasionCheckbox
        id="can-modify-duedate"
        name="can-modify-duedate"
        class="extra-top-margin medium-checkbox"
        :checked="canModifyDueDate"
        :isDisabled="!enableDueDate"
        @change="toggleCheckbox('canModifyDueDate'); emitWorkflowIsDirty();"
      >
        Can Modify Due Date
      </VasionCheckbox>

      <VasionCheckbox
        id="can-modify-duedate"
        name="can-modify-duedate"
        class="extra-top-margin large-checkbox"
        :checked="generatePDFUponComplete"
        @change="toggleCheckbox('generatePDFUponComplete'); emitWorkflowIsDirty();"
      >
        Create PDF On Completion
      </VasionCheckbox>

      <VasionCheckbox
        id="allow-initiator-access"
        name="allow-initiator-access"
        class="extra-top-margin large-checkbox"
        :checked="allowInitiatorAccess"
        @change="toggleCheckbox('allowInitiatorAccess'); emitWorkflowIsDirty();"
      >
        Always Allow Access for Initiator
      </VasionCheckbox>
    </div>
    <div class="setup-row">
      <VasionDropList
        v-slot="slotItem"
        v-model="workflowInitiationType"
        :dataArray="workflowInitiationTypes"
        :width="'310'"
        :isInErrorState="fieldErrorValues.workflowInitiationType"
        title="WORKFLOW INITIATION TYPE"
        type="plain-list"
        displayName="name"
        required
        valueName="value"
        @input="emitWorkflowIsDirty(); checkReset(); primaryReset(); clearFieldError('workflowName');"
      >
        {{ slotItem.item.name }}
      </VasionDropList>

      <VasionDropList
        v-if="workflowInitiationType.value === 1 || workflowInitiationType.value === 0"
        v-slot="slotItem"
        v-model="selectedForm"
        :dataArray="indexForms"
        :width="'310'"
        :title="`${$formsLabel.toUpperCase()}`"
        type="plain-list"
        displayName="name"
        valueName="value"
        @input="emitWorkflowIsDirty(); primaryReset(); updateFormLayout(); clearAllWorkflowInitiationLookups();"
      >
        {{ slotItem.item.name }}
      </VasionDropList>

      <VasionDropList
        v-if="selectedForm.name && selectedForm.value > 0 && workflowInitiationType.value !== 0"
        v-slot="slotItem"
        v-model="formValue"
        :dataArray="maxxFormList.length !== 0 ? maxxFormList : [{name: '* No Available Forms to Use *', value: -1}]"
        :isInErrorState="fieldErrorValues.form"
        :width="'310'"
        :title="`${$layoutsLabel.toUpperCase()}`"
        type="plain-list"
        displayName="name"
        valueName="value"
        @input="emitWorkflowIsDirty(); primaryReset(); clearFieldErrors();"
      >
        {{ slotItem.item.name }}
      </VasionDropList>

      <VasionCheckbox
        v-if="workflowInitiationType.value === 0"
        id="view-upload"
        class="small-checkbox"
        name="view-upload"
        :isInErrorState="fieldErrorValues.upload"
        :checked="upload"
        @change="toggleCheckbox('upload'); emitWorkflowIsDirty(); clearFieldError('upload-scan-browse');"
      >
        Upload
      </VasionCheckbox>

      <VasionCheckbox
        v-if="workflowInitiationType.value === 0"
        id="view-browse"
        class="small-checkbox"
        name="view-browse"
        :checked="browse"
        :isInErrorState="fieldErrorValues.browse"
        @change="emitWorkflowIsDirty(); toggleCheckbox('browse'); clearFieldError('upload-scan-browse');"
      >
        Browse
      </VasionCheckbox>

      <VasionCheckbox
        v-if="workflowInitiationType.value === 0"
        id="view-scan"
        class="small-checkbox"
        name="view-scan"
        :checked="scan"
        :isInErrorState="fieldErrorValues.scan"
        @change="toggleCheckbox('scan'); emitWorkflowIsDirty(); clearFieldError('upload-scan-browse')"
      >
        Scan
      </VasionCheckbox>

      <VasionCheckbox
        v-if="workflowInitiationType.value === 0"
        id="file-attachments"
        class="medium-checkbox extra-top-margin"
        name="file-attachments"
        :checked="allowFileAttachments"
        @change="toggleCheckbox('allowFileAttachments'); emitWorkflowIsDirty();"
      >
        Allow File Attachments
      </VasionCheckbox>

      <VasionCheckbox
        id="open-in-data-view"
        class="extra-top-margin"
        name="open-in-data-view"
        :checked="openInDataView"
        @change="toggleCheckbox('openInDataView'); emitWorkflowIsDirty();"
      >
        Open Document in Data View
      </VasionCheckbox>
    </div>

    <div id="workflow-steps" class="collapsable-section">
      <h2 class="subheader">
        Workflow Steps
      </h2>

      <VasionButton
        name="collapse-section"
        class="collapse-section"
        :icon="showWorkflowSteps ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
        title="Collapse Section"
        @vasionButtonClicked="toggleCollapsableSection('workflow-steps')"
      />

      <div v-if="showWorkflowSteps" class="setup-row workflow-spacer">
        <div v-for="(step, index) of localStepData" :key="index" class="step">
          <div class="step-left-column">
            <div class="step-number-container">
              <div class="step-number">
                {{ index + 1 }}
              </div>
            </div>
            <div class="step-vertical-line" />
          </div>
          <div class="sub-step-container">
            <div v-for="(subStep, subIndex) in step" :key="subIndex" class="sub-step">
              <div v-if="subIndex > 0" class="step-horizontal-line" />
              <VasionInput
                :id="'step-name_' + subStep.stepID"
                v-model="subStep.name"
                :title="subIndex === 0 ? 'STEP NAME' : ''"
                :name="'step-name_' + subStep.stepID"
                placeholder="Step Name"
                :isInErrorState="subStep.nameError && subStep.name === ''"
                inputType="top-white"
                class="step-input"
                @input="emitWorkflowIsDirty"
              />

              <VasionDropList
                :id="'recipient-type_' + subStep.stepID"
                v-slot="slotItem"
                v-model="subStep.entityType"
                :placeholder="'Select User Or Group ...'"
                :name="'recipient-type_' + subStep.stepID"
                :dataArray="recipientTypes"
                :width="'310'"
                :title="subIndex === 0 ? 'STEP TYPE' : ''"
                :isInErrorState="subStep.typeError && subStep.entityType === ''"
                type="plain-list"
                valueName="value"
                displayName="name"
                @input="emitWorkflowIsDirty(); clearApprovername(subStep);"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionDropList
                v-if="subStep.entityType.value !== 'Auto'"
                :id="'recipient_' + subStep.stepID"
                v-slot="slotItem"
                v-model="subStep.approverName"
                :name="'recipient_' + subStep.stepID"
                :dataArray="theRecipientList(index, subIndex)"
                :width="'310'"
                :showSearchField="true"
                :title="subIndex === 0 ? 'RECIPIENT' : ''"
                type="plain-list"
                :isInErrorState="subStep.recipientError && subStep.approverName === ''"
                valueName="value"
                displayName="name"
                @input="emitWorkflowIsDirty"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionInput
                :id="'description_' + subStep.stepID"
                v-model="subStep.description"
                :title="subIndex === 0 ? 'DESCRIPTION' : ''"
                :name="'description_' + subStep.stepID"
                placeholder="Description Text ..."
                inputType="top-white"
                class="short"
                :width="'280'"
                :isInErrorState="subStep.stepDescription"
                @input="emitWorkflowIsDirty"
              />

              <div class="buttons">
                <VasionButton
                  v-if="subIndex === 0"
                  :id="'step-up_' + subStep.stepID"
                  :name="'step-up_' + subStep.stepID"
                  class="step-button"
                  :class="{ 'primary-step-button' : subIndex === 0}"
                  :isDense="true"
                  :icon="'VasionArrowUpIcon'"
                  :isDisabled="!shouldReorderUpShow(subStep.orderNumber)"
                  title="Move Field Up"
                  @vasionButtonClicked="reorderField('up', index)"
                />

                <VasionButton
                  v-if="subIndex === 0"
                  :id="'step-down_' + subStep.stepID"
                  :name="'step-down_' + subStep.stepID"
                  class="step-button"
                  :class="{ 'primary-step-button' : subIndex === 0}"
                  :isDense="true"
                  :icon="'VasionArrowDownIcon'"
                  :isDisabled="!shouldReorderDownShow(subStep.orderNumber)"
                  title="Move Field Down"
                  @vasionButtonClicked="reorderField('down', index)"
                />

                <VasionButton
                  :id="'step-configure_' + subStep.stepID"
                  :name="'step-configure_' + subStep.stepID"
                  class="step-button"
                  :class="{ 'primary-step-button' : subIndex === 0}"
                  :icon="'VasionSettingsIcon'"
                  title="Configure Step"
                  @vasionButtonClicked="configureStep(subStep, subIndex)"
                />

                <VasionButton
                  :id="'step-delete_' + subStep.stepID"
                  :name="'step-delete_' + subStep.stepID"
                  class="step-button"
                  :class="{ 'primary-step-button' : subIndex === 0}"
                  :icon="'VasionDeleteIcon'"
                  title="Delete Step"
                  @vasionButtonClicked="deleteStep(index, subIndex, subStep.name)"
                />
              </div>
            </div>
            <VasionButton
              v-if="parallelRoutingEnabled"
              id="add-parallel-step"
              name="add-parallel-step"
              title="add-parallel-step"
              :classProp="'secondary-grey'"
              @vasionButtonClicked="addSubStep(index + 1)"
            >
              Add Parallel Step
            </VasionButton>
          </div>
        </div>
        <div class="step-number-container">
          <VasionButton
            name="add-step"
            :icon="'VasionAddIcon'"
            title="Add Step"
            @vasionButtonClicked="addStep(localStepData.length + 1)"
          />
        </div>
        <div v-if="localStepData.length == 0" class="no-steps-hint">
          <span>*If there are no steps, the workflow will complete automatically.</span>
        </div>
      </div>
    </div>

    <div id="advanced-options" class="collapsable-section">
      <h2 class="subheader">
        Advanced Options
      </h2>

      <VasionButton
        name="collapse-section"
        class="collapse-section"
        :icon="showAdvancedOptions ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
        title="Collapse Section"
        @vasionButtonClicked="toggleCollapsableSection('advanced-options')"
      />

      <div v-if="showAdvancedOptions">
        <div class="setup-row">
          <VasionCheckbox
            id="include-admin"
            name="include-admin"
            :checked="includeAdmin"
            @change="toggleCheckbox('includeAdmin'); emitWorkflowIsDirty();"
          >
            Include Admin in All Steps
          </VasionCheckbox>

          <VasionCheckbox
            id="auto-approve-documents"
            name="auto-approve-documents"
            :checked="autoApproveDocuments"
            @change="toggleCheckbox('autoApproveDocuments'); emitWorkflowIsDirty();"
          >
            Auto Approve Documents
          </VasionCheckbox>

          <VasionCheckbox
            id="save-workflow-comments"
            name="save-workflow-comments"
            :checked="saveWorkflowComments"
            @change="toggleCheckbox('saveWorkflowComments'); emitWorkflowIsDirty();"
          >
            Save Workflow Comments
          </VasionCheckbox>

          <VasionCheckbox
            id="user-in-group"
            name="user-in-group"
            :checked="userInGroup"
            @change="toggleCheckbox('userInGroup'); emitWorkflowIsDirty();"
          >
            User in Group Can Only Approve Once
          </VasionCheckbox>

          <VasionCheckbox
            id="exit-workflow-on-reject"
            name="exit-workflow-on-reject"
            :checked="exitWorkflowOnReject"
            @change="toggleCheckbox('exitWorkflowOnReject'); emitWorkflowIsDirty();"
          >
            Exit Workflow on Reject
          </VasionCheckbox>
        </div>

        <div class="setup-row">
          <VasionDropList
            id="workflow-initiator-field"
            v-slot="slotItem"
            v-model="workflowInitiatorField"
            title="WORKFLOW INITIATOR FIELD"
            name="workflow-initiator-field"
            type="plain-list"
            :dataArray="indexFields"
            valueName="value"
            displayName="name"
            :width="'310'"
            @input=" emitWorkflowIsDirty"
          >
            {{ slotItem.item.name }}
          </VasionDropList>

          <VasionDropList
            id="workflow-action-field"
            v-slot="slotItem"
            v-model="workflowActionField"
            title="WORKFLOW ACTION FIELD"
            name="workflow-action-field"
            type="plain-list"
            :dataArray="indexFields"
            valueName="value"
            displayName="name"
            :width="'310'"
            @input=" emitWorkflowIsDirty"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div> <!-- END: setup-row -->
      </div> <!-- END: v-if="showAdvancedOptions" -->
    </div> <!-- END: v-if="showAdvancedOptions" -->

    <div id="email-options" class="collapsable-section">
      <h2 class="subheader">
        Email Options
      </h2>

      <VasionButton
        name="collapse-section"
        class="collapse-section"
        :icon="showEmailOptions ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
        title="Collapse Section"
        @vasionButtonClicked="toggleCollapsableSection('email-options')"
      />

      <div v-if="showEmailOptions">
        <div class="setup-row">
          <VasionInput
            id="email-sender-address"
            v-model="senderAddress"
            title="EMAIL SENDER ADDRESS"
            name="email-sender-address"
            placeholder=""
            inputType="top-white"
            @input="emitWorkflowIsDirty"
          />

          <VasionCheckbox
            id="send-email-complete"
            name="send-email-complete"
            class="extra-top-margin"
            :checked="sendEmailComplete"
            @change="toggleCheckbox('sendEmailComplete'); emitWorkflowIsDirty();"
          >
            Send Email When Complete
          </VasionCheckbox>

          <VasionCheckbox
            id="send-email-rejected"
            name="send-email-rejected"
            class="extra-top-margin"
            :checked="sendEmailRejected"
            @change="toggleCheckbox('sendEmailRejected'); emitWorkflowIsDirty();"
          >
            Send Email When Terminated
          </VasionCheckbox>

          <VasionCheckbox
            id="include-document"
            name="include-document"
            class="extra-top-margin"
            :checked="includeDocument"
            @change="toggleCheckbox('includeDocument'); emitWorkflowIsDirty();"
          >
            Include Document as Attachment
          </VasionCheckbox>
        </div>

        <div v-if="sendEmailComplete">
          <h3 class="subtitle">
            Complete Actions
          </h3>

          <div class="setup-row">
            <VasionDropList
              id="complete-template"
              v-slot="slotItem"
              v-model="completedEmailTemplateID"
              title="COMPLETE TEMPLATE"
              name="complete-template"
              type="plain-list"
              :dataArray="templates"
              valueName="value"
              displayName="name"
              :width="'275'"
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionButton
              name="add-complete-email-template-button"
              :icon="'VasionAddIcon'"
              title="Add Email Template"
              class="configure-template-icon-button"
              @vasionButtonClicked="addEmailTemplateClick()"
            />

            <!-- <VasionButton
              id="configure-complete-template"
              name="configure-complete-template"
              class="configure-template"
              :classProp="'secondary'"
              @vasionButtonClicked="configureCompleteTemplate()">
                CONFIGURE BASED ON INDEX
            </VasionButton> -->

            <VasionInput
              id="completed-send-to"
              v-model="completedSendToAddress"
              title="SEND TO"
              name="completed-send-to"
              placeholder=""
              inputType="top-white"
              @input="emitWorkflowIsDirty"
            />

            <VasionDropList
              id="send-to-index-field"
              v-slot="slotItem"
              v-model="completedSendToField"
              title="SEND TO FIELD"
              name="send-to-index-field"
              type="plain-list"
              :dataArray="indexFields"
              valueName="value"
              displayName="name"
              :width="'310'"
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div> <!-- END: setup-row -->
        </div> <!-- END: v-if="sendEmailComplete" -->

        <div v-if="sendEmailRejected">
          <h3 class="subtitle">
            Reject Actions
          </h3>

          <div class="setup-row">
            <VasionDropList
              id="reject-template"
              v-slot="slotItem"
              v-model="rejectedEmailTemplateID"
              title="REJECT TEMPLATE"
              name="reject-template"
              type="plain-list"
              :dataArray="templates"
              valueName="value"
              displayName="name"
              :width="'275'"
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionButton
              name="add-reject-email-template-button"
              :icon="'VasionAddIcon'"
              title="Add Email Template"
              class="configure-template-icon-button"
              @vasionButtonClicked="addEmailTemplateClick()"
            />

            <!-- <VasionButton
              id="configure-reject-template"
              name="configure-reject-template"
              class="configure-template"
              :classProp="'secondary'"
              @vasionButtonClicked="configureRejectTemplate()">
                CONFIGURE BASED ON INDEX
            </VasionButton> -->

            <VasionInput
              id="rejected-send-to"
              v-model="rejectedSendToAddress"
              title="SEND TO"
              name="rejected-send-to"
              placeholder=""
              inputType="top-white"
              @input="emitWorkflowIsDirty"
            />

            <VasionDropList
              id="send-to-index-field"
              v-slot="slotItem"
              v-model="rejectedSendToField"
              title="SEND TO FIELD"
              name="send-to-index-field"
              type="plain-list"
              :dataArray="indexFields"
              valueName="value"
              displayName="name"
              :width="'310'"
              @input="emitWorkflowIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div> <!-- END: setup-row -->
        </div> <!-- END: v-if="sendEmailRejected" -->
      </div> <!-- END: v-if="showEmailOptions" -->
    </div>

    <md-dialog id="confirmFormDelete" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="toggleDeleteDialog()" @yesButtonClick="deleteStepAndToggle()" />
    </md-dialog>

    <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <div class="folder-dialog-browse">
        <VasionFolders @itemSelected="handleFolderSelected" />
      </div>
      <div class="align-right">
        <VasionButton
          id="browse-cancel"
          classProp="secondary"
          @vasionButtonClicked="toggleBrowseFolderDialog()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="browse-ok"
          classProp="primary"
          :isDisabled="!folderSelected"
          @vasionButtonClicked="folderDialogOK(); emitWorkflowIsDirty()"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: 'WorkflowSettings',
  data: function () {
    return {
      allowFileAttachments: false,
      allowInitiatorAccess: false,
      approvedDestinationFolder: '',
      approvedDestinationFolderId: 0,
      attributeFormValue: '',
      autoApproveDocuments: false,
      box: false,
      browse: false,
      canModifyDueDate: false,
      completedEmailTemplateID: '',
      completedSendToAddress: '',
      completedSendToField: '',
      deleteStepName: '',
      deleteStepOrderNumber: 0,
      deleteSubStepOrderNumber: 0,
      dueDateDays: 0,
      enableDueDate: true,
      exitWorkflowOnReject: false,
      fileBrowseField: '',
      folderSelected: false,
      formValue: '',
      fieldErrorValues: {
        approvedDestinationFolder: false,
        browse: false,
        formValue: false,
        initialStatus: false,
        scan: false,
        upload: false,
        workflowInitiationType: false,
        workflowName: false,
        form: false,
      },
      generatePDFUponComplete: false,
      googleDrive: false,
      includeAdmin: false,
      includeDocument: false,
      indexForms: [],
      initialReject: '',
      initialStatus: '',
      invalidFormValue: false,
      localStepData: [[]],
      oneDrive: false,
      openInDataView: false,
      parallelRoutingEnabled: false,
      primaryIdentifier: '',
      priority: '',
      recipientUsers: [],
      rejectedDestinationFolder: '',
      rejectedDestinationFolderId: 0,
      rejectedEmailTemplateID: '',
      rejectedSendToAddress: '',
      rejectedSendToField: '',
      saveWorkflowComments: false,
      scan: false,
      selectedForm: '',
      sendEmailComplete: false,
      sendEmailRejected: false,
      senderAddress: '',
      shouldAddStepBeDisabled: false,
      showAdvancedOptions: false,
      showBrowseFoldersDialog: false,
      showDeleteDialog: false,
      showEmailOptions: false,
      showWorkflowSteps: true,
      terminatedDestinationFolder: '',
      terminatedDestinationFolderId: 0,
      upload: false,
      userInGroup: false,
      validateResultText: '',
      workflowActionField: '',
      workflowId: 0,
      workflowInitiationType: '',
      workflowInitiationTypes: [],
      workflowInitiatorField: '',
      workflowName: '',
    }
  },
  computed: {
    deletePromptMessage() { return `Are you sure you want to delete step "${this.deleteStepName}"?` },
    groups() { return this.$store.state.common.groups },
    indexFields() {
      const fields = this.$store.state.storage.uniqueFieldNames
      fields.unshift({
        name: '',
        value: -1,
      })
      return fields
    },
    indexFieldsForForm() {
      return this.$store.state.common.indexFields.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    initialRejectFields() {
      return [
        { name: 'Assign to Initiator ', value: 1 },
        { name: 'Send to Folder', value: 2 },
      ]
    },
    maxxFormList() {
      return this.$store.state.workflow.maxxFormList.map((f) => {
        return {
          name: f.Name,
          value: f.EFormID,
        }
      })
    },
    priorities() {
      if (Array.isArray(this.$store.state.workflow.priorities)) {
        return this.$store.state.workflow.priorities.map((p) => {
          return {
            name: p.priorityName,
            value: p.priorityID,
          }
        })
      }
      return []
    },
    recipientTypes() {
      return [
        { name: 'User', value: 'User' },
        { name: 'Group', value: 'Group' },
        { name: 'Auto', value: 'Auto' },
      ]
    },
    saveWorkflowID() { return this.workflowId <= 0 ? this.$store.state.admin.saveWorkflowID : this.workflowId },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    selectedWorkflow() { return this.$store.state.workflow.selectedWorkflow },
    stepHeaderData() { return this.$store.state.workflow.stepHeaderData },
    templates() {
      return this.$store.state.common.emailTemplates.map((t) => {
        return {
          name: t.sName,
          value: t.iID,
        }
      })
    },
    users() { return this.$store.state.common.users },
    vaults() {
      return this.$store.state.vault.vaultList.map((vault) => {
        return {
          name: vault.vaultName.trim(),
          value: vault.vaultID,
        }
      })
    },
    workflow() { return this.$store.state.workflow.workflowSettings },
    workflowEmail() { return this.$store.state.workflow.workflowEmailSettings },
  },
  watch: {
    formValue: function () {
      this.updateFormByFormLayout()
    },
    indexFieldsForForm: function () {
      this.primaryIdentifier = this.indexFieldsForForm.find((f) => f.value === this.workflow.primaryFieldName) || ''
    },
    selectedForm: function () {
      if (this.workflow) {
        const formID = this.selectedForm && this.selectedForm.value ? this.selectedForm.value : 0
        this.$store.dispatch('workflow/setSelectedFormId', formID)
        this.$store.dispatch('common/getIndexFieldsForForm', formID)
      }
    },
    stepHeaderData: async function () {
      await this.resetLocalSteps()
    },
    workflow: async function () {
      await this.setWorklowValues()
    },
    workflowEmail: function () {
      this.setWorkflowEmailValues()
    },
    workflowInitiationType: function () {
      if (!this.workflowInitiationType || this.workflowInitiationType < 0) {
        return
      }

      if (this.workflow && this.workflow.initiatorType === this.workflowInitiationType.value) {
        this.openInDataView = this.workflow.openInDataView
      } else {
        this.openInDataView = this.workflowInitiationType.value === 1
      }

      if (this.workflow && this.workflowInitiationType.value === 0) {
        this.upload = this.workflow.addDocTypes ? this.workflow.addDocTypes.upload : false
        this.scan = this.workflow.addDocTypes ? this.workflow.addDocTypes.scan : false
        this.browse = this.workflow.addDocTypes ? this.workflow.addDocTypes.browse : false
      }

      if (this.workflowInitiationType.value === 0) {
        const formID = this.workflow.indexFormID && this.workflow.indexFormID > 0 ? this.workflow.indexFormID : 0
        this.selectedForm = this.indexForms.find((form) => {
          return form.value === formID
        })

        if (!this.selectedForm) {
          this.selectedForm = {
            name: '',
            value: 0,
          }
        }
      } else {
        this.updateFormByFormLayout()
      }
    },
  },
  created: async function () {
    this.workflowInitiationTypes = [{ name: 'Upload / Browse / Scan', value: 0 }, { name: `${$layoutsLabel}`, value: 1 }]

    this.$emit('isLoading', { isLoading: true })
    this.indexForms = await this.$store.dispatch('attributeForm/getForms')
    this.indexForms.unshift({
      name: '',
      value: 0,
    })

    await Promise.all([
      this.$store.dispatch('vault/getVaultList'),
      this.$store.dispatch('common/getUsers', { includeExternalAPIUsers: true }),
      this.$store.dispatch('workflow/getWorkflowPriorities'),
      this.$store.dispatch('storage/loadUniqueFieldNames'),
      this.$store.dispatch('common/getEmailTemplates'),
      this.$store.dispatch('common/getGroups'),
      this.$store.dispatch('fieldMapping/getDatabaseLookups', true)
    ])
    this.recipientUsers = [...this.users]

    if (this.workflow.indexFormID > 0) {
      await this.$store.dispatch('common/getIndexFieldsForForm', this.workflow.indexFormID)
    }
    await this.$store.dispatch('workflow/getMaxxFormListForWorkflow', { workflowId: this.workflow.wfID, formId: 0 })

    this.parallelRoutingEnabled = this.$store.state.systemSettings.featureFlags.enableWFParallelRoute

    await Promise.all([
      this.setWorklowValues(),
      this.setWorkflowEmailValues(),
    ])
    await this.resetLocalSteps()
    this.$emit('isLoading', { isLoading: false })
  },
  methods: {
    addEmailTemplateClick() {
      this.$emit('addEmailTemplateClick')
    },
    addStep(orderNumber) {
      this.localStepData.push([{
        approverName: '',
        description: '',
        name: '',
        orderNumber: orderNumber,
        stepID: 0,
        entityType: '',
        assignedToEntity: 0,
        sendEmailToFieldName: '',
        sendEmail: false,
        emailTemplateID: null,
        includeDocLinkInEmail: false,
        approverEmailTemplateID: null,
        approverIncludeDocLinkInEmail: false,
        includeSupervisor: false,
        preStepLookupID: null,
        requireUserPassword: false,
        passwordText: '',
        buttonInfo: {},
        enabledAutoEscalation: false,
        escalationDays: 0,
        escalationHours: 0,
        escalationUserID: null,
        escalationGroupID: null,
        escalationEmailTemplate: '',
        autoStepSettingData: null,
        autoStepDisplayData: null,
        nameError: false,
        typeError: false,
        recipientError: false,
      }])

      this.emitWorkflowIsDirty()
    },
    addSubStep(orderNumber) {
      this.localStepData[orderNumber - 1].push({
        approverName: '',
        description: '',
        name: '',
        orderNumber: orderNumber,
        stepID: 0,
        entityType: '',
        assignedToEntity: 0,
        sendEmailToFieldName: '',
        sendEmail: false,
        emailTemplateID: null,
        includeDocLinkInEmail: false,
        approverEmailTemplateID: null,
        approverIncludeDocLinkInEmail: false,
        includeSupervisor: false,
        preStepLookupID: null,
        requireUserPassword: false,
        passwordText: '',
        buttonInfo: {},
        enabledAutoEscalation: false,
        escalationDays: 0,
        escalationHours: 0,
        escalationUserID: null,
        escalationGroupID: null,
        escalationEmailTemplate: '',
        autoStepSettingData: null,
        autoStepDisplayData: null,
        nameError: false,
        typeError: false,
        recipientError: false,
      })

      this.emitWorkflowIsDirty()
    },
    checkReset() {
      if (this.workflowInitiationType.value === 1) {
        this.upload = false
        this.scan = false
        this.browse = false
        this.allowFileAttachments = false
        this.clearAllWorkflowInitiationLookups()
      }
    },
    async clearAllWorkflowInitiationLookups() {
      await this.localStepData.forEach( (step, stepIndex) => {
        step.forEach( (subStep, subStepIndex) => {
          this.localStepData[stepIndex][subStepIndex].preStepLookupID = null
        })
      })
    },
    clearApprovername(step) {
      step.approverName = ''
    },
    clearFieldError(field) {
      // eslint-disable-next-line
      this.fieldErrorValues[field] = false
      this.$store.dispatch('workflow/clearTabError', 'settings')
    },
    clearFieldErrors() {
      // eslint-disable-next-line
      Object.keys(this.fieldErrorValues).map(key => this.fieldErrorValues[key] = false)
      this.$store.dispatch('workflow/clearTabError', 'settings')
    },
    configureCompleteTemplate() {
      // TODO: This is really just a placeholder for now
    },
    configureRejectTemplate() {
      // TODO: This is really just a placeholder for now
    },
    // eslint-disable-next-line
    configureStep(stepData, stepIndex) {
      stepData.subIndex = stepIndex
      stepData.workflowInitiationType = this.workflowInitiationType
      stepData.selectedForm = this.selectedForm
      if (this.validateStepsBeforeSave() === true) {
        this.$emit('configureStep', JSON.parse(JSON.stringify(stepData)))
      }
    },
    deleteStep(stepOrderNumber, subStepOrderNumber, stepName) {
      this.deleteStepOrderNumber = stepOrderNumber
      this.deleteSubStepOrderNumber = subStepOrderNumber
      this.deleteStepName = stepName
      this.toggleDeleteDialog()
    },
    async deleteStepAndToggle() {
      this.localStepData[this.deleteStepOrderNumber].splice(this.deleteSubStepOrderNumber, 1)
      if (this.localStepData[this.deleteStepOrderNumber].length === 0) {
        this.localStepData.splice(this.deleteStepOrderNumber, 1)
      }
      for (let i = 0; i < this.localStepData.length; i += 1) {
        this.localStepData[i].forEach(step => { step.orderNumber = i + 1 })
      }
      this.emitWorkflowIsDirty()
      this.toggleDeleteDialog()
    },
    emitWorkflowIsDirty() {
      this.$emit('markAsDirty', true)
    },
    folderDialogOK() {
      if (this.selectedFolder
        && this.selectedFolder.isSelected === true) {
        if (this.fileBrowseField === 'approved-destination') {
          this.approvedDestinationFolderId = this.selectedFolder.item.FolderId
          this.approvedDestinationFolder = this.selectedFolder.item.Name
        } else if (this.fileBrowseField === 'rejected-destination') {
          this.rejectedDestinationFolderId = this.selectedFolder.item.FolderId
          this.rejectedDestinationFolder = this.selectedFolder.item.Name
        } else {
          this.terminatedDestinationFolderId = this.selectedFolder.item.FolderId
          this.terminatedDestinationFolder = this.selectedFolder.item.Name
        }
      } else {
        this.approvedDestinationFolder = null
        this.approvedDestinationFolderId = ''

        this.rejectedDestinationFolderId = null
        this.rejectedDestinationFolder = ''

        this.terminatedDestinationFolderId = null
        this.terminatedDestinationFolder = ''
      }

      this.toggleBrowseFolderDialog()
    },
    getDroplistName(selection) {
      if (!selection) {
        return ''
      }

      if (typeof selection === 'object') {
        return selection.name
      }

      return ''
    },
    getDroplistUsername(selection) {
      if (!selection) {
        return ''
      }

      if (typeof selection === 'object') {
        return selection.username
      }

      return ''
    },
    getDroplistValue(selection) {
      if (!selection) {
        return null
      }

      if (typeof selection === 'object') {
        return selection.value
      }

      return null
    },
    handleFolderSelected() {
      this.folderSelected = true
    },
    primaryReset() {
      this.primaryIdentifier = ''
    },
    reorderField(direction, index) {
      const directionNum = direction === 'up' ? -1 : 1
      const movingStep = this.localStepData[index]
      this.localStepData.splice(index, 1)
      this.localStepData.splice(index + directionNum, 0, movingStep)

      for (let i = 0; i < this.localStepData.length; i += 1) {
        this.localStepData[i].forEach(step => { step.orderNumber = i + 1 })
      }
      this.emitWorkflowIsDirty()
    },
    async resetLocalSteps() {
      this.localStepData = this.$store.state.workflow.stepHeaderData.map((step) => {
        const theEntityType = this.recipientTypes.find((type) => type.value === step.entityType) || ''
        return {
          approverName: theEntityType.name === 'User'
            ? this.users.find((user) => user.username === step.approverName) || ''
            : this.groups.find((group) => group.name === step.approverName) || '',
          description: step.description,
          name: step.name,
          orderNumber: step.orderNumber,
          stepID: step.stepID,
          entityType: theEntityType,
          assignToEntity: step.assignToEntity,
          sendEmailToFieldName: step.sendEmailToFieldName,
          sendEmail: step.sendEmail,
          emailTemplateID: step.emailTemplateID,
          includeDocLinkInEmail: step.includeDocLinkInEmail,
          approverEmailTemplateID: step.approverEmailTemplateID,
          approverIncludeDocLinkInEmail: step.approverIncludeDocLinkInEmail,
          includeSupervisor: step.includeSupervisor,
          preStepLookupID: step.preStepLookupID,
          requireUserPassword: step.requireUserPassword,
          passwordText: step.passwordText,
          buttonInfo: step.buttonInfo,
          enabledAutoEscalation: step.enabledAutoEscalation,
          escalationDays: step.escalationDays,
          escalationHours: step.escalationHours,
          escalationUserID: step.escalationUserID,
          escalationGroupID: step.escalationGroupID,
          escalationEmailTemplate: step.escalationEmailTemplate,
          autoStepSettingData: null,
          autoStepDisplayData: null,
          documentTaskAction: step.documentTaskAction,
          assignTaskListID: step.assignTaskListID,
          reassignGroupID: step.reassignGroupID,
          selectUserFromGroup: step.selectUserFromGroup,
          failureLogic: step.failureLogic,
        }
      })
      const stepByOrderNumber = []
      this.localStepData.forEach((step) => {
        if (!stepByOrderNumber[step.orderNumber]) {
          stepByOrderNumber[step.orderNumber] = []
        }
        stepByOrderNumber[step.orderNumber].push(step)
      })
      this.localStepData = stepByOrderNumber.slice(1)
    },
    async save() {
      let success = true
      let newWorkflowID = 0

      const saveResult = {
        success: true,
        message: '',
      }

      if (this.validateBeforeSave() === false || this.validateStepsBeforeSave() === false) {
        saveResult.success = false
        saveResult.message = this.validateResultText
        return saveResult
      }
      const settingsPayload = {
        wfID: this.saveWorkflowID,
        name: this.workflowName,
        initialStatus: this.initialStatus,
        approvedFolderID: this.approvedDestinationFolderId,
        approvedFolderName: this.approvedDestinationFolder,
        rejectedFolderID: this.rejectedDestinationFolderId,
        rejectedFolderName: this.rejectedDestinationFolder,
        terminateFolderID: this.terminatedDestinationFolderId,
        terminateFolderName: this.terminatedDestinationFolder,
        includeAdminInAllSteps: this.includeAdmin,
        autoApproveDocuments: this.autoApproveDocuments,
        saveWorkflowComments: this.saveWorkflowComments,
        userInGroupCanOnlyApproveOnce: this.userInGroup,
        workflowInitiatorField: this.getDroplistName(this.indexFields.find((f) => f.value === this.workflowInitiatorField.value)),
        workflowActionField: this.getDroplistName(this.indexFields.find((f) => f.value === this.workflowActionField.value)),
        indexFormID: !this.selectedForm || this.selectedForm.value <= 0 ? null : this.selectedForm.value,
        defaultPriority: this.getDroplistValue(this.priorities.find((p) => p.value === this.priority.value)),
        canModifyDueDate: this.enableDueDate ? this.canModifyDueDate : false,
        initiatorType: this.getDroplistValue(this.workflowInitiationTypes.find((f) => f.value === this.workflowInitiationType.value)),
        primaryFieldName: this.getDroplistValue(this.indexFieldsForForm.find((f) => f.value === this.primaryIdentifier.value)),
        dueDateDays: this.enableDueDate ? this.dueDateDays : null,
        addDocTypes: {
          upload: this.upload,
          scan: this.scan,
          browse: this.browse,
          browseTypes: {
            vasionDrive: true,
            googleDrive: true,
            oneDrive: true,
            box: true,
            workDocs: true,
            s3: true,
            sharepoint: true,
          },
        },
        formID: (this.workflowInitiationType.value === 1) ? this.getDroplistValue(this.maxxFormList.find((f) => f.value === this.formValue.value)) : "",
        openInDataView: this.openInDataView,
        generatePDFUponComplete: this.generatePDFUponComplete,
        allowInitiatorAccess: this.allowInitiatorAccess,
        exitWorkflowOnReject: this.exitWorkflowOnReject,
        rejectStepAction: this.initialReject.value,
        enableDueDate: this.enableDueDate,
        supportAttachments: this.allowFileAttachments,
      }
      newWorkflowID = await this.$store.dispatch('workflow/saveWorkflowSettings', settingsPayload)
      this.workflowId = parseInt(newWorkflowID, 10)

      await Promise.all([
        this.$store.dispatch('workflow/getWorkflowSettings', this.workflowId),
        this.$store.dispatch('workflow/getWorkflowAdminList'),
      ])

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(this.workflowId) || typeof this.workflowId === 'undefined' || this.workflowId <= 0) {
        this.workflowId = 0
        saveResult.success = false
        saveResult.message = 'Unable to Save Workflow.'
        return saveResult
      }

      await this.$store.dispatch('admin/setSaveWorkflowID', this.workflowId)

      const stepPayload = {
        workflowID: this.workflowId,
        steps: [],
      }
      this.localStepData.forEach((step) => {
        step.forEach((subStep) => {

          subStep.buttonInfo?.customButtons?.forEach((customBtn) => {
            if (customBtn.customHeaderProperties && (customBtn.actionType !== "Create Portal User" && customBtn.actionType !== "Create User Folder")) {
              customBtn.customHeaderProperties.iIndexFormID = this.workflow.indexFormID
            }
          })

          stepPayload.steps.push({
            name: subStep.name,
            stepID: subStep.stepID,
            selectUserFromGroup: subStep.selectUserFromGroup,
            description: subStep.description,
            approverName: subStep.approverName.name,
            orderNumber: subStep.orderNumber,
            assignToEntity: subStep.approverName.value,
            entityType: subStep.entityType.value,
            sendEmailToFieldName: subStep.sendEmailToFieldName,
            sendEmail: subStep.sendEmail,
            emailTemplateID: subStep.emailTemplateID,
            includeDocLinkInEmail: subStep.includeDocLinkInEmail,
            approverEmailTemplateID: subStep.approverEmailTemplateID,
            approverIncludeDocLinkInEmail: subStep.approverIncludeDocLinkInEmail,
            includeSupervisor: subStep.includeSupervisor,
            preStepLookupID: subStep.preStepLookupID,
            requireUserPassword: subStep.requireUserPassword,
            passwordText: subStep.passwordText,
            buttonInfo: subStep.buttonInfo,
            enabledAutoEscalation: subStep.enabledAutoEscalation,
            escalationDays: subStep.escalationDays,
            escalationHours: subStep.escalationHours,
            escalationUserID: subStep.escalationUserID,
            escalationGroupID: subStep.escalationGroupID,
            escalationEmailTemplate: subStep.escalationEmailTemplate,
            autoStepSettingData: subStep.autoStepSettingData,
            documentTaskAction: subStep.documentTaskAction,
            assignTaskListID: subStep.assignTaskListID,
            reassignGroupID: subStep.reassignGroupID,
            failureLogic: subStep.failureLogic,
          })
        })
      })
      let result = await this.$store.dispatch('workflow/saveAllWorkflowSteps', stepPayload)
      this.$store.dispatch('workflow/getWorkStepHeaders', this.workflowId)
      if (result) {
        saveResult.message = 'Unable to save Workflow Steps. ' + result
        success = false
      }

      let localCompEmailTemplate = this.getDroplistValue(this.completedEmailTemplateID)
      if (localCompEmailTemplate === null || localCompEmailTemplate === '') {
        localCompEmailTemplate = 0
      }

      let localRejectEmailTemplate = this.getDroplistValue(this.rejectedEmailTemplateID)
      if (localRejectEmailTemplate === null || localRejectEmailTemplate === '') {
        localRejectEmailTemplate = 0
      }

      const workflowEmailPayload = {
        workflowID: this.workflowId,
        senderAddress: this.senderAddress,
        sendEmailWhenComplete: this.sendEmailComplete,
        sendEmailWhenRejected: this.sendEmailRejected,
        includeDocumentAttachment: this.includeDocument,
        completedEmailTemplateID: this.getDroplistValue(this.completedEmailTemplateID),
        rejectedEmailTemplateID: this.getDroplistValue(this.rejectedEmailTemplateID),
        completedSendToAddress: this.completedSendToAddress,
        completedSendToField: this.getDroplistName(this.indexFields.find((f) => f.value === this.completedSendToField.value)),
        rejectedSendToAddress: this.rejectedSendToAddress,
        completedCriteriaFields: this.workflowEmail.completedCriteriaFields,
        rejectedCriteriaFields: this.workflowEmail.rejectedCriteriaFields,
        rejectedSendToField: this.getDroplistName(this.indexFields.find((f) => f.value === this.rejectedSendToField.value)),
      }

      if (!(await this.$store.dispatch('workflow/saveWorkflowEmailSettings', workflowEmailPayload))) {
        success = false
        saveResult.message += 'Unable to save Email Settings. '
      }
      this.$store.dispatch('workflow/getWorkflowEmailSettings', this.workflowId)

      this.$store.dispatch('workflow/clearTabErrors')
      saveResult.success = success

      return saveResult
    },
    async setWorkflowEmailValues() {
      if (!this.workflowEmail || this.workflowEmail.senderAddress === undefined) {
        this.senderAddress = ''
        this.sendEmailComplete = false
        this.sendEmailRejected = false
        this.includeDocument = false
        this.completedSendToAddress = ''
        this.rejectedSendToAddress = ''
        this.completedSendToField = ''
        this.rejectedSendToField = ''
        this.rejectedEmailTemplateID = ''
        this.completedEmailTemplateID = ''
      } else {
        this.senderAddress = this.workflowEmail.senderAddress
        this.sendEmailComplete = this.workflowEmail.sendEmailWhenComplete
        this.sendEmailRejected = this.workflowEmail.sendEmailWhenRejected
        this.includeDocument = this.workflowEmail.includeDocumentAttachment
        this.completedSendToAddress = this.workflowEmail.completedSendToAddress
        this.rejectedSendToAddress = this.workflowEmail.rejectedSendToAddress
        this.completedSendToField = this.indexFields.find((f) => f.name === this.workflowEmail.completedSendToField) || ''
        this.rejectedSendToField = this.indexFields.find((f) => f.name === this.workflowEmail.rejectedSendToField) || ''
        this.rejectedEmailTemplateID = this.templates.find((t) => t.value === this.workflowEmail.rejectedEmailTemplateID) || ''
        this.completedEmailTemplateID = this.templates.find((t) => t.value === this.workflowEmail.completedEmailTemplateID) || ''
      }
    },
    async setWorklowValues() {
      if (!this.workflow) {
        return
      }
      this.workflowId = this.workflow.wfID
      this.workflowName = this.workflow.name
      this.approvedDestinationFolder = this.workflow.approvedFolderName
      this.approvedDestinationFolderId = this.workflow.approvedFolderID
      this.rejectedDestinationFolder = this.workflow.rejectedFolderName
      this.rejectedDestinationFolderId = this.workflow.rejectedFolderID
      this.terminatedDestinationFolder = this.workflow.terminateFolderName
      this.terminatedDestinationFolderId = this.workflow.terminateFolderID
      this.includeAdmin = this.workflow.includeAdminInAllSteps
      this.workflowActionField = this.indexFields.find((f) => f.name === this.workflow.workflowActionField) || ''
      this.workflowInitiatorField = this.indexFields.find((f) => f.name === this.workflow.workflowInitiatorField) || ''
      this.autoApproveDocuments = this.workflow.autoApproveDocuments
      this.saveWorkflowComments = this.workflow.saveWorkflowComments
      this.userInGroup = this.workflow.userInGroupCanOnlyApproveOnce
      this.initialStatus = this.workflow.initialStatus
      this.priority = this.priorities.find((p) => p.value === this.workflow.defaultPriority) || ''
      this.canModifyDueDate = this.workflow.canModifyDueDate
      this.upload = this.workflow.addDocTypes ? this.workflow.addDocTypes.upload : false
      this.scan = this.workflow.addDocTypes ? this.workflow.addDocTypes.scan : false
      this.browse = this.workflow.addDocTypes ? this.workflow.addDocTypes.browse : false
      this.workflowInitiationType = this.workflowInitiationTypes.find((v) => v.value === this.workflow.initiatorType) || ''
      this.openInDataView = this.workflow.openInDataView === undefined ? false : this.workflow.openInDataView
      this.generatePDFUponComplete = this.workflow.generatePDFUponComplete
      this.allowInitiatorAccess = this.workflow.allowInitiatorAccess
      this.dueDateDays = this.workflow.dueDateDays
      this.allowFileAttachments = this.workflow.supportAttachments

      if (this.workflowId > 0) {
        this.enableDueDate = this.workflow.enableDueDate === undefined ? this.dueDateDays > 0 : this.workflow.enableDueDate
      } else {
        this.enableDueDate = true
      }

      this.exitWorkflowOnReject = this.workflow.exitWorkflowOnReject
      this.initialReject = this.initialRejectFields.find(f => f.value === this.workflow.rejectStepAction) || ''

      const formID = this.workflow.indexFormID && this.workflow.indexFormID > 0 ? this.workflow.indexFormID : 0
      this.selectedForm = this.indexForms.find((form) => {
        return form.value === formID
      })
      await this.$store.dispatch('workflow/getMaxxFormListForWorkflow', { workflowId: this.workflow.wfID, formId: this.selectedForm.value })
      this.formValue = this.maxxFormList.find((f) => {
        return f.value === this.workflow.formID
      }) || ''
    },
    shouldReorderDownShow(sequence) {
      return sequence < this.localStepData.length
    },
    shouldReorderUpShow(sequence) {
      return sequence !== 1
    },
    theRecipientList(index, subIndex) {
      return this.localStepData[index][subIndex].entityType.name === 'User'
        ? this.recipientUsers.map(user => { return { name: user.name, value: user.value } })
        : this.groups.map(group => { return { name: group.name, value: group.value } })
    },
    toggleBrowseFolderDialog(field) {
      this.folderSelected = false
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog

      this.fileBrowseField = ''
      if (this.showBrowseFoldersDialog && field && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleCheckbox(id) {
      this[id] = !this[id]
    },
    toggleCollapsableSection(section) {
      switch (section) {
        case 'workflow-steps':
          this.showWorkflowSteps = !this.showWorkflowSteps
          break;
        case 'advanced-options':
          this.showAdvancedOptions = !this.showAdvancedOptions
          break;
        case 'email-options':
          this.showEmailOptions = !this.showEmailOptions
          break;
        default:
          this.showWorkflowSteps = true
          this.showAdvancedOptions = false
          this.showEmailOptions = false
          break;
      }
    },
    toggleDeleteDialog() {
      this.showDeleteDialog = !this.showDeleteDialog
    },
    updateFormByFormLayout() {
      if ((this.formValue.value === -1 || !this.formValue) && this.workflowInitiationType.value === 1) {
        this.invalidFormValue = true
        return
      }
      this.invalidFormValue = false

      if (this.workflowInitiationType && this.workflowInitiationType.value === 1 && this.formValue && this.formValue.value > 0) {
        const eForm = this.$store.state.workflow.maxxFormList.find((f) => f.EFormID === this.formValue.value)
        const formID = eForm && eForm.IndexFormID !== null && eForm.IndexFormID > 0 ? eForm.IndexFormID : 0
        this.$store.dispatch('workflow/setSelectedFormId', formID)
        this.$store.dispatch('common/getIndexFieldsForForm', formID)
      }
    },
    async updateFormLayout() {
      await this.$store.dispatch('workflow/getMaxxFormListForWorkflow', { workflowId: this.workflow.wfID, formId: this.selectedForm.value })
      if (this.selectedForm.name !== '' && this.selectedForm.value > 0) {
        // eslint-disable-next-line
        this.formValue = this.maxxFormList[0]
      } else {
        this.formValue = ''
      }
    },
    updateLocalStep(newStepData) {
      if (!newStepData) {
        return
      }

      const localStep = this.localStepData[newStepData.orderNumber - 1][newStepData.subIndex]
      if (!localStep) {
        return
      }

      localStep.name = newStepData.name
      localStep.sendEmail = newStepData.sendEmail
      localStep.selectUserFromGroup = newStepData.selectUserFromGroup
      localStep.emailTemplateID = newStepData.emailTemplateID
      localStep.includeDocLinkInEmail = newStepData.includeDocLinkInEmail
      localStep.approverEmailTemplateID = newStepData.approverEmailTemplateID
      localStep.approverIncludeDocLinkInEmail = newStepData.approverIncludeDocLinkInEmail
      localStep.includeSupervisor = newStepData.includeSupervisor
      localStep.preStepLookupID = newStepData.preStepLookupID
      localStep.passwordText = newStepData.passwordText
      localStep.requireUserPassword = newStepData.requireUserPassword
      localStep.sendEmailToFieldName = newStepData.sendEmailToFieldName
      localStep.buttonInfo = newStepData.buttonInfo
      localStep.enabledAutoEscalation = newStepData.enabledAutoEscalation
      localStep.escalationDays = newStepData.escalationDays
      localStep.escalationHours = newStepData.escalationHours
      localStep.escalationUserID = newStepData.escalationUserID
      localStep.escalationGroupID = newStepData.escalationGroupID
      localStep.escalationEmailTemplate = newStepData.escalationEmailTemplate
      localStep.autoStepSettingData = newStepData.autoStepSettingData
      localStep.autoStepDisplayData = newStepData.autoStepDisplayData
      localStep.documentTaskAction = newStepData.documentTaskAction
      localStep.assignTaskListID = newStepData.assignTaskListID
      localStep.reassignGroupID = newStepData.reassignGroupID
      localStep.failureLogic = newStepData.failureLogic

      this.localStepData[newStepData.orderNumber - 1][newStepData.subIndex] = localStep
    },
    validateBeforeSave() {
      let error = false
      let errorText = 'ERROR:'

      if (!this.workflowName || this.workflowName === '') {
        this.fieldErrorValues.workflowName = true
        this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
        errorText = `${errorText}
        - Please provide a name for the workflow.`
        error = true
      }
      if (this.workflowName.trim() === '') {
        this.fieldErrorValues.workflowName = true
        this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
        errorText = `${errorText}
        - Workflow Name cannot only include spaces.`
        error = true
      }

      if (!this.initialStatus || this.initialStatus === '') {
        this.fieldErrorValues.initialStatus = true
        this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
        errorText = `${errorText}
        - Please provide an initial status for the workflow.`
        error = true
      }

      if (!this.approvedDestinationFolder || this.approvedDestinationFolder === '' || this.approvedDestinationFolder === 0) {
        this.fieldErrorValues.approvedDestinationFolder = true
        this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
        errorText = `${errorText}
        - Please provide a completed destination for the workflow.`
        error = true
      }

      if (this.workflowInitiationType === '') {
        this.fieldErrorValues.workflowInitiationType = true
        this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
        errorText = `${errorText}
        - Please select a workflow initiation type for the workflow.`
        error = true
      }

      if (this.workflowInitiationType.value === 0 && !this.upload && !this.browse && !this.scan) {
        this.fieldErrorValues.workflowInitiationType = true
        this.fieldErrorValues.upload = true
        this.fieldErrorValues.scan = true
        this.fieldErrorValues.browse = true
        this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
        errorText = `${errorText}
        - If workflow initiation type is set to "Upload / Browse / Scan", at least one selection of "upload," "browse," or "scan" must be made.`
        error = true
      }

      if (this.workflowInitiationType.value === 1 && (!this.formValue || this.formValue === '' || this.formValue < 1)) {
        this.fieldErrorValues.workflowInitiationType = true
        this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
        this.fieldErrorValues.formValue = true
        errorText = `${errorText}
        - If workflow initiation type is set to "${$layoutsLabel}", ${$layoutsLabelWithPrefix} must be selected.`
        error = true
      }

      if (this.initialReject === '') {
        this.fieldErrorValues.initialReject = true
        this.$store.dispatch('workflow/setWorkflowTabError', 'initialReject')
        errorText = `${errorText}
        - Please select a Initial Reject Step for the workflow.`
        error = true
      }

      if (this.invalidFormValue) {
        this.fieldErrorValues.form = true
        this.$store.dispatch('workflow/setWorkflowTabError', 'form')
        error = true
        if (this.formValue) {
          errorText = `${errorText}
          - Invalid Form selected.`
        }
      }

      if (error) {
        this.validateResultText = errorText
        console.warn(errorText)
        return false
      }

      return true
    },
    validateStepsBeforeSave() {
      let error = false
      let errorText = 'ERROR: '

      this.localStepData.forEach((step) => {
        step.forEach((subStep) => {
          let missingName = false
          if (!subStep.name || subStep.name === '') {
            subStep.nameError = true
            this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
            errorText = `${errorText}
            - Please provide a step name for the step in sequence ${subStep.orderNumber}.`
            error = true
            missingName = true
          }
          if (subStep.name.trim() === '') {
            subStep.nameError = true
            this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
            errorText = `${errorText}
            - Step name, for the step in sequence ${subStep.orderNumber}, cannot only include spaces.`
            error = true
            missingName = true
          }

          const nameText = missingName ? `in sequence ${subStep.orderNumber}` : `named "${subStep.name}"`

          const type = subStep.entityType
          if (!type || (!typeof type === 'object')
            || (!type.name || type.name === '')
            || (!type.value || type.value === '')) {
              subStep.typeError = true
              this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
              errorText = `${errorText}
              - Please provide a step type for the step ${nameText}.`
              error = true
          }

          const recipient = subStep.approverName
          if ((!recipient || (!typeof recipient === 'object')
            || (!recipient.name || recipient.name === '')
            || (!recipient.value || recipient.value <= 0)) && type.value !== 'Auto') {
              subStep.recipientError = true
              this.$store.dispatch('workflow/setWorkflowTabError', 'settings')
              errorText = `${errorText}
              - Please provide a recipient for the step ${nameText}.`
              error = true
          }
        })
      })

      if (error) {
        this.validateResultText = errorText
        console.warn(errorText)
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.step-vertical-line{
  border-left: 1px dashed #979797;
  margin: 6px 0px 6px 19px;
  height: 100%;
}

.step-horizontal-line{
  border-bottom: 1px dashed #979797;
  display: block;
  position: absolute;
  left: -19px;
  padding: 20px 0px 0px 30px;
  z-index: -1;
  margin-left: 2px;
}

.step{
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.workflow-spacer{
  padding-top: 35px;
}

.sub-step{
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 15px;
}

.sub-step-container {
  position: relative;
  bottom: 30px;
}

.circle{
  margin-right: 10px;
  background-color: gray;
  border-radius: 20px;
  display: flex;
  width: 20px;
  align-items: center;
  justify-content: center;
}

.step-input{
  margin-right: 10px;
}

#add-parallel-step{
  margin-left: 8px;
}

.step-number{
  font-family: Archivo;
  font-size: 14px;
  font-weight: bold;
  color: #161718;

}

.step-left-column{
  display: flex;
  flex-direction: column;
}

.step-number-container{
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $grey-75;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.no-steps-hint{
  height: 40px;
  padding-left: 2rem;
  align-items: center;
  font-size: 1.25rem;
  display: flex;
}

#settings-tab {
    display: block;
    float: left;

    .setup-row {
      display: block;
      float: left;
      width: 1525px;
      margin: 6px 0;

      > .vasion-input {
        width: 310px;
        float: left;
        margin-right: 16px;

        &.short {
          width: 280px;
          margin-right: 0;
        }

        &.due-date {
          width: 100px;
        }
      }

      .vasion-droplist {
        float: left;
        margin-right: 16px;
      }

      .browse-input {
        margin-right: 5px;
      }

      .vasion-button {
        &.primary-step-button {
          float: left;
          margin-top: 23px;
        }

        &.step-button {
          float: left;
        }

        &.add-step {
          button {
            margin-left: 0;
          }
        }

        &.configure-template-icon-button {
          float: left;
          margin-left: -10px;
          margin-right: 3px;
          margin-top: 20px;
        }

        &.configure-template {
          float: left;
          width: 310px;
          margin-top: 15px;
          margin-right: 16px;

          button {
            width: 310px;
            margin-left: 0;
            margin-right: 0;
          }
        }

        &.browse-button {
          float: left;
          button {
            margin-top: 22px;
            margin-left: 0;
            margin-right: 13px;
          }
        }
      }

      .vasion-checkbox {
        float: left;
        margin: 0 16px 16px 0;

        &.extra-top-margin {
          margin-top: 28px;
        }
        &.small-checkbox {
          width: 89px;
          margin-top: 27px;
        }
        &.large-checkbox {
          width: 245px;
        }
        &.medium-checkbox {
          width: 185px;
        }
      }

      .buttons {
        width: 150px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .collapsable-section {
      float: left;
      margin-top: 20px;
      width: 1360px;

      .subheader {
        @include SubHeadline;
        width: 200px;
        display: block;
        float: left;
        margin-top: 5px;
      }

      .subtitle {
        @include Subtitle;
      }

      .step-wrapper {
        clear: left;
      }
    }
  }

  #browse-folders-dialog {
    .folder-dialog-browse {
      width: 400px;
      height: 300px;
      overflow: auto;
    }

    .align-right {
      text-align: right;
    }
  }
</style>
