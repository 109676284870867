<template>
  <div>
    <div class="md-layout bottom-separator-div">
      <div class="md-layout-item vasion-page-title">
        Edit {{ stepName }}
      </div>
      <div class="md-layout-item vasion-flex-end shift-right">
        <VasionButton id="done-button" :classProp="'primary'" @vasionButtonClicked="doneClick()">
          Done
        </VasionButton>
        <VasionButton id="cancel-button" :classProp="'secondary'" @vasionButtonClicked="cancelClick()">
          Cancel
        </VasionButton>
      </div>
    </div>
    <div class="md-layout tab-button-div">
      <VasionButton v-if="!isAutoStepType" id="general-tab-button" :classProp="generalTabClass" @vasionButtonClicked="updateSelectedTab('general')">
        General
      </VasionButton>
      <VasionButton id="button-info-tab-button" :classProp="buttonInfoTabClass" @vasionButtonClicked="updateSelectedTab('buttonInfo')">
        {{ isAutoStepType ? 'Automated Actions' : 'Button Information' }}
      </VasionButton>
      <VasionButton id="step-entry-logic-tab-button" :classProp="stepEntryLogicTabClass" @vasionButtonClicked="updateSelectedTab('stepEntryLogic')">
        Step Entry Logic
      </VasionButton>
    </div>
    <div
      v-if="showButtonInfoTab && isAutoStepType"
      class="edit-workflow-step-tab"
    >
      <div class="fields-flex-top">
        <VasionDropList
          id="failure-action"
          name="failure-action"
          v-slot="slotItem"
          v-model="failureAction"
          :dataArray="failureActionsList"
          :showSearchField="false"
          title="FAILURE ACTION"
          type="plain-list"
          placeholder="Select action..."
          class="fields-flex-item"
          displayName="name"
          valueName="value"
          @input="failureActionChange"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <!-- Assign to Group -->
        <VasionDropList
          v-if="failureAction && failureAction.value === 'Assign to Group'"
          id="assign-group-group"
          name="assign-group-group"
          v-slot="slotItem"
          v-model="failureActionData.assignToGroup.group"
          title="GROUP"
          type="plain-list"
          width="100%"
          valueName="value"
          displayName="name"
          placeholder="Select Group..."
          class="fields-flex-item"
          :dataArray="groups"
          @input="emitWorkflowIsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <!-- Assign to User -->
        <VasionDropList
          v-if="failureAction && failureAction.value === 'Assign to User'"
          id="assign-user-user"
          name="assign-user-user"
          v-slot="slotItem"
          v-model="failureActionData.assignToUser.user"
          title="USER"
          type="plain-list"
          width="100%"
          valueName="value"
          displayName="name"
          placeholder="Select User..."
          class="fields-flex-item"
          :dataArray="users"
          @input="emitWorkflowIsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div class="fields-flex">
        <VasionCheckbox
          v-if="failureAction && (failureAction.value === 'Assign to User' || failureAction.value === 'Assign to Group')"
          id="assign-user-send-email-notification"
          name="assign-user-send-email-notification"
          class="vasion-checkbox-01 mt-30px"
          :checked="failureActionData.sendEmail"
          @change="failureActionData.sendEmail = !failureActionData.sendEmail"
        >
          Send Email notification
        </VasionCheckbox>
      </div>
      <div class="fields-flex">
        <VasionInput
          v-if="failureAction && failureActionData.sendEmail && (failureAction.value === 'Assign to User' || failureAction.value === 'Assign to Group')"
          id="email-user-email-to"
          name="email-user-email-to"
          v-model="failureActionData.emailUser.emailTo"
          title="EMAIL TO"
          inputType="top-white"
          specialType="email"
          class="fields-flex-item"
          @input="emitWorkflowIsDirty"
          @notValid="isEmailValid = false"
          @isValid="isEmailValid = true"
        />
        <VasionDropList
          v-if="failureAction && failureActionData.sendEmail && (failureAction.value === 'Assign to User' || failureAction.value === 'Assign to Group')"
          id="email-user-email-to-field"
          name="email-user-email-to-field"
          v-slot="slotItem"
          v-model="failureActionData.emailUser.emailToField"
          title="EMAIL TO FIELD"
          type="plain-list"
          width="100%"
          valueName="value"
          displayName="name"
          placeholder="Select Email User Field..."
          class="fields-flex-item"
          :dataArray="indexFields"
          @input="emitWorkflowIsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <VasionDropList
          v-if="failureAction && failureActionData.sendEmail && (failureAction.value === 'Assign to User' || failureAction.value === 'Assign to Group')"
          id="assign-email-template"
          name="assign-email-template"
          v-slot="slotItem"
          v-model="failureActionData.emailUser.emailTemplate"
          title="EMAIL TEMPLATE"
          type="plain-list"
          width="100%"
          valueName="value"
          displayName="name"
          placeholder="Select Email Template..."
          class="fields-flex-item"
          :dataArray="emailTemplates"
          @input="emitWorkflowIsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
    </div>
    <div v-show="showGeneralTab" class="edit-workflow-step-tab">
      <div class="md-layout settings-row-div">
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedTask"
          :dataArray="tasks"
          :width="'300'"
          class="w300"
          title="TASKLIST"
          type="plain-list"
          displayName="name"
          placeholder="Select Tasklist..."
          valueName="value"
          @input="emitWorkflowIsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedTaskRequirement"
          :dataArray="taskRequirementsList"
          :width="'300'"
          class="w300"
          title="TASK REQUIREMENTS"
          type="plain-list"
          displayName="name"
          placeholder="Select Task Requirement..."
          valueName="value"
          @input="emitWorkflowIsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div class="md-layout settings-row-div">
        <VasionCheckbox
          id="send-email-when-entering"
          name="send-email-when-entering"
          class="w300 extra-top-margin"
          :checked="sendEmail"
          @change="toggleCheckbox('sendEmail'); emitWorkflowIsDirty();"
        >
          Send Email When Entering Step
        </VasionCheckbox>
        <VasionDropList
          v-show="sendEmail"
          id="approver-email-template"
          v-slot="slotItem"
          v-model="approverEmailTemplateObject"
          title="STEP EMAIL TEMPLATE"
          name="approver-email-template"
          type="plain-list"
          :dataArray="templates"
          valueName="value"
          displayName="name"
          :width="'300'"
          class="w300"
          placeholder="Choose Email Template..."
          @input="emitWorkflowIsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <VasionCheckbox
          v-show="sendEmail"
          id="approver-include-document-link"
          name="approver-include-document-link"
          class="w300 extra-top-margin"
          :checked="approverIncludeDocLinkInEmail"
          @change="toggleCheckbox('approverIncludeDocLinkInEmail'); emitWorkflowIsDirty();"
        >
          Include Document Link for Step Email
        </VasionCheckbox>
      </div>

      <div class="md-layout settings-row-div">
        <VasionDropList
          v-show="sendEmail"
          v-slot="slotItem"
          v-model="sendEmailToObject"
          :dataArray="indexFieldsForForm"
          :width="'300'"
          class="w300"
          title="SEND EMAIL TO"
          type="plain-list"
          displayName="name"
          placeholder="Select Field..."
          valueName="value"
          @input="emitWorkflowIsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>

        <VasionDropList
          v-show="sendEmail"
          id="email-template"
          v-slot="slotItem"
          v-model="emailTemplateObject"
          title="EMAIL TEMPLATE"
          name="email-template"
          type="plain-list"
          :dataArray="templates"
          valueName="value"
          displayName="name"
          :width="'300'"
          class="w300"
          placeholder="Choose Email Template..."
          @input="emitWorkflowIsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>

        <VasionCheckbox
          v-show="sendEmail"
          id="include-document-link"
          name="include-document-link"
          class="w396 extra-top-margin"
          :checked="includeDocLinkInEmail"
          @change="toggleCheckbox('includeDocLinkInEmail'); emitWorkflowIsDirty();"
        >
          Include Document Link
        </VasionCheckbox>
      </div>

      <div class="md-layout settings-row-div">
        <VasionCheckbox
          v-if="showSelectUserFromGroup"
          id="send-email-to-approver"
          name="end-email-to-approver"
          class="w300"
          :checked="selectUserFromGroup"
          @change="toggleCheckbox('selectUserFromGroup'); emitWorkflowIsDirty();"
        >
          Select User from Group
        </VasionCheckbox>
      </div>

      <div class="settings-row-div">
        <VasionCheckbox
          id="require-user-password"
          name="require-user-password"
          class="w396"
          :checked="requireUserPassword"
          @change="toggleCheckbox('requireUserPassword'); emitWorkflowIsDirty();"
        >
          Require User Password
        </VasionCheckbox>
      </div>
      <div class="settings-row-div padding-top">
        <VasionInput
          id="step-password"
          v-model="passwordText"
          title="STEP PASSWORD"
          name="step-password"
          placeholder="Create Password"
          inputType="top-white"
          type="password"
          class="w300"
          :readonly="passwordReadonly"
          @input="emitWorkflowIsDirty"
        />
      </div>

      <div class="settings-row-div">
        <VasionCheckbox
          id="include-supervisor"
          name="include-supervisor"
          class="w396"
          :checked="includeSupervisor"
          @change="toggleCheckbox('includeSupervisor'); emitWorkflowIsDirty();"
        >
          Include Group Supervisors (Groups Only)
        </VasionCheckbox>
      </div>

      <div class="settings-row-div padding-top">
        <VasionDropList
          v-if="stepData.workflowInitiationType.value === 0"
          id="workflow-initiation-lookup"
          v-slot="slotItem"
          v-model="workflowInitiationLookup"
          name="workflow-initiation-lookup"
          title="WORKFLOW INITIATION LOOKUP"
          :dataArray="lookupList"
          width="300"
          placeholder="Select Lookup..."
          type="plain-list"
          valueName="id"
          displayName="name"
          @input="emitWorkflowIsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>

      <!-- Auto Escalation -->
      <div id="auto-escalation" class="collapsable-section">
        <h2 class="subheader">
          Auto Escalation
        </h2>

        <VasionButton
          name="collapse-section"
          class="collapse-section"
          :icon="showAutoEscalation ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
          title="Collapse Section"
          @vasionButtonClicked="toggleCollapsableSection('auto-escalation')"
        />

        <div v-show="showAutoEscalation">
          <div class="settings-row-div">
            <VasionCheckbox
              id="enable-auto-escalation"
              name="enable-auto-escalation"
              class="w396"
              :checked="enableAutoEscalation"
              @change="toggleCheckbox('enableAutoEscalation'); emitWorkflowIsDirty();"
            >
              Enable Auto Escalation
            </VasionCheckbox>
          </div>

          <div v-show="enableAutoEscalation">
            <div class="settings-row-div">
              <span>Auto Escalation After:</span>
            </div>
            <div class="settings-row-div flex">
              <VasionInput
                id="escalation-days"
                v-model="escalationDays"
                title="DAYS"
                name="escalation-days"
                placeholder="0"
                inputType="top-white"
                type="number"
                class="days-hours"
                min="0"
                @input="emitWorkflowIsDirty"
              />

              <VasionInput
                id="escalation-hours"
                v-model="escalationHours"
                title="HOURS"
                name="escalation-hours"
                placeholder="0"
                inputType="top-white"
                type="number"
                class="days-hours"
                min="0"
                @input="emitWorkflowIsDirty"
              />
            </div>

            <div id="assign-row" class="settings-row-div">
              <span class="assign-to">ASSIGN TO:</span>
              <div class="vasion-flex-row">
                <md-radio v-model="assignToType" class="user-group" value="user">
                  User
                </md-radio>
                <md-radio v-model="assignToType" class="user-group" value="group">
                  Group
                </md-radio>
                <VasionDropList
                  id="choose-user-group"
                  v-slot="slotItem"
                  v-model="userGroup"
                  name="choose-user-group"
                  type="plain-list"
                  :dataArray="userGroupList"
                  valueName="value"
                  displayName="name"
                  :width="'300'"
                  class="w300"
                  :placeholder="`Choose ${userGroupLabel}...`"
                  @input="emitWorkflowIsDirty"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </div>
            </div>

            <div id="alert-row" class="settings-row-div">
              <VasionDropList
                id="alert-email-template"
                v-slot="slotItem"
                v-model="alertEmailTemplate"
                title="ALERT EMAIL TEMPLATE"
                name="alert-email-template"
                type="plain-list"
                :dataArray="templates"
                valueName="value"
                displayName="name"
                :width="'300'"
                class="w300"
                placeholder="Choose Email Template..."
                @input="emitWorkflowIsDirty"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showButtonInfoTab" class="edit-workflow-step-tab">
      <div v-show="!isAutoStepType">
        <ButtonInfo
          ref="approvedButton"
          :buttonInfoProp="buttonInfo.approvedButton"
          buttonName="Approved"
          :showTitleRow="true"
          @markAsDirty="emitWorkflowIsDirty"
        />
        <ButtonInfo 
          ref="completedButton" 
          :buttonInfoProp="buttonInfo.completedButton" 
          buttonName="Completed" 
          @markAsDirty="emitWorkflowIsDirty"
        />
        <ButtonInfo 
          ref="forwardButton" 
          :buttonInfoProp="buttonInfo.forwardButton" 
          buttonName="Forward" 
          @markAsDirty="emitWorkflowIsDirty"
        />
        <ButtonInfo 
          ref="rejectButton" 
          :buttonInfoProp="buttonInfo.rejectButton" 
          buttonName="Reject" 
          @markAsDirty="emitWorkflowIsDirty"
        />
        <ButtonInfo
          ref="reassignButton"
          :reassignGroupID="reassignGroupID"
          :buttonInfoProp="buttonInfo.reassignButton"
          buttonName="Reassign"
          @markAsDirty="emitWorkflowIsDirty"
        />
        <div>
          <VasionCheckbox
            id="custom-button-enabled"
            name="custom-button-enabled"
            class="custom-button-check-box"
            :checked="customButtonEnabled"
            @change="toggleCheckbox('customButtonEnabled'); emitWorkflowIsDirty();"
          >
            Custom
          </VasionCheckbox>
        </div>
      </div>
      <div v-show="customButtonEnabled || isAutoStepType" class="vasion-html-table-default">
        <table>
          <thead>
            <tr>
              <th class="move-icon-column" />
              <th>
                <label class="vasion-html-table-header">Name</label>
              </th>
              <th>
                <label class="vasion-html-table-header">Workflow Status</label>
              </th>
              <th>
                <label class="vasion-html-table-header">Action</label>
              </th>
              <th class="controls-column" />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(button, index) in buttonInfo.customButtons"
              :key="index"
              :class="{'vasion-lightest-grey-background': index % 2 === 0, 'dropTarget': index == dragRowTargetIndex }" 
              @click="rowClickHandler($event, button)"
              draggable
              @dragstart="dragRowStart($event, index)"
              @dragover="dragRowOver($event, index)"
              @drop="dragRowDrop($event, index)"
              @dragend="dragRowEnd()"
            >
              <td class="move-icon move-icon-column">
                <VasionMoveIcon />
              </td>
              <td>
                <label class="vasion-html-table-field">{{ button.actionName | emptyStringDash }}</label>
              </td>
              <td>
                <label class="vasion-html-table-field">{{ button.workflowStatus | emptyStringDash }}</label>
              </td>
              <td>
                <label class="vasion-html-table-field">{{ button.actionType | emptyStringDash }}</label>
              </td>
              <td class="controls-column">
                <div id="ellipsis-button" class="md-layout">
                  <VasionButton
                    :isRaised="false"
                    :isDense="false"
                    :icon="'VasionEllipsisIcon'"
                    @vasionButtonClicked="ellipsisOpen($event, button)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <VasionButton id="add-new-custom-button" :classProp="'primary-light'" @vasionButtonClicked="addCustomActionClick()">
          Add New
        </VasionButton>
      </div>
    </div>
    <div v-if="showStepEntryLogicTab" class="edit-workflow-step-tab">
      <StepEntryLogic
        ref="logic"
        :propWorkflowID="workflowID"
        :propStepID="stepID"
        :displayDataProp="autoStepDisplayData"
        :modifyDataProp="autoStepSettingData"
        @markAsDirty="emitWorkflowIsDirty"
      />
    </div>

    <md-dialog id="custom-button-details-modal" :md-active.sync="showCustomButtonDetails" :md-click-outside-to-close="false">
      <CustomButtonActionDetails
        :actionDetails="editingCustomButton"
        :availableLinkedActions="availableLinkedActions"
        :isAutoStepType="isAutoStepType"
        @close="closeCustomButtonDetails"
        @button-updated="updateCustomButtonDetails"
        @markAsDirty="emitWorkflowIsDirty"
      />
    </md-dialog>

    <md-dialog id="vasion-confirmation-div" :md-active.sync="showConfirmation" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="confirmationMessage" @noButtonClick="showConfirmation=false" @yesButtonClick="confirmationConfirmed()" />
    </md-dialog>

    <VasionEllipsisMenu
      v-if="showEllipsisMenu"
      :buttonConfig="ellipsisButtonConfig"
      :style="ellipsisMenuTopStyle"
      :rowItem="ellipsisRowItem"
      @ellipsis-button-clicked="ellipsisButtonClicked"
      @close="ellipsisClose"
    />

    <VasionSnackbar
      :showSnackbarBool.sync="showSnackbar"
      :snackbarTitle="snackbarTitle"
      :snackbarSubTitle="snackbarSubTitle"
    />
  </div>
</template>

<script>
import StepEntryLogic from '@/components/workflow/StepEntryLogic.vue';
import ButtonInfo from '@/components/workflow/ButtonInfo.vue';
import CustomButtonActionDetails from '@/components/workflow/CustomButtonActionDetails.vue';

export default {
  name: 'WorkflowStepDetails',
  components: {
    ButtonInfo,
    CustomButtonActionDetails,
    StepEntryLogic,
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    workflowID: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      alertEmailTemplate: '',
      approverEmailTemplateID: null,
      approverEmailTemplateObject: '',
      approverIncludeDocLinkInEmail: false,
      assignToType: 'user',
      autoStepDisplayData: null,
      autoStepSettingData: null,
      availableLinkedActions: [],
      buttonInfo: {},
      confirmationMessage: '',
      customButtonEnabled: false,
      dragRowStartIndex: -1,
      dragRowTargetIndex: -1,
      editingCustomButton: null,
      ellipsisButtonConfig: {
        buttons: [
          {
            id: 'edit-custom-button',
            name: 'Edit',
            icon: 'VasionEditIcon',
          },
          {
            id: 'delete-custom-button',
            name: 'Delete',
            icon: 'VasionDeleteIcon',
          },
        ],
      },
      ellipsisRowItem: {},
      emailTemplateID: null,
      emailTemplateObject: '',
      enableAutoEscalation: false,
      errorText: '',
      escalationDays: 0,
      escalationHours: 0,
      failureAction: null,
      failureActionData: {
        assignToGroup: {
          group: '',
        },
        assignToUser: {
          user: '',
        },
        sendEmail: false,
        emailUser: {
          emailTo: '',
          emailToField: '',
          emailTemplate: '',
        },
      },
      failureActionsList: [
        {
          name: 'Assign to Group',
          value: 'Assign to Group',
        },
        {
          name: 'Assign to User',
          value: 'Assign to User',
        },
        {
          name: 'Terminate workflow',
          value: 'Terminate workflow',
        },
      ],
      includeDocLinkInEmail: false,
      includeSupervisor: false,
      isDirty: false,
      isEmailValid: false,
      orderNumber: 0,
      passwordReadonly: true,
      passwordText: '',
      preStepLookupID: null,
      reassignGroupID: 0,
      requireUserPassword: false,
      selectedTabName: '',
      selectedTask: { name: '[None]', value: 0 },
      selectedTaskRequirement: { name: '[None]', value: 0 },
			selectUserFromGroup: false,
      sendEmail: false,
      sendEmailToFieldName: '',
      sendEmailToObject: '',
      showAutoEscalation: false,
      showConfirmation: false,
      showCustomButtonDetails: false,
      showEllipsisMenu: false,
      showSnackbar: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      stepID: -1,
      stepName: '',
      subIndex: -1,
      taskRequirementsList: [],
      tasks: [],
      userGroup: '',
      workflowInitiationLookup: '',
    }
  },
  computed: {
    buttonInfoTabClass() { return this.showButtonInfoTab === true ? 'text-selected' : 'text' },
    emailTemplates() {
      return this.$store.state.common.emailTemplates.map((t) => {
        return {
          name: t.sName,
          value: t.iID,
        }
      })
    },
    generalTabClass() { return this.showGeneralTab === true ? 'text-selected' : 'text' },
    groups() { return this.$store.state.common.groups },
    indexFields() {
      const fieldNamesToIgnore = ['Workflow_Status', 'Workflow_Approver', 'Workflow_Due_Date', 'Workflow_Initiator', 'CreatedBy']
      return this.$store.state.common.indexFields.filter( field => {
        return !fieldNamesToIgnore.includes(field.value)
      })
    },
    indexFieldsForForm() {
      const fieldArray = this.$store.state.common.indexFields.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })

      fieldArray.unshift({
        name: '[None]',
        value: '',
      })

      return fieldArray
    },
    isAutoStepType() { return this.stepData?.entityType?.value === 'Auto' },
    lookupList() {
      const filteredLookups = this.$store.state.fieldMapping.lookupList.filter(x => x.attributeFormID === this.stepData.selectedForm.value)
      return filteredLookups
    },
    selectedGroupId() { return this.assignToType === 'group' ? this.userGroup.value : null },
    selectedUserId() { return this.assignToType === 'user' ? this.userGroup.value : null },
    showButtonInfoTab() { return this.selectedTabName === 'buttonInfo' },
    showGeneralTab() { return this.selectedTabName === 'general' },
    showStepEntryLogicTab() { return this.selectedTabName === 'stepEntryLogic' },
    showSelectUserFromGroup() { return this.stepData?.entityType?.value === 'Group' },
    stepEntryLogicTabClass() { return this.showStepEntryLogicTab === true ? 'text-selected' : 'text' },
    templates() {
      const templateArray = this.$store.state.common.emailTemplates.map((t) => {
        return {
          name: t.sName,
          value: t.iID,
        }
      })

      templateArray.unshift({
        name: '[None]',
        value: 0,
      })

      return templateArray
    },
    userGroupLabel() { return this.assignToType === 'user' ? 'User' : 'Group' },
    userGroupList() { return this.assignToType === 'user' ? this.users : this.groups },
    users() { return this.$store.state.common.users },
  },
  watch: {
    assignToType: function () {
      this.escalationGroupID = null
      this.escalationUserID = null
      this.userGroup = ''
    },
    escalationDays: function () {
      if (this.escalationDays < 0) {
        this.escalationDays = 0
      }
    },
    escalationHours: function () {
      if (this.escalationHours < 0) {
        this.escalationHours = 0
      }
    },
    stepData: function () {
      this.setStepData()
    },
  },
  created: async function () {
    this.$store.dispatch('common/getUsers')
    this.$store.dispatch('common/getGroups')
    this.tasks = await this.getTasks()
    this.taskRequirementsList = await this.getTasksRequirementsList()
    this.setStepData()
    this.passwordReadonly = false

    this.selectedTabName = this.isAutoStepType ? 'buttonInfo' : 'general'
    if (this.isAutoStepType) this.toggleCheckbox('customButtonEnabled')
  },
  methods: {
    addCustomActionClick() {
      this.editingCustomButton = null
      this.updateAvailableLinkedActions('')
      this.showCustomButtonDetails = true
    },
    cancelClick() {
      this.$emit('cancelClick')
      this.updateSelectedTab('general')
      this.$emit('markAsClean', true)
    },
    closeCustomButtonDetails() { this.showCustomButtonDetails = false },
    confirmationConfirmed() {
      this.buttonInfo.customButtons = this.buttonInfo.customButtons.filter(button => {
        return button.actionName !== this.editingCustomButton.actionName
      })

      this.showConfirmation = false
      this.emitWorkflowIsDirty()
    },
    deleteCustomActionClick(actionName) {
      this.editingCustomButton = this.buttonInfo.customButtons.find(element => element.actionName === actionName)
      if (!this.editingCustomButton) {
        return
      }

      this.confirmationMessage = `Are you sure you want to delete "${actionName}"?`
      this.showConfirmation = true
    },
    disableEditButton(actionType) {
      return actionType !== 'Auto Signature' && actionType !== '' && actionType !== 'None' && actionType !== 'Set Fields'
    },
    doneClick() {
      const result = this.validateAndBuildResult()
      if (result) {
        this.$emit('doneClick', result)
        this.updateSelectedTab('general')
      } else {
        this.snackbarTitle = "Unable to save step details"
        this.showSnackbar = true
      }
      if (this.isDirty) {
        this.$emit('markAsDirtyDone', true)
      }
    },
    dragRowChildPrevent(event) {
      event.preventDefault()
      event.stopPropagation()
    },
    dragRowDrop(event, dropIndex) {
      event.preventDefault();
      const startIndex = this.dragRowStartIndex
      if (startIndex == dropIndex || startIndex + 1 == dropIndex) {
        return
      }
      const movedUp = dropIndex < startIndex
      
      // Now move the buttons
      if (!movedUp) {
        //if we moved the button down, then once we remove it from the button list it offsets our dropIndex
        dropIndex -= 1
      }
      var element = this.buttonInfo.customButtons[startIndex];
      this.buttonInfo.customButtons.splice(startIndex, 1);
      this.buttonInfo.customButtons.splice(dropIndex, 0, element);
    },
    dragRowEnd() {
      this.dragRowTargetIndex = -1
      this.dragRowStartIndex = -1
    },
    dragRowOver(event, dropIndex) {
      if (this.dragRowStartIndex == dropIndex || this.dragRowStartIndex + 1 == dropIndex) {
        return
      }
      event.preventDefault();
      this.dragRowTargetIndex = dropIndex
    },
    dragRowStart(event, index) {
      this.dragRowStartIndex = index;
      event.dataTransfer.effectAllowed = 'move';
    },
    editCustomActionClick(actionName) {
      this.updateAvailableLinkedActions(actionName)
      this.editingCustomButton = this.buttonInfo.customButtons.find(element => element.actionName === actionName)
      this.showCustomButtonDetails = true
    },
    ellipsisButtonClicked(payload) {
      this.ellipsisClose()

      if (!payload || !payload.buttonId || !payload.item) {
        return
      }

      switch (payload.buttonId) {
        case 'edit-custom-button':
          this.editCustomActionClick(payload.item.actionName)
          break;
        case 'delete-custom-button':
          this.deleteCustomActionClick(payload.item.actionName)
          break
        default:
          break
      }
    },
    ellipsisClose() { this.showEllipsisMenu = false },
    ellipsisOpen(event, item) {
      this.ellipsisClose()
      this.ellipsisRowItem = item
      let displayY = event.pageY
      let baseSize = 0

      if (this.ellipsisButtonConfig && this.ellipsisButtonConfig.buttons) {
        baseSize = this.ellipsisButtonConfig.buttons.length * 40
      }

      if (displayY + baseSize > document.documentElement.scrollHeight) {
        displayY -= baseSize
      }

      this.ellipsisMenuTopStyle = `top:${displayY}px;`
      this.showEllipsisMenu = true
    },
    emitWorkflowIsClean() {
      this.isDirty = false;
      this.$emit('markAsClean', true)
    },
    emitWorkflowIsDirty() {
      this.isDirty = true;
      this.$emit('markAsDirty', true)
    },
    failureActionChange() {
      this.failureActionData = {
        assignToGroup: {
          group: '',
        },
        assignToUser: {
          user: '',
        },
        emailUser: {
          emailTo: '',
          emailToField: '',
          emailTemplate: '',
        },
        sendEmail: false,
      }
    },
    getFailureLogic() {
      if (!this.failureAction) return {}
      let failureLogic = {
        failedEmailTo: null,
        failedSendEmailNotification: false,
        failedEmailTemplateId: null
      }
      failureLogic.failedActionType = this.failureAction.value

      if (this.failureAction.value === 'Assign to Group') {
        failureLogic.assignToGroupId = this.failureActionData.assignToGroup.group.value
      }

      if (this.failureAction.value === 'Assign to User') {
        failureLogic.assignToUserId = this.failureActionData.assignToUser.user.value
      }

      if (this.failureActionData.sendEmail) {
        if (this.failureActionData.emailUser.emailToField) failureLogic.failedEmailToField = this.failureActionData.emailUser.emailToField.value
        failureLogic.failedEmailTo = this.failureActionData.emailUser.emailTo
        failureLogic.failedSendEmailNotification = this.failureActionData.sendEmail
        failureLogic.failedEmailTemplateId = this.failureActionData.emailUser.emailTemplate?.value
      }
      return failureLogic
    },
    async getTasks() {
      let tasks = await this.$store.dispatch('workflow/getAllTaskLists')
      tasks = tasks.map((task) => {
        return {
          name: task.sName,
          value: task.iID,
        }
      })

      tasks.unshift({
        name: '[None]',
        value: 0,
      })
      return tasks
    },
    async getTasksRequirementsList() {
      let tasksRequirements = await this.$store.dispatch('workflow/getWorkflowStepsTasksOptions')
      let i = 0
      tasksRequirements = tasksRequirements.map((taskRequirement) => {
        i += 1
        return {
          name: taskRequirement,
          value: i,
        }
      })

      tasksRequirements.unshift({
        name: '[None]',
        value: 0,
      })
      return tasksRequirements
    },
    rowClickHandler(event, data) {
      if (event.target.parentNode.classList.contains('md-button-content')) return
      if (!data || !data.actionName || data.length <= 0) return
      this.editCustomActionClick(data.actionName)
    },
    setFailureLogic(failureLogic) {
      this.failureAction = this.failureActionsList.find(failureAction => failureAction.value === failureLogic.failedActionType)
      if (!this.failureAction) return

      if (this.failureAction.value === 'Assign to Group') {
        this.failureActionData.assignToGroup.group = this.groups.find(group => group.value === failureLogic.assignToGroupId)
      }

      if (this.failureAction.value === 'Assign to User') {
        this.failureActionData.assignToUser.user = this.users.find(user => user.value === failureLogic.assignToUserId)
      }

      this.failureActionData.sendEmail = failureLogic.failedSendEmailNotification
      this.failureActionData.emailUser.emailTo = failureLogic.failedEmailTo
      this.failureActionData.emailUser.emailToField = this.indexFields.find(indexField => indexField.value === failureLogic.failedEmailToField)
      this.failureActionData.emailUser.emailTemplate = this.emailTemplates.find(emailTemplate => emailTemplate.value === failureLogic.failedEmailTemplateId)
    },
    setStepData() {
      if (this.stepData) {
        this.stepID = !this.stepData.stepID ? -1 : this.stepData.stepID
        this.emailTemplateID = this.stepData.emailTemplateID
        this.includeDocLinkInEmail = this.stepData.includeDocLinkInEmail
        this.approverIncludeDocLinkInEmail = this.stepData.approverIncludeDocLinkInEmail
        this.approverEmailTemplateID = this.stepData.approverEmailTemplateID
        this.orderNumber = this.stepData.orderNumber
        this.passwordText = this.stepData.passwordText
        this.preStepLookupID = this.stepData.preStepLookupID
        this.requireUserPassword = this.stepData.requireUserPassword
        this.sendEmail = this.stepData.sendEmail
        this.sendEmailToFieldName = this.stepData.sendEmailToFieldName
        this.stepName = this.stepData.name
        this.buttonInfo = this.stepData.buttonInfo
        this.reassignGroupID = this.stepData.reassignGroupID
        this.enableAutoEscalation = this.stepData.enabledAutoEscalation
        this.escalationDays = this.stepData.escalationDays
        this.escalationHours = this.stepData.escalationHours
        this.userGroup = this.stepData.escalationGroupID === null
            ? this.users.find(user => user.value === this.stepData.escalationUserID)
            : this.groups.find(group => group.value === this.stepData.escalationGroupID)

        this.selectedTask = this.tasks.find(task => task.value === this.stepData.assignTaskListID)
        this.selectedTaskRequirement = this.taskRequirementsList.find(task => task.name === this.stepData.documentTaskAction)
        this.selectUserFromGroup = this.stepData.selectUserFromGroup
        if (!this.userGroup) {
          this.userGroup = ''
        }

        this.alertEmailTemplate = ''
        if (this.stepData.escalationEmailTemplate) {
          this.alertEmailTemplate = this.templates.find(template => template.value === this.stepData.escalationEmailTemplate)
        }

        this.autoStepSettingData = this.stepData.autoStepSettingData
        this.autoStepDisplayData = this.stepData.autoStepDisplayData
        this.subIndex = this.stepData.subIndex
        this.includeSupervisor = this.stepData.includeSupervisor

        if (this.stepData?.failureLogic && typeof this.stepData.failureLogic === 'object' && this.stepData.failureLogic !== null && Object.keys(this.stepData.failureLogic).length){
          this.setFailureLogic(this.stepData.failureLogic)
        }
        else
          this.failureActionChange()
      } else {
        this.emailTemplateID = null
        this.includeDocLinkInEmail = false
        this.approverEmailTemplateID = null
        this.approverIncludeDocLinkInEmail = false
        this.orderNumber = 0
        this.passwordText = ''
        this.preStepLookupID = null
        this.requireUserPassword = false
        this.sendEmail = false
        this.sendEmailToFieldName = ''
        this.stepName = ''
        this.buttonInfo = {}
        this.enableAutoEscalation = false
        this.escalationDays = 0
        this.escalationHours = 0
        this.userGroup = ''
        this.autoStepSettingData = null
        this.autoStepDisplayData = null
        this.selectedTask = { name: '[None]', value: 0 }
        this.selectedTaskRequirement = { name: '[None]', value: 0 }
        this.includeSupervisor = false
      }

      this.sendEmailToObject = this.indexFieldsForForm.find((field) => field.value === this.sendEmailToFieldName) || ''
      this.customButtonEnabled = this.buttonInfo && this.buttonInfo.customButtons && this.buttonInfo.customButtons.length > 0

      this.emailTemplateObject = ''
      if (this.emailTemplateID !== null && this.emailTemplateID > 0) {
        this.emailTemplateObject = this.templates.find((template) => template.value === this.emailTemplateID) || ''
      }
      if (this.approverEmailTemplateID !== null && this.approverEmailTemplateID > 0) {
        this.approverEmailTemplateObject = this.templates.find((template) => template.value === this.approverEmailTemplateID) || ''
      }
      if (this.preStepLookupID !== null && this.preStepLookupID > 0) {
        this.workflowInitiationLookup = this.lookupList.find((lookup) => lookup.id === this.preStepLookupID) || ''
      }
      if ((this.workflowInitiationLookup === '' && this.preStepLookupID !== null) || this.stepData.workflowInitiationType.value === 0) {
        this.preStepLookupID = null
      }
    },
    toggleCheckbox(id) { this[id] = !this[id] },
    toggleCollapsableSection(section) {
      switch (section) {
        case 'auto-escalation':
          this.showAutoEscalation = !this.showAutoEscalation
          break;
        default:
          this.showAutoEscalation = false
          break;
      }
    },
    updateAvailableLinkedActions(excludeActionName) {
      this.availableLinkedActions = []

      if (!this.buttonInfo.customButtons) {
        this.buttonInfo = {
          approvedButton: this.$refs.approvedButton.getLocalData(),
          completedButton: this.$refs.completedButton.getLocalData(),
          forwardButton: this.$refs.forwardButton.getLocalData(),
          rejectButton: this.$refs.rejectButton.getLocalData(),
          reassignButton: this.$refs.reassignButton.getLocalData(),
          customButtons: [],
        }
      }

      this.buttonInfo.customButtons.map((element) => {
        if (element.actionName !== excludeActionName) {
          this.availableLinkedActions.push({
            name: element.actionName,
            value: element.actionName,
          })
        }

        return true
      })
    },
    updateCustomButtonDetails(newCustomButtonInfo) {
      if (!this.editingCustomButton) {
        this.buttonInfo.customButtons.push(newCustomButtonInfo)
      } else {
        const oldButtonName = this.editingCustomButton.actionName
        this.buttonInfo.customButtons = this.buttonInfo.customButtons.map((button) => {
          // TODO: Update the linked action name as needed
          return button.actionName === this.editingCustomButton.actionName ? newCustomButtonInfo : button
        })

        // rename linked action names as needed
        if (oldButtonName !== newCustomButtonInfo.actionName) {
          this.buttonInfo.customButtons.map((button) => {
            if (button.linkedActionName === oldButtonName) {
              button.linkedActionName = newCustomButtonInfo.actionName
            }

            return true
          })
        }
      }

      this.closeCustomButtonDetails()
    },
    updateSelectedTab(tabName) {
      this.selectedTabName = tabName
    },
    validateAndBuildResult() {
      this.snackbarSubTitle = ''

      if (!this.stepName) {
        this.snackbarSubTitle += `- Please enter a Step Name\n`
        return null
      }

      if (this.enableAutoEscalation
          && (this.escalationDays === '' || this.escalationDays === '')) {
        this.snackbarSubTitle += `- Please select valid numbers for DAYS and HOURS, since "Enable Auto Escalation" is enabled.\n`
        return null
      }

      if (this.enableAutoEscalation
          && (this.userGroup === '' || (this.selectedGroupId === null && this.selectedUserId === null))
        ) {
        this.snackbarSubTitle += `- Please select a User or Group, since "Enable Auto Escalation" is enabled.\n`
        return null
      }

      if (this.enableAutoEscalation
          && (!this.alertEmailTemplate || !this.alertEmailTemplate.value)) {
        this.snackbarSubTitle += `- Please select an ALERT EMAIL TEMPLATE, since "Enable Auto Escalation" is enabled.\n`
        return null
      }

      if (this.isAutoStepType) {
        if (!this.failureAction?.value) {
          this.snackbarSubTitle += `- Please select a FAILURE ACTION.\n`
          return null
        }
        
        if (this.failureAction && this.failureAction?.value === 'Assign to Group' && !this.failureActionData.assignToGroup.group?.value) {
          this.snackbarSubTitle += `- Please select a GROUP, since FAILURE ACTION is set to "Assign to Group".\n`
          return null
        }
        if (this.failureAction && this.failureAction?.value === 'Assign to Group' && this.failureActionData.assignToGroup.sendEmail && !this.failureActionData.assignToGroup.emailTemplate?.value) {
          this.snackbarSubTitle += `- Please select a EMAIL TEMPLATE, since FAILURE ACTION is set to "Assign to Group" and Send Email notification is checked.\n`
          return null
        }

        if (this.failureAction && this.failureAction?.value === 'Assign to User' && !this.failureActionData.assignToUser.user?.value) {
          this.snackbarSubTitle += `- Please select a USER, since FAILURE ACTION is set to "Assign to User".\n`
          return null
        }
        if (this.failureAction && this.failureAction?.value === 'Assign to User' && this.failureActionData.assignToUser.sendEmail && !this.failureActionData.assignToUser.emailTemplate?.value) {
          this.snackbarSubTitle += `- Please select a EMAIL TEMPLATE, since FAILURE ACTION is set to "Assign to User" and Send Email notification is checked.\n`
          return null
        }
        
        if (this.failureAction && this.failureAction?.value === 'Email User' && !(this.failureActionData.emailUser.emailTo || this.failureActionData.emailUser.emailToField?.value)) {
          this.snackbarSubTitle += `- Please set a EMAIL TO or select a EMAIL TO FIELD, since FAILURE ACTION is set to "Email User".\n`
          return null
        }
        if (this.failureAction && this.failureAction?.value === 'Email User' && this.failureActionData.emailUser.emailTo && !this.isEmailValid) {
          this.snackbarSubTitle += `- Please set a valid email in the EMAIL TO field.\n`
          return null
        }
        if (this.failureAction && this.failureAction?.value === 'Email User' && !this.failureActionData.emailUser.emailTemplate?.value ) {
          this.snackbarSubTitle += `- Please select a EMAIL TEMPLATE, since FAILURE ACTION is set to "Email User".\n`
          return null
        }

        if (!this.buttonInfo.customButtons || this.buttonInfo.customButtons.length == 0) {
          this.snackbarSubTitle += `- You must have at least one action for a step.\n`
          return null
        }
      }

      const result = {
        orderNumber: this.orderNumber,
        sendEmail: this.sendEmail,
        name: this.stepName,
        emailTemplateID: this.sendEmail && this.emailTemplateObject && this.emailTemplateObject.value !== undefined && this.emailTemplateObject.value > 0 ? this.emailTemplateObject.value : null,
        approverEmailTemplateID: this.sendEmail && this.approverEmailTemplateObject && this.approverEmailTemplateObject.value !== undefined && this.approverEmailTemplateObject.value > 0 ? this.approverEmailTemplateObject.value : null,
        includeDocLinkInEmail: this.sendEmail && this.includeDocLinkInEmail,
        approverIncludeDocLinkInEmail: this.sendEmail && this.approverIncludeDocLinkInEmail,
        passwordText: this.passwordText,
        requireUserPassword: this.requireUserPassword,
        selectUserFromGroup: this.selectUserFromGroup,
        sendEmailToFieldName: !this.sendEmail || !this.sendEmailToObject || !this.sendEmailToObject.value ? '' : this.sendEmailToObject.value,
        buttonInfo: {
          approvedButton: this.$refs.approvedButton.getLocalData(),
          completedButton: this.$refs.completedButton.getLocalData(),
          forwardButton: this.$refs.forwardButton.getLocalData(),
          rejectButton: this.$refs.rejectButton.getLocalData(),
          reassignButton: this.$refs.reassignButton.getLocalData(),
          customButtons: this.buttonInfo?.customButtons ? this.buttonInfo.customButtons : [],
        },
        enabledAutoEscalation: this.enableAutoEscalation,
        escalationDays: parseInt(this.escalationDays, 10),
        escalationHours: parseInt(this.escalationHours, 10),
        escalationUserID: this.selectedUserId,
        escalationGroupID: this.selectedGroupId,
        escalationEmailTemplate: this.enableAutoEscalation && this.alertEmailTemplate && this.alertEmailTemplate.value !== undefined && this.alertEmailTemplate.value > 0 ? this.alertEmailTemplate.value : null,
        autoStepSettingData: this.$refs.logic?.buildSavePayload(),
        autoStepDisplayData: this.$refs.logic?.buildStepDisplayPayload(),
        documentTaskAction: !this.selectedTaskRequirement || this.selectedTaskRequirement.value === 0 ? null : this.selectedTaskRequirement.name,
        assignTaskListID: !this.selectedTask ? null : this.selectedTask.value,
        reassignGroupID: this.$refs.reassignButton.groupID,
        subIndex: this.subIndex,
        includeSupervisor: this.includeSupervisor,
        preStepLookupID: this.workflowInitiationLookup ? this.workflowInitiationLookup.id : null,
        failureLogic: this.getFailureLogic(),
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .white-background {
    background-color: $white;
  }

  .controls-column {
    width: 90px;
  }

  .custom-button-check-box {
    padding-top: 12px;
  }

  .design-div {
    -webkit-flex: 1; /* Chrome */
    flex: 1; /* NEW, */
    overflow: auto;
    background-color: $white;
  }

  #general-tab-button {
    margin-left: -8px;
  }

  .padding-top {
    padding-top: 30px;
  }

  .w300 {
    width: 300px;
    margin-right: 10px;
  }

  .vasion-checkbox {
    width: 300;
    float: left;

    &.extra-top-margin {
      margin-top: 28px;
    }
    &.small-checkbox {
      width: 89px;
      margin-top: 27px;
    }
  }

  .tab-button-div {
    padding-bottom: 20px;
  }

  .settings-row-div {
    padding-bottom: 15px;
    clear: both;

    &.flex {
      display: flex;
    }
  }

  .collapsable-section {
    float: left;
    margin-top: 20px;
    width: 100%;

    .subheader {
      @include SubHeadline;
      width: 225px;
      display: block;
      float: left;
      margin-top: 5px;
    }

    .subtitle {
      @include Subtitle;
    }
  }

  .days-hours {
    width: 150px;
    margin-right: 20px;
  }

  .assign-to {
    height: 12px;
    font-family: $font-family-medium;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey-400;
  }

  .user-group {
    width: 150px;
    margin-right: 20px;
  }

  #ellipsis-button {
    padding-left: 45px;
  }

  .dropTarget {
    border-top: 6px solid $secondary;
  }

  .move-icon {
    cursor: move;
    fill: $grey-600;
  }
  .move-icon-column {
    width: 24px;
  }

  @media (max-height: 900px) {
    #assign-row .vasion-droplist::v-deep .list {
      margin-bottom: 32px;
    }
    #alert-row .vasion-droplist::v-deep .list {
      margin-bottom: 15px;
    }
  }
  .vasion-checkbox-01 ::v-deep {
    float: unset;
    display: flex;
    margin-right: 16px;
    
    svg {
      margin-right: unset;
    }
    label {
      color: $grey-500;
    }
  }
  .fields-flex {
    margin-top: 0px;
    margin-bottom: 16px;
    display: flex;
    flex-flow: row wrap;
  }
  .fields-flex-item {
    margin-right: 16px;
    min-width: 300px;
    max-width: 300px;
  }
  .fields-flex-top {
    margin-top: 16px;
    margin-bottom: 0px;
    display: flex;
    flex-flow: row wrap;
  }
  .mt-30px {
    margin-top: 30px;
  }
  .edit-workflow-step-tab {
    width: calc(100vw - 380px);
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }

  @media (max-width: $breakpoint-lg) {
  .edit-workflow-step-tab {
    width: calc(100vw - 118px);
  }
}
</style>
